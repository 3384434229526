import * as RNLocalize from 'react-native-localize';
import DeviceInfo from './nativeModules/DeviceInfo';

class Settings {
  buildType = 'canteen';
  appStoreUrl = 'itms://itunes.apple.com/us/app/id1175012873';
  discoveryUrl =
    'https://tally.prod.readytouchpos.com/tallyapi/discover/listAsEnvironmentsMap';
  env = 'prod';
  ipaName = 'Connect & Pay';
  appDisplayName = 'Connect & Pay®';
  webAppDisplayName = 'MyMarketAccount.com';
  key = '3C9DEDE2-EC3E-43E3-9C20-E591ED341127';
  oneSignalAppId = '979fc659-8f8a-42cc-b678-3ccf69ff9aa9';
  appInsightsKey = '4d36a388-278d-4533-86cd-7c1dcec328bb';
  branchKey = 'key_live_biErVcpFsf9W4a3bUU0HaagdEqoKc8yG';
  branchFallbackUrls = {"DEV":"https://staging-connectandpay.365rm.us","QA":"https://test4-connectandpay.365rm.us","STAGING":"https://staging-connectandpay.365rm.us","PROD":"https://www.connectandpay.com"};
  //default 365Pay info for all env.
  auth0 = {"DEV":{"domain":"test3-auth.365rm.us","clientId":"6yDcMva90CK597Ep2B9HkDVaPwoETySU","clientIdAuth0Web":"WWZVkFdVPCb0saTkCJqDklJeglUkPOLl","audience":"https://gateway.test3.365rm.us","orgIdAuth0":"org_ySGlIKK5uRmh4RPr"},"QA":{"domain":"test4-auth.365rm.us","clientId":"qsKCBwElVYkvgGj17Id4bKCAmqUkuMVl","clientIdAuth0Web":"7vw2j1VqhCMrd1gUfSUgyMKbBpmt9guX","audience":"https://gateway.test4.365rm.us","orgIdAuth0":"org_hFiWQeH7zTWnyVGM"},"STAGING":{"domain":"staging-auth.365rm.us","clientId":"0WIcpc1cnqQkj4qeeBo6JOvhlwUtK014","clientIdAuth0Web":"VGlfhJJ0OmJySzbM1cp0P4OqH8QduUaY","audience":"https://gateway.staging.365rm.us","orgIdAuth0":"org_ndJOWKmJ2F53VaJQ"},"PROD":{"domain":"auth.365rm.us","clientId":"sfHO1zGrDWnmVQlY3YCY9yvXvsqn8P9E","clientIdAuth0Web":"h5ZqtweIWJ3CdQmcYZB1l36AR5IyMaKb","audience":"https://gateway.prod.365rm.us","orgIdAuth0":"org_Hjqu6WY2GmwbULnR"}};
  scope = 'openid offline_access profile email';
  // prettier-ignore
  iOSScanditKey = 'AWe+pYuBH6DeFY45hyznt84P/kF2RSWnYkFxFQguIL4CfBLU5lxxbKJYQo0heVJxZlYfJsJeFYH2RqdlOEHv5ktWAuSORXkI5BCr7/ZpLvftCIUnek6Dm0NrNkoHKisV+wybKcUFNWhtC/0Znxwv4vpNFd8gPZutmKKO1ouBK1MM1xTGEZh9wgpUSghvZpeyfNPnteZls0W5oFtICgQtsFmKWvCDP5V68x/tYFLaamBb51eWtsuG8HajXJ6Ir+hYZhJj0M7QiW8IZQ7+4xkBQNShjm7mfaAzz1RfFpuOl0dSAcIqpV2vb0nIxe4lWGXbv2DrLHaAtixuHQ7tQ9p6ZqA80b/uVSXfRSditjHURtlslW0EbDGRcnuEh8lcF5GLmiNcZI9dBVhtHtNxhm5SwH0lxcbrbSW4uVkAd/gorVjEHq7OZTU03qO62WQ15KMTzgT3kEm26T9GnCn55pkKos9ncYwV2+ZekzTmHnmQi7bjQIawnflaowl8cvEFshOQ2pnqqmADBHbL1OanMDDpAKjKl4iC0/YEyQtpA5GeEetuF6t0DMFZxKC4a17Aa3Qj2prridtbqXWXB+8G75ZuEZp0qR6UPJYMobFFa+ujTTZtHSX7Ut44egt6UV8yuFuxfnsZgvLf2D8jF4Djd/C/evADynVV4j9M0gP2GprPjj3qufzK2o+45SCHeWXpuLRIls4YVuN2SZY0l6zEgq9j7Hrz8sBIFbGtd5ZGw/gapnwxcVXpPmQIzfbpAOLWlBDN7dAIXtHgPOl0AE3zNoRx/xPxBqIddYXwOOIAKU5HWte9HVHP8pMtd8pHkih6ng==';
  // prettier-ignore
  androidScanditKey = 'AS2ei5uBD3nHGne5+SAp6/AywHX9Kw6IFXMWgZoWYi0ZXOnD4EKnERFUkQ4Vf1YBRUmRPDgsbemzZ06BkkQ4hkE12GL7UqyWRzV/aUA3+zYJc3Q8NnwPStt27jQGZaqnaRZZQQIu0u/yEZJv3kFOT0ywER5Sgupui89MxVySOmYFWQESuUe+Ff/WevAbB/kYlRB+bXPN1sC0CSKlqtud7l3AMzCGAIbGSFf/CHxvnhAetkN/TeiM4W7AQfukPDZT8IcaKC9LWePCvwBvx1MfH4r+GLa9DOIGjTUjMYLuCNYhkKkBBxaKYi+bLiwROehwEocmJLciPROenBiAsiGdMlq8T9B81FaSpcHzDIt+40FcmfasKj8U+tfW0fhIok0T8U1QstXPcAZ9x8vyqPGu/drNAkSUNTk5uNLUV7CrHyGBLwvgYxjZ7dpwiab98hZE4V2Diu8+bqfdwhTOvFnzGUagL/NpYbVP/5CIxjcqcFK3C24ucq1KSry9xf57ZeTosI+pqUqBspMxAm3JZBcORvEvkuuQ+gEgzrquCkxd2Rrs9C4W0Rut9GVLU9wHCqZ20L4cN2VnmqCkO1J8D9YlXY6mLvPxOOvrgZyufN8NzM9FgQwRTg8iYAvWoFB0DFoK7jqpKnD9oQZvO76h0Ra8K9rsquJIt3JJPnfzVW4eMYvbW6Pe6WeB1aEUsORewKVmkyfalQO5sEtQkscTWK7DLT66fLA9uKWEdlnzRHRuTWB2+G6uompLXVk30O1D+CoyxhKACUWuT3ZMXb9FSnyOSbft3oqWAumJZDXb3/4p0SWgGopCQASy+om+VtrJSw==';
  demo = false;
  contactEmail = 'Support@365smartshop.com';
  supportEmail = 'Support@365smartshop.com';
  processors: Record<string, number> = {
    heartland: 14,
    stripe: 20,
  };
  processorsAsString: Record<string, string> = {
    heartland: 'heartland',
    stripe: 'stripe',
  };
  hapticOptions = {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
  };

  appTourVersion = '1.0';

  getPackageName() {
    return DeviceInfo.getBuildIdSync();
  }
  async getFullDisplayVersion(platform: string) {
    const buildNumber = await DeviceInfo.getBuildNumber();

    if (platform === 'ios') {
      return `v${buildNumber}`;
    }

    return `v${await DeviceInfo.getVersion()} (${buildNumber})`;
  }

  getPrivacyPolicyUrl(locale: string, version: string, type: string) {
    return `https://contents.365retailmarkets.com/${type}/${this.getPoliciesLocale(
      locale,
    )}/privacy/${version}/privacy.html`;
  }

  async getPrivacyPolicyHtml(locale: string, version: string, type: string) {
    const url = this.getPrivacyPolicyUrl(locale, version, type);

    try {
      const response = await fetch(url);

      if (response.status === 404) {
        throw new Error('Resource not found');
      }
      return response.text();
    } catch (error) {
      const defaultLocale = this.getDefaultLocale();

      if (locale !== defaultLocale) {
        return this.getPrivacyPolicyHtml(defaultLocale, version, type);
      } else {
        throw error;
      }
    }
  }

  getTermsUrl(locale: string, type: string) {
    return `https://contents.365retailmarkets.com/${type}/${this.getPoliciesLocale(
      locale,
    )}/terms/terms.html`;
  }

  getPoliciesLocale(locale: string) {
    if (locale.startsWith('en')) {
      return 'en-US';
    } else if (locale.startsWith('fr')) {
      return 'fr-CA';
    } else if (locale.startsWith('it')) {
      return 'it-IT';
    } else if (locale.startsWith('de')) {
      return 'de-CH';
    } else if (locale.startsWith('nl')) {
      return 'nl-NL';
    } else if (locale.startsWith('es')) {
      return 'es-ES';
    } else if (locale.startsWith('sv')) {
      return 'sv-SE';
    } else if (locale.startsWith('da')) {
      return 'da-DK';
    } else if (locale.startsWith('nb')) {
      return 'nb-NO';
    } else if (locale.startsWith('no')) {
      return 'no-NO';
    }

    return locale;
  }

  getDefaultLocale() {
    return 'en-US';
  }

  getLocale() {
    const locale = RNLocalize.getLocales()[0];
    let localeStr = locale.languageTag;

    if (localeStr.length === 2) {
      localeStr += `-${locale.countryCode}`;
    }

    return localeStr;
  }

  getLocales() {
    return RNLocalize.getLocales().map((locale) => {
      let localeStr = locale.languageTag;

      if (localeStr.length === 2) {
        localeStr += `-${locale.countryCode}`;
      }

      return localeStr;
    });
  }
}

export default new Settings();
