import React from 'react';
import CreateAccountPrivacyPolicyHandler from '../elements/createAccount/CreateAccountPrivacyPolicyHandler';
import ScreenContext from '../ScreenContext';
import type {SnackAction} from 'src/types/Snack';
import type {ScreenProps} from 'src/types/Screen';
import TextInput from '../elements/AVTextInput';
import {AppDispatch, store} from 'src/redux/store';
import {PulseIndicator} from 'react-native-indicators';
import {get3LetterCountryCode} from 'src/constants/Countries';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  Linking,
  AppState,
} from 'react-native';
import Styles from 'src/components/Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import SyncHelper from 'src/services/SyncService';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import AppRoutes from 'src/AppRoutes';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {ScreenWidth} from 'react-native-elements/dist/helpers';
import FocusAwareStatusBar from 'src/components/elements/FocusAwareStatusBar';
import UIManager from 'src/components/elements/ui/UIManager';
import DeepLinkHandler from 'src/components/elements/DeepLinkHandler';
import Logo from 'src/components/elements/Logo';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import NavActions from 'src/actions/NavActions';
import Header from 'src/components/elements/Header';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {EnvironmentKey} from 'src/models/Environment';
import {RootState} from 'src/redux/store';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {
  alertError,
  confirm,
  confirmNonDismissable,
} from '../helpers/AlertHelper';
import {fetchRemoteConfig} from 'src/redux/slices/firebaseRemoteConfigSlice';
import {authStore} from 'src/init';
import {getMobileAuth0Client, getWebAuth0Client} from 'src/nativeModules/Auth0';
import Events from 'src/logging/Events';
import uuid from 'src/nativeModules/UUID';
import moment from 'moment';
import DealRepository from 'src/services/aws/DealRepository';
import OneSignal from 'src/nativeModules/OneSignal';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import {NavigationProp} from '@react-navigation/native';
import BuildTypes from 'src/constants/BuildTypeConstants';

function sessionTimeOut() {
  setTimeout(
    () =>
      alertError(Localized.Errors.login_failure, null, () => {
        ActionsFactory.getAccountActions().logout().catch();
        NavActions.resetMultiple([AppRoutes.Welcome]);
      }),
    300,
  );
}

type WelcomeProps = {
  snack?: SnackAction;
  env: EnvironmentKey;
  firebaseConfig: object;
  dispatch: AppDispatch;
};

type WelcomeState = {
  fullVersion: string;
  touches: number;
  appState: string;
};

type SettingsModel = {
  demo: boolean;
  env: string;
  gma: boolean;
  debug: boolean;
  retry: boolean;
};

type Account = {
  isSSO: boolean;
  pinHash: string;
  accountId: string;
  email: string;
  unverifiedEmail?: string;
  orgId: string;
  locationId: string;
  localization: {
    region: string;
    currency: string;
  };
  city: string;
  zip: string;
  firstName: string;
  lastName: string;
};

//auth0
type WelcomeAuth0Props = {
  snack?: SnackAction;
  referralId: string;
  token: string;
  navigation?: NavigationProp<WelcomeScreen>;
};

type WelcomeAuth0State = {
  fullVersion: string;
  previousRoute?: string;
  showRedirectScreen: boolean;
};
//------------

const backgroundColor = Platform.select({
  web: 'white',
  default: Styles.loginBackground,
});
const BACKGROUND_WIDTH = ScreenWidth * 1.1;

let appUpdateAlertShown = false;
export class WelcomeScreen extends React.Component<
  WelcomeProps & WelcomeAuth0Props,
  WelcomeState & WelcomeAuth0State
> {
  password: TextInput | null;
  privacyHandler: CreateAccountPrivacyPolicyHandler | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  REDIRECT_SCREEN_SHOW_DELAY = 500;
  declare myTimeout: NodeJS.Timeout;

  static saveSettingChanges(model: SettingsModel) {
    //TODO will move static func to EnvironmentScreen in componentWillUnmount
    // replace for this.props.saveChanges(this.state);
    ActionsFactory.getAccountActions().isDemoChanged(model.demo);
    // ActionsFactory.getAccountActions().environmentChanged(model.env);
    ActionsFactory.getAccountActions().useGmaChanged(model.gma);
    ActionsFactory.getAccountActions().isDebugChanged(model.debug);
  }

  constructor(props: WelcomeProps & WelcomeAuth0Props) {
    super(props);
    this.state = {
      touches: 0,
      fullVersion: '',
      appState: AppState.currentState,
      showRedirectScreen: false,
    };

    this.logoPress = this.logoPress.bind(this);
    this.loginWithAuth0 = this.loginWithAuth0.bind(this);
    this.webLoginAuth0 = this.webLoginAuth0.bind(this);
    this.goToEnvironmentSettings = this.goToEnvironmentSettings.bind(this);
  }

  _isNoAppUpdateAlertShowing = () => !appUpdateAlertShown;

  async componentDidMount() {
    const loggedIn = await this.checkIfLoggedIn();
    FirebaseAnalytic.trackEvent('componentDidMount', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      loggedIn,
    });
    if (!loggedIn) {
      const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
      this.setState({
        fullVersion,
      });
    }
    AppState.addEventListener('change', this._handleAppStateChange);
    AccountStore.addVerifyEmailListner(this.webLoginAuth0);

    this.props.dispatch(fetchRemoteConfig(false));
  }

  componentWillUnmount() {
    AccountStore.removeVerifyEmailListner(this.webLoginAuth0);
  }

  _handleAppStateChange = (nextAppState) => {
    switch (nextAppState) {
      case 'active':
        if (
          this._isNoAppUpdateAlertShowing() &&
          this._enableShowAlertAfterMinimized()
        ) {
          this.props.dispatch(fetchRemoteConfig(true));
        }

        break;
    }
  };

  _enableShowAlertAfterMinimized = () => Platform.OS == 'ios';

  logoPress() {
    const touches = this.state.touches + 1;
    FirebaseAnalytic.trackEvent('logoPress', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      touches,
    });
    if (touches >= 5) {
      this.setState({
        touches: 0,
      });
      this.goToEnvironmentSettings();
    } else {
      this.setState({
        touches,
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<WelcomeProps>): void {
    this.showAppUpdateAlert(prevProps);
  }

  showAppUpdateAlert = (prevProps) => {
    const firebaseConfig = this.props.firebaseConfig;
    if (
      firebaseConfig !== null &&
      prevProps.firebaseConfig !== firebaseConfig
    ) {
      if (firebaseConfig['softUpdate'] && this._isNoAppUpdateAlertShowing()) {
        appUpdateAlertShown = true;
        confirm(
          firebaseConfig['alertMessage'],
          () => {
            appUpdateAlertShown = false;
            Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
            this.trackEvent('softUpdateAlertLinkClick', 'WelcomeScreen');
          },
          () => {
            appUpdateAlertShown = false;
            this.trackEvent('softUpdateAlertCloseClick', 'WelcomeScreen');
          },
          firebaseConfig['alertTitle'],
          firebaseConfig['alertCancelButtonTitle'],
          firebaseConfig['alertUpdateButtonTitle'],
        );
        this.trackEvent('softUpdateAlert', 'WelcomeScreen');
      } else {
        this.showForceUpdateAlert(firebaseConfig);
      }
    }
  };

  trackEvent(event: string, screen: string) {
    FirebaseAnalytic.trackEvent(event, screen, {
      ...this.props,
      ...this.state,
    });
  }

  showForceUpdateAlert(firebaseConfig) {
    appUpdateAlertShown = true;
    confirmNonDismissable(
      firebaseConfig['alertMessage'],
      () => {
        this.showForceUpdateAlert(firebaseConfig);
        Linking.openURL(firebaseConfig['alertUpdateActionUrl']);
        this.trackEvent('forceUpdateAlertLinkClick', 'WelcomeScreen');
      },
      firebaseConfig['alertTitle'],
      firebaseConfig['alertUpdateButtonTitle'],
    );
    this.trackEvent('forceUpdateAlert', 'WelcomeScreen');
  }

  goToEnvironmentSettings() {
    const env = this.props.env;
    FirebaseAnalytic.trackEvent('goToEnvironmentSettings', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
    });
    NavActions.push(AppRoutes.Environment, {
      saveChanges: WelcomeScreen.saveSettingChanges,
      env,
      demo: AccountStore.isDemo(),
      debug: AccountStore.isDebug(),
      showRetry: Platform.OS === 'android',
      showGma: true,
      showDemo: true,
      showDebug: true,
      showLanguage: true,
      showCreateAccount: true,
      onCountrySelectionPress: this.onCountrySelectionPress,
    });
  }

  onCountrySelectionPress = () => NavActions.push(AppRoutes.CountrySelection);

  async checkIfLoggedIn() {
    const accDetail = await PersistentStore.getAccountDetails();
    const gmaAccDetails = await PersistentStore.getGmaAccountDetails();
    FirebaseAnalytic.trackEvent('checkIfLoggedIn', 'WelcomeScreen', {
      ...this.props,
      ...this.state,
      accDetail,
    });
    if (
      gmaAccDetails.accountInfo?.FirstName === undefined &&
      gmaAccDetails.accountInfo?.Email
    ) {
      return false;
    }
    if (accDetail && accDetail.accountId !== '-1' && accDetail.username) {
      this.context.actions.changeRootRoute(AppRoutes.App);
      return true;
    }

    return false;
  }

  async loginWithAuth0() {
    // Set timeout as 500ms
    // so that redirect screen does not appear immediately
    this.myTimeout = setTimeout(() => {
      this.setState({showRedirectScreen: true});
    }, this.REDIRECT_SCREEN_SHOW_DELAY);

    const Auth0 = getMobileAuth0Client();
    const env = store.getState().environment.env;
    try {
      const credentials = await Auth0.webAuth.authorize(
        {
          scope: 'openid offline_access profile email',
          audience: Settings.auth0[env].audience,
          organization: Settings.auth0[env].orgIdAuth0,
        },
        {
          ephemeralSession: true,
        },
      );
      if (credentials) {
        const {snack} = this.props;
        this.verifyLogin(this.context, undefined, snack);
        await authStore.storeSession(credentials);
      }
    } catch (error) {
      clearInterval(this.myTimeout);
      this.setState({
        showRedirectScreen: false,
      });
      // no error user cancelled
      if (
        error &&
        error.message?.toLowerCase().indexOf('user cancelled') >= 0
      ) {
        return;
      }

      const guid = await uuid.getRandomUUID();

      Events.Error.trackEvent(
        'Exception',
        'Login:Error',
        error.toString(),
        guid,
      );

      alertError('Unable to login at this time, please try again later.');
    }
  }

  async webLoginAuth0() {
    await getWebAuth0Client().loginWithPopup();
    const user = await getWebAuth0Client().getUser();

    if (!user) {
      return;
    }
    const accessToken = await getWebAuth0Client().getTokenSilently({
      detailedResponse: true,
    });
    const idInfo = await getWebAuth0Client().getIdTokenClaims();

    await authStore.storeSession({
      accessToken: accessToken.access_token,
      idToken: idInfo.__raw,
      expiresAt: moment().unix() + accessToken.expires_in,
      tokenType: 'Bearer',
      scope: '',
      refreshToken: 'web',
      isSSO: idInfo['https://365rm.us/isSSO'],
    });

    const account = await ActionsFactory.getAccountActions().loginLegacy();

    if (account.firstName === null && account.email) {
      this.context.actions.changeRootRoute(AppRoutes.Login);
      const params = {
        accountId: account.accountId,
        email: account.unverifiedEmail,
        isSSO: account.isSSO,
        firstName: account.firstName,
        lastName: account.lastName,
        showPin: !account.pinHash,
        referralId: this.props?.referralId,
        token: this.props.token,
      };

      NavActions.push(AppRoutes.CountrySelection, params);
      return;
    } else if (account.unverifiedEmail) {
      sessionTimeOut();
      return;
    }
    await WelcomeScreen.onLoginSuccess(
      account,
      this.context,
      this.props.referralId,
      this.props.token,
      undefined,
      this.props.snack,
    );
  }

  async verifyLogin(context, initialTabRoute, snack) {
    let account = await ActionsFactory.getAccountActions().loginLegacy(true);

    if (
      account.type?.length === 0 ||
      (account.type === 'PANTRY' && Settings.buildType === BuildTypes.pantry) ||
      (account.type !== 'PANTRY' && Settings.buildType !== BuildTypes.pantry)
    ) {
      account = await ActionsFactory.getAccountActions().loginLegacy();
      try {
        if (!(account.localization.region && account.country)) {
          const locale = Settings.getLocale();
          const country = locale.substring(locale.length - 2);
          const code = get3LetterCountryCode(country);

          await ActionsFactory.getAccountActions().updateInfo(
            account.accountId,
            {
              country: account.country ?? code,
              region: account.localization.region ?? code,
            },
          );
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();

        Events.Error.trackEvent(
          'Error',
          'Redirect:Error',
          error.toString(),
          guid,
        );
      } finally {
        await WelcomeScreen.onLoginSuccess(
          account,
          context,
          initialTabRoute,
          snack,
        );
      }
    } else {
      alertError(
        Localized.Errors.user_login_credentials_is_not_valid,
        null,
        () => {
          NavActions.reset(AppRoutes.Welcome);
          ActionsFactory.getAccountActions().logout();
        },
        Localized.Errors.uh_oh,
      );
    }
  }

  static async onLoginSuccess(
    account: Account,
    context: ScreenProps,
    referralId: string,
    token: string,
    initialTabRoute?: AppRoutes,
    snack?: SnackAction,
  ) {
    DealRepository.clearDeals();
    if (!AccountStore.isDemo()) {
      OneSignal.sendTags({
        email: account.email,
        mka: account.accountId,
        env: store.getState().environment.env,
      });
      OneSignal.setExternalUserId(account.accountId);
    }

    if (account.accountId) {
      FirebaseAnalytic.setUserId(account.accountId, {
        accountId: account.accountId,
        email: account.email,
        unverifiedEmail: account.unverifiedEmail,
        mka: account.accountId,
        org: account.orgId,
        location: account.locationId,
        region: account.localization.region,
        city: account.city,
        zip: account.zip,
        currency: account.localization.currency,
        env: store.getState().environment.env,
        firstName: account.firstName,
        lastName: account.lastName,
        referralId: referralId,
        // isSSO: account.isSSO.toString(),
      });
    }

    Events.AccountLogin.trackEvent(
      DeviceInfo.getModel(),
      account.localization.region,
      account.localization.currency,
      account.isSSO,
      account.email,
    );

    if (snack) {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute, {
        nestedNames: [AppRoutes.Inbox],
        name: AppRoutes.SnackDetail,
        params: {snackLocationId: snack.locationId, ...snack},
      });
    } else if (Platform.OS !== 'web') {
      //TODO: Follow up for information about email/unverifiedEmail
      //TODO: unverifiedEmail is null after clicking the link in the email to continue setting up account
      if (account.firstName === null && account.email) {
        NavActions.reset(AppRoutes.Welcome);
        NavActions.push(AppRoutes.CountrySelection, {
          accountId: account.accountId,
          email: account.unverifiedEmail,
          firstName: account.firstName,
          lastName: account.lastName,
          showPin: !account.pinHash,
          referralId: referralId,
          token: token,
        });
      } else if (account.unverifiedEmail) {
        sessionTimeOut();
        return;
      } else {
        context.actions.changeRootRoute(
          AppRoutes.AppProductTour,
          initialTabRoute,
        );
      }
    } else {
      context.actions.changeRootRoute(AppRoutes.App, initialTabRoute);
    }

    SyncHelper.performSyncWithServer();
    ActionsFactory.getAccountActions().refreshMessages();
  }

  close() {
    NavActions.pop();
  }

  onLinkExistingAccount = () => NavActions.push(AppRoutes.LinkExistingAccount);
  onCreateAccount = () => NavActions.push(AppRoutes.CreateAccountEnterEmail);

  render() {
    let Wrapper: React.FC<React.PropsWithChildren<object>> = ({children}) => (
      <View style={Styles.Style.flex}>{children}</View>
    );
    if (Platform.OS === 'web') {
      Wrapper = ({children}) => (
        <Header title={Localized.Labels.welcome_back}>{children}</Header>
      );
    }
    const isWebSignOn = Platform.OS === 'web';
    const isCanteen = Settings.buildType === BuildTypes.canteen;

    return (
      <Wrapper>
        {!this.state.showRedirectScreen && (
          <View style={styles.view}>
            <FocusAwareStatusBar
              barStyle={UIManager.getLoginStatusBarStyle()}
              backgroundColor={Styles.loginBackground}
            />
            <DeepLinkHandler
              launchAuth0Login={
                Platform.OS === 'web' ? this.webLoginAuth0 : this.loginWithAuth0
              }
            />
            <CreateAccountPrivacyPolicyHandler
              ref={(privacyHandler) => {
                this.privacyHandler = privacyHandler;
              }}
            />

            <View style={styles.logoContainer}>
              <TouchableWithoutFeedback
                accessible={false}
                onPress={this.logoPress}
              >
                <View>
                  <Logo />
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                    testID="welcomeHeaderText"
                    accessible={true}
                    accessibilityLabel="welcome"
                    accessibilityRole="header"
                    style={styles.instructionsWelcome}
                  >
                    {Localized.Labels.welcome}
                  </AVText>
                </View>
              </TouchableWithoutFeedback>
            </View>

            <View style={styles.content}>
              <View style={styles.welcomeView}>
                {/* To Do: Modify Buttons */}
                <RoundedButton
                  accessible={true}
                  testID={'logInButtonWelcomeScreen'}
                  accessibilityHint="Go to the login screen"
                  accessibilityRole="button"
                  buttonType={ButtonType.normal}
                  containerStyle={styles.topButton}
                  textStyle={styles.textStyleTop}
                  color={isCanteen ? '#003349' : Styles.primaryColor}
                  onPress={
                    isWebSignOn ? this.webLoginAuth0 : this.loginWithAuth0
                  }
                  text={Localized.Buttons.log_in}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                />
                <RoundedButton
                  accessibilityRole="button"
                  accessible={true}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                  testID="linkExistingAccountButton"
                  color={isCanteen ? '#003349' : Styles.primaryColor}
                  accessibilityHint="Link existing account"
                  textStyle={isCanteen ? styles.canteenText : styles.textStyle}
                  text={Localized.Buttons.link_account}
                  containerStyle={styles.topButton}
                  onPress={this.onLinkExistingAccount}
                  buttonType={ButtonType.outline}
                />
                <View style={styles.lineContainer}>
                  <View style={styles.solidLine} />
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                    testID="orText"
                    accessible={true}
                    accessibilityLabel="or"
                    accessibilityRole="text"
                    style={styles.orText}
                  >
                    {Localized.Labels.or}
                  </AVText>
                  <View style={styles.solidLine} />
                </View>
                <View style={styles.createAccountButton}>
                  <RoundedButton
                    accessible={true}
                    testID="createAccountButton"
                    accessibilityHint="Create Account"
                    accessibilityRole="button"
                    buttonType={ButtonType.outline}
                    color={isCanteen ? '#003349' : Styles.primaryColor}
                    containerStyle={styles.topButton}
                    textStyle={
                      isCanteen ? styles.canteenText : styles.textStyle
                    }
                    onPress={this.onCreateAccount}
                    text={Localized.Buttons.create_account_cap}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                  />
                </View>
              </View>
            </View>
            <AVText
              accessible={true}
              accessibilityLabel={`Application version: ${this.state.fullVersion}`}
              style={styles.versionText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            >
              {this.state.fullVersion}
            </AVText>
          </View>
        )}
        {this.state.showRedirectScreen && (
          <View style={styles.redirectBackground}>
            <PulseIndicator
              size={120}
              color={Styles.bgColor}
              style={styles.indicator}
            />
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              style={styles.redirectCaption}
            >
              {Localized.Labels.redirect_message}
            </AVText>
          </View>
        )}
      </Wrapper>
    );
  }
}

const styles = StyleSheet.create({
  view: {
    backgroundColor,
    flex: 1,
  },
  logoContainer: {
    alignSelf: 'center',
    height: Styles.Heights.h3,
    justifyContent: 'center',
    paddingTop: Styles.Spacing.m1,
    marginTop: -30,
    marginBottom: -100,
    flex: 1,
  },
  content: {
    marginBottom: Styles.Spacing.m5,
    width: Platform.OS === 'web' ? null : ScreenWidth,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m2,
  },
  solidLine: {
    height: 1,
    flex: 1,
    backgroundColor: '#999',
    marginHorizontal: Styles.Spacing.m2,
  },
  instructionsWelcome: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: Styles.Spacing.m1,
  },

  instructions: {
    color: Styles.black,
    fontSize: Styles.Fonts.f4,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: Styles.Spacing.m3,
  },

  versionText: {
    color: Styles.primaryColor,
    backgroundColor: Styles.transparent,
    fontSize: Styles.Fonts.f0,
    left: Styles.Spacing.m4,
    bottom: 20,
    position: 'absolute',
  },
  indicator: {
    flex: 0.5,
  },
  redirectCaption: {
    color: Styles.bgColor,
    fontSize: Styles.Fonts.f5,
    fontWeight: '700',
    marginTop: 0,
    paddingTop: 0,
    marginStart: 8,
    marginEnd: 8,
    textAlign: 'center',
  },
  topButton: Platform.select({
    default: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
  }),
  createAccountButton: {
    marginTop: -13,
    alignSelf: 'auto',
    paddingVertical: Styles.Spacing.m1,
  },
  textStyleTop: {
    flex: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: Styles.white,
  },
  textStyle: {
    flex: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: Styles.primaryColor,
  },
  canteenText: {
    flex: 1,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: '#003349',
  },
  orText: {
    flex: 0.5,
    textAlign: 'center',
    fontSize: Styles.Fonts.f1,
    color: Styles.black,
    textTransform: 'uppercase',
  },
  welcomeView: {
    marginHorizontal: Styles.Spacing.m4,
    marginTop: 120,
    width: Platform.OS === 'web' ? '40%' : ScreenWidth - Styles.Spacing.m4 * 2,
  },
  redirectBackground: {
    backgroundColor: Styles.primaryColor,
    minHeight: Styles.ScreenHeight,
    minWidth: Styles.ScreenWidth,
    alignSelf: 'center',
  },
});
export default compose(
  withForwardedNavigationParams<WelcomeProps>(),
  connect((state: RootState) => ({
    env: state.environment?.env,
    firebaseConfig: state.firebaseConfig?.data,
  })),
)(WelcomeScreen);
