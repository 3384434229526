import AccountApi from 'src/api/AccountApi';
import MoblicoApi from 'src/api/MoblicoApi';
import MoblicoTokenService from './MoblicoTokenService';
import Events from 'src/logging/Events';
export const metricTypes = {
  EnterPage: 'Enter_Page',
  AdClick: 'Ad_Click',
  ViewDeal: 'View_Deal',
  AdView: 'View_Promo',
};

class MoblicoService {
  async registerUser(
    email: string,
    accountId: string,
    consumerEngagementId: string,
    orgId: string,
    locationId: string,
    checkGroups: boolean,
  ): Promise<string | null> {
    const moblicoGroups = [orgId, locationId];
    if (!consumerEngagementId && orgId && locationId) {
      const token = await MoblicoTokenService.getToken();
      try {
        await MoblicoApi.registerUser(token, email, accountId);
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'MoblicoService:RegisterUser',
          error.message ? error.message : error.toString(),
        );
      }

      const userToken = await MoblicoTokenService.getToken(email);
      moblicoGroups.forEach(async (group: string) => {
        await MoblicoApi.addUserToGroup(userToken, email, group);
      });
      const userResponse: any = await MoblicoApi.getUser(userToken, email);

      if (userResponse.id) {
        await AccountApi.updateConsumerEngagementId(accountId, userResponse.id);
        return userResponse.id;
      }
    } else if (checkGroups && orgId && locationId) {
      const userToken = await MoblicoTokenService.getToken(email);
      const existingGroups = await MoblicoApi.getUserGroups(userToken, email);
      let existingGroupNames = [];

      if (existingGroups && existingGroups.length > 0) {
        existingGroupNames = existingGroups.map((group) => group.name);
      }

      // Add any new groups
      moblicoGroups.forEach(async (group) => {
        if (!existingGroupNames || existingGroupNames.indexOf(group) < 0) {
          await MoblicoApi.addUserToGroup(userToken, email, group);
        }
      });
      // Remove any old groups
      existingGroupNames.forEach(async (group) => {
        if (!moblicoGroups || moblicoGroups.indexOf(group) < 0) {
          await MoblicoApi.removeUserFromGroup(userToken, email, group);
        }
      });
    }

    return null;
  }

  async sendMetric(email: string, type: string, text: string | number) {
    const userToken = await MoblicoTokenService.getToken(email);
    return MoblicoApi.sendMetric(userToken, type, text);
  }

  async sendApplePayMetric(email: string, amount: number) {
    const userToken = await MoblicoTokenService.getToken(email);
    return MoblicoApi.sendApplePayMetric(userToken, amount);
  }

  async updateUserEmail(currentEmail: string, newEmail: string) {
    try {
      const userToken = await MoblicoTokenService.getToken(currentEmail);
      return MoblicoApi.updateUserEmail(userToken, currentEmail, newEmail);
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'MoblicoService:UpdateUserEmail',
        error.message ? error.message : error.toString(),
      );
    }
  }
}

export default new MoblicoService();
