import moment from 'moment';
import * as React from 'react';
import {
  FlatList,
  Image,
  Platform,
  RefreshControl,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {Avatar} from 'react-native-elements';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import SwipeableRow from '../../elements/DeleteSwipeableRow';
import Header from '../../elements/Header';
import ShadowContainer from '../../elements/ShadowContainer';
import DefaultLogo from '../../img/svg/365Logo';
import SFLogo from '../../img/svg/SFLogo';
import Star from '../../img/svg/inbox/Star';
import Water from '../../img/svg/inbox/Water';
import Styles from '../../Styles';
import type {MessageType} from 'src/types/MessageType';
import ActionsFactory from 'src/actions/ActionsFactory';
import MessageRepository from 'src/services/aws/MessageRepository';
import AccountStore from 'src/stores/AccountStore';
import Deal from '../../elements/promotions/Deal';
import SwitchSelector from '../../elements/SwitchSelector';
import DealService from 'src/services/DealService';
import DealRepository from 'src/services/aws/DealRepository';
import MarketLogo from '../../img/svg/MarketLogo';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AVText from '../../elements/AVText';
import ReferralItem from '../../elements/ReferralItem';
import type {Referral} from 'src/types/Referral';
import {ReferralStatus} from 'src/types/Referral';
import Events from 'src/logging/Events';
import ReferralActions from 'src/actions/ReferralActions';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import HttpClient from 'src/api/HttpClient';
import Util from 'src/Util';
import TimeUtils from 'src/services/TimeUtils';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess, confirm} from '../../helpers/AlertHelper';
import type {GiftItem as GiftItemType} from 'src/types/Snack';
import SnackItem from '../../elements/SnackItem';
import DealModel from 'src/models/Moblico/Deal';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {loadSnacks, LoadSnacksParams} from 'src/redux/thunks/snackThunks';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

type InboxScreenProps = {
  navigation: NavigationProp<InboxScreen>;
  loadSnacks(params: LoadSnacksParams): Promise<Array<GiftItemType>>;
};
type InboxScreenState = {
  messages: Array<DealModel | MessageType | Referral | GiftItemType>;
  activeDeals: Array<DealModel>;
  pastDeals: Array<DealModel>;
  refreshing: boolean;
  filterType: filterTypes;
  referrals: Array<Referral>;
  snacks: Array<GiftItemType>;
};

export enum filterTypes {
  all = 'all',
  active = 'active',
  past = 'past',
}

export enum filterPositions {
  all = 0,
  active = 1,
  past = 2,
}

class InboxScreen extends React.Component<InboxScreenProps, InboxScreenState> {
  didFocusSubscription;
  didBlurSubscription;

  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: InboxScreenProps) {
    super(props);
    this.state = {
      messages: [],
      activeDeals: [],
      pastDeals: [],
      refreshing: false,
      filterType: filterTypes.all,
      referrals: [],
      snacks: [],
    };
    this.renderItem = this.renderItem.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.onMessagePress = this.onMessagePress.bind(this);
    this.onDealPress = this.onDealPress.bind(this);
    this.onMessagesChanged = this.onMessagesChanged.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.onDeletePressed = this.onDeletePressed.bind(this);
    this.reload = this.reload.bind(this);
    this.loadReferrals = this.loadReferrals.bind(this);
    this.updateReferral = this.updateReferral.bind(this);
    this.loadSnacks = this.loadSnacks.bind(this);
    this.getKey = this.getKey.bind(this);
    this.updateInbox().catch();
  }

  componentDidMount() {
    MessageRepository.addMessageListener(this.onMessagesChanged);
    DealRepository.addDealListener(this.onMessagesChanged);

    if (Platform.OS === 'web') {
      ActionsFactory.getAccountActions().refreshMessages().catch();
    }

    this.didFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        this.loadReferrals().catch();
        this.loadSnacks().catch();
      },
    );
    this.didBlurSubscription = this.props.navigation.addListener('blur', () => {
      this.context.actions.resetFilterType();
    });
  }

  async loadReferrals() {
    try {
      const shouldLoadReferrals =
        AccountStore.getReferralStat() && AccountStore.getReferralCampaign();

      if (shouldLoadReferrals) {
        const referrals = await ReferralActions.getReferrals(
          AccountStore.getAccountId(),
        );
        Logger.Log.LogAPIEvent(
          'PlatformAPI',
          'FetchReferrals',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(referrals),
        );
        this.setState(
          {
            referrals,
          },
          () => {
            this.updateInbox();
          },
        );
      }
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'InboxScreen:loadReferrals',
        e.message ? e.message : e.toString(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferrals',
        JSON.stringify({accountId: AccountStore.getAccountId()}),
        JSON.stringify(e),
      );
    }
  }

  componentWillUnmount() {
    MessageRepository.removeMessageListener(this.onMessagesChanged);
    DealRepository.removeDealListener(this.onMessagesChanged);
    this.didFocusSubscription();
    this.didBlurSubscription();
  }

  onMessagesChanged() {
    this.updateInbox().catch();
  }

  async updateInbox() {
    const messages = await MessageRepository.getMessages();
    let deals: DealModel[] = [];
    try {
      if (
        AccountStore.getConsumerEngagementId() &&
        AccountStore.isConsumerEngagementEnabled()
      ) {
        deals = DealService.getDeals();
      }

      const activeDeals = deals.filter((deal) =>
        DealService.isDealActive(deal),
      );
      const pastDeals = deals.filter((deal) => {
        return !activeDeals.some((activeDeal) => activeDeal.id === deal.id);
      });
      const allMessages = [
        ...messages,
        ...activeDeals,
        ...this.state.referrals,
        ...this.state.snacks,
      ] as Array<MessageType & DealModel & Referral & GiftItemType>;
      allMessages.sort((a, b) => {
        const bDate = this.getSortDate(b);
        const aDate = this.getSortDate(a);
        return bDate - aDate;
      });

      activeDeals.sort(
        (
          a: MessageType & Referral & GiftItemType & DealModel,
          b: MessageType & Referral & GiftItemType & DealModel,
        ) => {
          const bDate = this.getSortDate(b);
          const aDate = this.getSortDate(a);
          return aDate - bDate;
        },
      );
      this.setState({
        messages: allMessages,
        activeDeals,
        pastDeals,
        refreshing: false,
      });
      FirebaseAnalytic.trackEvent('updateInbox', 'InboxScreen: updateInbox', {
        ...this.props,
        ...this.state,
      });
    } catch (e) {
      Events.Error.trackEvent(
        'Exception',
        'InboxScreen:updateInbox',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getSortDate(item: MessageType & Referral & GiftItemType & DealModel) {
    if (item.subject) {
      return moment(item.date).valueOf();
    }

    if (item.snackId) {
      return moment(item.snackSent).valueOf();
    }

    if (item.campaign) {
      return moment(item.dateCreated).valueOf();
    }

    return moment(item.endDate).valueOf();
  }

  async loadSnacks() {
    const snacks = await this.props.loadSnacks({
      locationId: AccountStore.getLocationId(),
    });
    if (snacks) {
      FirebaseAnalytic.trackEvent('loadSnacks', 'InboxScreen: loadSnacks', {
        ...this.props,
        ...this.state,
      });
    }
    this.setState({snacks}, () => {
      this.updateInbox();
    });
  }

  onMessagePress(message: MessageType) {
    ActionsFactory.getAccountActions().messageRead(message).catch();
    FirebaseAnalytic.trackEvent(
      'onMessagePress',
      'InboxScreen: onMessagePress',
      {
        ...this.props,
        ...this.state,
      },
    );

    NavActions.push(AppRoutes.MessageDetail, {
      message,
      renderIcon: this.renderIcon,
    });
  }

  onDealPress(deal: DealModel) {
    FirebaseAnalytic.trackEvent('onDealPress', 'InboxScreen: onDealPress', {
      ...this.props,
      ...this.state,
    });
    NavActions.push(AppRoutes.DealDetail, {
      deal,
    });
  }

  async onReferralClaim(referral: Referral, index: number) {
    this.context.actions.showSpinner();

    try {
      const redeemReferralResponse = await ReferralActions.redeemReferral(
        AccountStore.getAccountId(),
        referral.referralId,
        Util.getCurrentDate(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(redeemReferralResponse),
      );
      this.updateReferral(referral, index, ReferralStatus.Claimed);
      alertSuccess(Localized.Labels.claimed_successfully);
      FirebaseAnalytic.trackEvent(
        'onReferralClaim',
        'InboxScreen: onReferralClaim',
        {
          ...this.props,
          ...this.state,
        },
      );
      this.reload().catch();
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());
      let message = Localized.Labels.unable_to_redeem;

      if (error?.code === PlatformApiErrors.CampaignAlreadyExpired) {
        message = Localized.Labels.campaign_already_expired;
      } else if (error?.code === PlatformApiErrors.ReferralAlreadyRedeemed) {
        message = Localized.Labels.reward_already_redeemed;
      }

      this.updateReferral(referral, index, ReferralStatus.Expired);
      alertError(message);
      Events.Error.trackEvent(
        'Exception',
        'InboxScreen:onReferralClaim',
        error,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  updateReferral(referral, index, status) {
    const {messages} = this.state;
    const field =
      referral.sender === AccountStore.getAccountId()
        ? 'senderRedeem'
        : 'receiverRedeem';
    referral[field] = status;
    messages[index] = referral;
    this.setState({
      messages,
    });
    FirebaseAnalytic.trackEvent(
      'updateReferral',
      'InboxScreen: updateReferral',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  async reload() {
    await ActionsFactory.getAccountActions().reloadConsumerData({
      accountId: AccountStore.getAccountId(),
      accountBalanceId: AccountStore.getAccountBalanceId(),
      email: AccountStore.getEmail(),
      userInitiated: true,
    });
  }

  onDeletePressed(message: MessageType) {
    confirm(Localized.Labels.confirm_delete_message, () => {
      ActionsFactory.getAccountActions().deleteMessage(message).catch();
    });
    FirebaseAnalytic.trackEvent(
      'onDeletePressed',
      'InboxScreen: onDeletePressed',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  renderAppLogo() {
    if (Settings.buildType === BuildTypes.default) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_365Pay}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_365Pay}
        >
          <DefaultLogo size={Styles.Fonts.f4} />
        </View>
      );
    } else if (Settings.buildType === BuildTypes.social) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_social_feedia}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_social_feedia}
        >
          <SFLogo size={Styles.Fonts.f4} />
        </View>
      );
    } else if (Settings.buildType === BuildTypes.market) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_market_connect}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_market_connect}
        >
          <MarketLogo size={Styles.Fonts.f4} fillColor={Styles.black} />
        </View>
      );
    } else {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_canteen}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_canteen}
        >
          <Image
            style={styles.icon}
            source={require('../../img/canteen-icon.png')}
          />
        </View>
      );
    }
  }

  renderIcon(item: MessageType) {
    switch (item.icon) {
      case 'app':
        return this.renderAppLogo();

      case 'star':
        return <Star width={Styles.Fonts.f4} height={Styles.Fonts.f4} />;

      case 'water':
        return <Water width={Styles.Fonts.f4} height={Styles.Fonts.f4} />;

      default:
        return (
          <View
            accessible={true}
            accessibilityLabel={this.getMessageTitle(item)}
            accessibilityRole="image"
            role="img"
            aria-label={this.getMessageTitle(item)}
          >
            <Avatar
              rounded
              title={this.getMessageTitle(item)}
              size={Styles.Fonts.f4}
            />
          </View>
        );
    }
  }

  getMessageTitle(message: MessageType) {
    let title = '365';

    if (message.from) {
      const pieces = message.from.split(' ');

      if (pieces.length === 1) {
        title = pieces[0].charAt(0).toUpperCase();
      } else {
        title =
          pieces[0].charAt(0).toUpperCase() + pieces[1].charAt(0).toUpperCase();
      }
    }

    return title;
  }

  _onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    ActionsFactory.getAccountActions().refreshMessages().catch();
    this.loadReferrals().catch();
    this.loadSnacks().catch();

    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.isConsumerEngagementEnabled()
    ) {
      DealService.loadMoblicoDeals(AccountStore.getEmail()).catch();
    }
  };

  getDataSource(filterType: filterTypes) {
    if (filterType === filterTypes.all) {
      return this.state.messages;
    }

    if (filterType === filterTypes.active) {
      return this.state.activeDeals;
    }

    return this.state.pastDeals;
  }

  getKey(item) {
    if (item.subject) {
      return `${item.id}${item.date}`;
    }

    if (item.snackId) {
      return `${item.snackId}${item.snackSent}`;
    }

    if (item.campaign) {
      return `${item.campaignId}${item.dateCreated}`;
    }

    return `${item.id}${item.endDate}`;
  }

  renderItem({
    item,
    index,
  }: {
    item: MessageType & Referral & GiftItemType & DealModel;
    index: number;
  }) {
    if (item.message) {
      return this.renderMessage(item);
    }

    if (item.campaign) {
      return (
        <ReferralItem
          referral={item}
          onPress={() => this.onReferralClaim(item, index)}
          {...this.context}
        />
      );
    }

    if (item.snackId) {
      return (
        <SnackItem
          snack={item}
          onPress={() => {
            NavActions.push(AppRoutes.SnackDetail, {
              snackId: item.snackId,
              snackLocationId: item.locationId,
            });
          }}
          {...this.context}
        />
      );
    }

    return <Deal deal={item} onPress={this.onDealPress} {...this.context} />;
  }

  renderMessage(item: MessageType) {
    return (
      <View style={styles.borderContainer}>
        <SwipeableRow
          onDeletePressed={() => this.onDeletePressed(item)}
          type="delete"
        >
          <TouchableOpacity
            onPress={() => this.onMessagePress(item)}
            style={styles.messageContainer}
          >
            {this.renderIcon(item)}
            <View style={styles.messageLeft}>
              <View style={styles.topRow}>
                <AVText style={styles.from}>{item.from}</AVText>
                <View style={styles.topRight}>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    style={styles.date}
                  >
                    {TimeUtils.formatInboxDate(item.date)}
                  </AVText>
                </View>
              </View>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                style={styles.subject}
              >
                {item.subject}
              </AVText>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                style={styles.message}
                numberOfLines={2}
              >
                {item.message}
              </AVText>
            </View>
            <View style={styles.arrow}>
              <FontAwesome5Pro
                name="chevron-right"
                color={Styles.lightGray}
                size={Styles.Fonts.f2}
                light
              />
            </View>
          </TouchableOpacity>
        </SwipeableRow>
      </View>
    );
  }

  render() {
    const data = this.getDataSource(this.state.filterType) as Array<
      MessageType & Referral & GiftItemType & DealModel
    >;
    return (
      <Header
        title={Localized.Labels.inbox}
        bottomView={
          <SwitchSelector
            value={this.context.state.filterType}
            onPress={(value: filterTypes) => this.setState({filterType: value})}
            options={[
              {
                text: Localized.Labels.messages,
                label: Localized.Labels.messages,
                value: 'all',
              },
              {
                text: Localized.Labels.active_offers,
                label: Localized.Labels.active_offers,
                value: 'active',
              },
              {
                text: Localized.Labels.past_offers,
                label: Localized.Labels.past_offers,
                value: 'past',
              },
            ]}
          />
        }
      >
        <ShadowContainer style={styles.content}>
          <FlatList
            refreshControl={
              <RefreshControl
                refreshing={this.state.refreshing}
                onRefresh={this._onRefresh}
              />
            }
            data={data}
            keyExtractor={(item) => this.getKey(item)}
            renderItem={this.renderItem}
            ListEmptyComponent={
              <AVText style={styles.emptyMessage}>
                {Localized.Labels.no_messages}
              </AVText>
            }
          />
        </ShadowContainer>
      </Header>
    );
  }
}

const styles = StyleSheet.create({
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  borderContainer: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  content: {},
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  emptyMessage: {
    margin: Styles.Spacing.m3,
    textAlign: 'center',
  },
  from: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
  icon: {
    borderRadius: Styles.Fonts.f4 / 2,
    height: Styles.Fonts.f4,
    resizeMode: 'contain',
    width: Styles.Fonts.f4,
  },
  message: {
    color: Styles.lightGray,
    flex: 1,
    fontSize: Styles.Fonts.f0,
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: Styles.Spacing.m3,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  messageLeft: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
  },
  subject: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
  topRight: {
    flexDirection: 'row',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default compose(
  withForwardedNavigationParams<InboxScreenProps>(),
  connect(null, (dispatch: AppDispatch) => ({
    loadSnacks: (params) => dispatch(loadSnacks(params)).unwrap(),
  })),
)(InboxScreen);
