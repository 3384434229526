import React from 'react';
import {View, StyleSheet, AppState} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {AnimatedCircularProgress} from 'react-native-circular-progress';
import {withGlobalize} from 'react-native-globalize';
import {Snackbar} from 'react-native-paper';
import {VendorsExchangeDeviceEvents} from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import Header from '../../elements/Header';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import TransactionAction from 'src/actions/TransactionActions';
import NavActions from 'src/actions/NavActions';
import Events, {VendorsExchangeActions} from 'src/logging/Events';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import Keypad, {
  KeypadTypes,
} from '../../elements/vending/keypads/vendorsExchange/VendorsExchangeKeypad';
import type {KeypadDisplay} from '../../elements/vending/keypads/vendorsExchange/types';
import AccountStore from 'src/stores/AccountStore';
import {PaymentRequest} from 'src/services/bluetooth/vendorsExchange/PaymentRequestCharacteristic';
import {
  PaymentStatus,
  SelectionStatus,
} from 'src/services/bluetooth/vendorsExchange/PaymentStatusCharacteristic';
import VendSession, {VendingStates} from 'src/services/VEVendSession';
import AppRoutes from 'src/AppRoutes';
import Util from 'src/Util';
import UIManager from '../../elements/ui/UIManager';
import ScreenContext from '../../ScreenContext';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

const MAX_KEY_SELECTIONS = 4;
type VendorsExchangeVendingScreenStateType = {
  selections: Array<string>;
  allowMoreSelections: boolean;
  buttonLabel: string;
  snackbarText: string;
  snackbarVisible: boolean;
  snackbarError: boolean;
  timeRemaining: number;
};

class VendorsExchangeVendingScreen extends React.Component<
  Record<string, never>,
  VendorsExchangeVendingScreenStateType
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  interval: any;
  handleAppStateChangeSubscription: any;

  constructor(props) {
    super(props);
    this.state = {
      selections: [],
      allowMoreSelections: true,
      buttonLabel: Localized.Buttons.cancel,
      snackbarText: '',
      snackbarVisible: false,
      snackbarError: false,
      timeRemaining: VendSession.MachineConfig.TimeoutBetweenVends,
    };
    this.onKeyClick = this.onKeyClick.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.clearPressed = this.clearPressed.bind(this);
    this.onPaymentRequested = this.onPaymentRequested.bind(this);
    this.onStatusUpdated = this.onStatusUpdated.bind(this);
    this.btnPressed = this.btnPressed.bind(this);
    this.onDismissSnackbar = this.onDismissSnackbar.bind(this);
    this.handleAppStateChange = this.handleAppStateChange.bind(this);
    this.clearCountdownInterval = this.clearCountdownInterval.bind(this);
  }

  componentDidMount() {
    FirebaseAnalytic.trackEvent(
      'componentDidMount',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    VendSession.Device.on(
      VendorsExchangeDeviceEvents.paymentRequested,
      this.onPaymentRequested,
    );
    VendSession.Device.on(
      VendorsExchangeDeviceEvents.statusUpdated,
      this.onStatusUpdated,
    );
    this.interval = setInterval(() => {
      if (this.state.timeRemaining <= 0) {
        this.btnPressed();
      } else {
        this.setState({
          timeRemaining: this.state.timeRemaining - 1,
        });
      }
    }, 1000);
    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange,
    );
  }

  clearCountdownInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  componentWillUnmount() {
    this.clearCountdownInterval();
    this.handleAppStateChangeSubscription.remove();
    VendSession.Device.removeListener(
      VendorsExchangeDeviceEvents.paymentRequested,
      this.onPaymentRequested,
    );
    VendSession.Device.removeListener(
      VendorsExchangeDeviceEvents.statusUpdated,
      this.onStatusUpdated,
    );
  }

  onStatusUpdated(paymentStatus: PaymentStatus) {
    FirebaseAnalytic.trackEvent(
      'paymentStatus',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (paymentStatus.status === SelectionStatus.selectionDeny) {
      this.handleSelectionDenied();
    } else if (paymentStatus.status === SelectionStatus.selectionOk) {
      this.handleSelectionOk(paymentStatus);
    } else if (paymentStatus.status === SelectionStatus.selectionKo) {
      this.handleSelectionProblem();
    } else if (paymentStatus.status === SelectionStatus.standby) {
      this.handleSelectionInvalid();
    }
  }

  handleAppStateChange(currentAppState: string) {
    FirebaseAnalytic.trackEvent(
      'handleAppStateChange',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
        currentAppState,
      },
    );

    if (currentAppState !== 'active') {
      if (VendSession.PendingItem) {
        Events.Vend.trackEvent('CLOSE_VEND_PENDING');
      }

      this.btnPressed();
    }
  }

  handleSelectionInvalid() {
    FirebaseAnalytic.trackEvent(
      'handleSelectionInvalid',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (VendSession.State !== VendingStates.waitingForSelection) {
      this.displaySnackbar(Localized.Errors.invalid_selection, true);
      VendSession.clearPendingItem();
      this.clearPressed();
    }
  }

  handleSelectionDenied() {
    FirebaseAnalytic.trackEvent(
      'handleSelectionDenied',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (VendSession.State !== VendingStates.waitingForSelection) {
      this.displaySnackbar(Localized.Labels.insufficient_balance, true);
      VendSession.clearPendingItem();
      this.clearPressed();
    }
  }

  displaySnackbar(text: string, error = false) {
    FirebaseAnalytic.trackEvent(
      'displaySnackbar',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
        text,
        error,
      },
    );
    this.context.actions.hideSpinner();
    this.setState({
      snackbarText: text,
      snackbarVisible: true,
      snackbarError: error,
    });
  }

  resetTimerForVend() {
    let newTimeRemaining =
      this.state.timeRemaining +
      VendSession.MachineConfig.TimeoutBetweenVends / 2;

    FirebaseAnalytic.trackEvent(
      'resetTimerForVend',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
        newTimeRemaining,
      },
    );

    if (newTimeRemaining > VendSession.MachineConfig.TimeoutBetweenVends) {
      newTimeRemaining = VendSession.MachineConfig.TimeoutBetweenVends;
    }

    this.setState({
      timeRemaining: newTimeRemaining,
    });
  }

  checkToCloseSession() {
    if (
      VendSession.Items.length >=
        VendSession.MachineConfig.VendsPerTransaction ||
      VendSession.AuthorizationAmount <= 0
    ) {
      this.btnPressed();
    }
  }

  handleSelectionOk(paymentStatus: PaymentStatus) {
    FirebaseAnalytic.trackEvent(
      'resetTimerForVend',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
        paymentStatus,
      },
    );
    if (VendSession.State === VendingStates.waitingForStatus) {
      this.displaySnackbar(
        `${Localized.Labels.item_vended} ${Util.formatCurrency(
          this.props,
          paymentStatus.paymentRequest.selectionPrice,
          AccountStore.getCurrency(),
        )}`,
      );
      VendSession.addItemToCart(paymentStatus.paymentRequest);
      this.clearPressed();
      this.setState({
        buttonLabel: Localized.Buttons.finish,
      });
      this.checkToCloseSession();
    } else {
      Events.VendorsExchange.trackEvent(VendorsExchangeActions.InvalidState, {
        expected: VendingStates.waitingForStatus,
        actual: VendSession.State,
      });
    }
  }

  handleSelectionProblem() {
    FirebaseAnalytic.trackEvent(
      'handleSelectionProblem',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (VendSession.State !== VendingStates.waitingForSelection) {
      this.displaySnackbar(Localized.Errors.item_failed_to_vend, true);
      VendSession.clearPendingItem();
      this.clearPressed();
    }
  }

  onPaymentRequested(paymentRequest: PaymentRequest) {
    FirebaseAnalytic.trackEvent(
      'onPaymentRequested',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (VendSession.State === VendingStates.waitingForRequest) {
      const approved =
        paymentRequest.selectionPrice <= AccountStore.getAccountBalance();
      VendSession.Device.approvePayment(paymentRequest, approved);

      if (approved) {
        this.resetTimerForVend();
        VendSession.addPendingItem(paymentRequest);
      }
    } else {
      Events.VendorsExchange.trackEvent(VendorsExchangeActions.InvalidState, {
        expected: VendingStates.waitingForRequest,
        actual: VendSession.State,
      });
    }
  }

  btnPressed() {
    FirebaseAnalytic.trackEvent('btnPressed', 'VendorsExchangeVendingScreen', {
      ...this.props,
      ...this.state,
    });

    this.context.actions.hideSpinner();
    this.clearCountdownInterval();

    if (VendSession.Items.length > 0 || VendSession.PendingItem) {
      this.captureTransaction();
    } else {
      this.cancelTransaction();
    }
  }

  async enterPressed() {
    FirebaseAnalytic.trackEvent(
      'enterPressed',
      'VendorsExchangeVendingScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    try {
      if (VendSession.State === VendingStates.waitingForSelection) {
        this.context.actions.showSpinner(`${Localized.Labels.vending}...`);
        const userId = '';
        const credit = VendSession.AuthorizationAmount;
        VendSession.setState(VendingStates.waitingForRequest);
        await VendSession.Device.startPayment(
          userId,
          credit,
          this.state.selections,
          AccountStore.getCurrency(),
        );
      } else {
        Events.VendorsExchange.trackEvent(VendorsExchangeActions.InvalidState, {
          expected: VendingStates.waitingForSelection,
          actual: VendSession.State,
        });
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'VendorsExchangeVendingScreen:enterPressed',
        error.message ? error.message : error.toString(),
      );
    }
  }

  clearPressed() {
    this.setState({
      selections: [],
      allowMoreSelections: true,
    });
  }

  cancelTransaction() {
    TransactionAction.cancelTransaction(
      VendSession.MachineConfig.TransactionId,
    );
    Events.Vend.trackEvent('VEND_CANCEL');
    VendSession.Device.disconnect();
    NavActions.pop();
  }

  captureTransaction() {
    if (VendSession.PendingItem) {
      VendSession.addItemToCart(VendSession.PendingItem);
    }

    const avLiveItems = VendSession.AVLiveItems;
    TransactionAction.captureTransaction(
      VendSession.MachineConfig.TransactionId,
      avLiveItems,
    );
    Events.Vend.trackEvent(
      'VEND_CAPTURE',
      VendSession.MachineConfig.TransactionId,
      VendSession.Items.length,
    );
    VendSession.Device.disconnect();
    NavActions.replace(AppRoutes.Receipt, {
      totalAmt: VendSession.CartTotal,
      items: avLiveItems,
      subtotal: 0,
      taxes: [],
      returnRoute: AppRoutes.Machines,
      insideTab: true,
      deviceId: VendSession.Device.deviceId,
    });
  }

  onKeyClick(value: KeypadDisplay) {
    const newSelections: Array<string> = [...this.state.selections, value];
    this.setState(
      {
        selections: newSelections,
        allowMoreSelections: newSelections.length < MAX_KEY_SELECTIONS,
      },
      () => {
        FirebaseAnalytic.trackEvent(
          'onKeyClick',
          'VendorsExchangeVendingScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
      },
    );
  }

  onDismissSnackbar() {
    this.setState({
      snackbarVisible: false,
    });
  }

  render() {
    const {
      selections,
      timeRemaining,
      allowMoreSelections,
      buttonLabel,
      snackbarError,
      snackbarText,
      snackbarVisible,
    } = this.state;
    const hasSelections = selections && selections.length > 0;
    let selectionText = Localized.Labels.make_your_selection;

    if (hasSelections) {
      selectionText = `${Localized.Labels.selection}: ${selections.join('')}`;
    }

    const timerProgress =
      (timeRemaining / VendSession.MachineConfig.TimeoutBetweenVends) * 100;
    return (
      <Header
        title={VendSession.Device.name}
        rightView={UIManager.getBalanceContainer(false, Localized)}
      >
        <View style={styles.content}>
          <View style={styles.topSection}>
            <AVText
              style={[
                styles.instruction,
                hasSelections && styles.selectionText,
              ]}
            >
              {selectionText}
            </AVText>
            <AnimatedCircularProgress
              size={50}
              width={Styles.Spacing.m2}
              fill={timerProgress}
              tintColor={Styles.primaryColor}
              backgroundColor={Styles.white}
              rotation={0}
              lineCap="round"
              duration={1500}
            >
              {() => <AVText>{timeRemaining}</AVText>}
            </AnimatedCircularProgress>
          </View>
          <Keypad
            allowMoreSelections={allowMoreSelections}
            keypadType={VendSession.Device.keypadType as KeypadTypes}
            onKeyClick={this.onKeyClick}
            onClearPressed={this.clearPressed}
            onEnterPressed={this.enterPressed}
          />
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.btnPressed}
          accessibilityLabel="Cancel"
          text={buttonLabel}
        />
        <Snackbar
          duration={5000}
          style={[styles.snackbar, snackbarError && styles.snackbarError]}
          onDismiss={this.onDismissSnackbar}
          visible={snackbarVisible}
        >
          {snackbarText}
        </Snackbar>
      </Header>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'column',
    padding: Styles.Spacing.m3,
  },
  instruction: {
    flex: 1,
    marginRight: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
  selectionText: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
  },
  topSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
  },
  snackbar: {
    backgroundColor: Styles.positiveColor,
  },
  snackbarError: {
    backgroundColor: Styles.warningColor,
  },
});
export default withForwardedNavigationParams()(
  withGlobalize(VendorsExchangeVendingScreen),
);
