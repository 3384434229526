import * as React from 'react';
import moment from 'moment';
import ActionsFactory from 'src/actions/ActionsFactory';
import Settings from 'src/Settings';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import type {ScreenProps} from 'src/types/Screen';
import type {SetupModel} from 'src/types/SetupModel';
import type {Referral} from 'src/types/Referral';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import PaymentCredentialsBuilder from 'src/models/PaymentCredentials';
import AppEventTypes from 'src/constants/AppEventTypes';
import Util from 'src/Util';
import uuid from 'src/nativeModules/UUID';
import Logger from 'src/logging/Logger';
type CreateAccountHandlerProps = {
  context: ScreenProps;
};

class CreateAccountHandler extends React.Component<CreateAccountHandlerProps> {
  startTime: moment.Moment;

  constructor(props: CreateAccountHandlerProps) {
    super(props);
    this.startTime = moment();
    this.displayAccountSetupError = this.displayAccountSetupError.bind(this);
  }

  async handleCreateAccount(
    account: SetupModel,
    email: string,
    pinRequired: boolean,
    referral: Referral | null | undefined,
  ) {
    try {
      const {context} = this.props;
      context.actions.showSpinner();
      const response = await ActionsFactory.getAccountActions().setupAccount(
        account,
        email,
        pinRequired,
      );
      Events.AccountCreation.trackEvent(this.startTime, '', 'EMAIL_VERIFIED');
      const accountId = account.accountId || response.accountId;
      this.createAccountEvent(accountId);

      if (referral?.isActive) {
        NavActions.push(AppRoutes.AccountReady, {
          referralId: referral?.referralId,
          username: email,
        });
      } else {
        // Determine if they can add a credit card
        const processor =
          await ActionsFactory.getAccountActions().getProcessorCreds(accountId);

        Logger.Log.LogAPIEvent(
          'AppAPI',
          'GetPaymentCredentials',
          JSON.stringify({accountId}),
          JSON.stringify(processor),
        );
        if (processor.type === Settings.processorsAsString.heartland) {
          NavActions.replace(AppRoutes.CreditCardAdd, {
            username: email,
            accountId,
            startTime: this.startTime,
            paymentCredentials: await PaymentCredentialsBuilder.build(
              processor,
            ),
          });
        } else {
          alertSuccess(Localized.Success.create_account_success, () =>
            NavActions.reset(AppRoutes.Welcome),
          );
        }
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CreateAccountHandler:HandleCreateAccount',
        error?.message ? error?.message : error.toString(),
      );
      this.displayAccountSetupError(error);
    } finally {
      this.props.context.actions.hideSpinner();
    }
  }
  async createAccountEvent(accountId: string) {
    try {
      await ActionsFactory.getAccountActions().updateAppEvent(
        accountId,
        AppEventTypes.AccountCreated,
        Util.getCurrentUTCDate(),
        Util.getCurrentDate(),
        moment.tz.guess(),
        Util.getAppOsName(),
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'AccountCreationScreen:UpdateAccountCreationEvent',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  displayAccountSetupError(error: string | any) {
    let message = Localized.Errors.problem_creating_account;

    if (
      (typeof error === 'string' &&
        error.includes('specified already exists')) ||
      (typeof error.message === 'string' &&
        error.message.includes('specified already exists'))
    ) {
      message += ` ${Localized.Errors.email_already_exists}`;
    } else if (typeof error === 'string' && error.includes('characters long')) {
      message += ` ${Localized.Errors.password_not_long_enough}`;
    } else {
      message += ` ${Localized.Errors.check_internet_connection}`;
    }

    if (!message.endsWith('.')) {
      message += '.';
    }

    alertError(message);
  }

  render() {
    return null;
  }
}

export default CreateAccountHandler;
