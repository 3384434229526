import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {loadStripe, StripeElements, Stripe} from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import PostalCodes from 'postal-codes-js';
import ScreenContext from '../../ScreenContext';
import type {CreditCardScreenProps} from './CreditCardScreen';
import {CCModel} from 'src/types/CCModel';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import BackSubheader from '../../elements/BackSubheader';
import AVText from '../../elements/AVText';
import AVTextInput from '../../elements/AVTextInput';
import AccountStore from 'src/stores/AccountStore';
import ActionsFactory from 'src/actions/ActionsFactory';
import Events from 'src/logging/Events';
import NavActions from 'src/actions/NavActions';
import uuid from 'src/nativeModules/UUID';
import Settings from 'src/Settings';
import CountryDropdown from '../../elements/CountryDropdown';
import {get2LetterCountryCode} from 'src/constants/Countries';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import Logger from 'src/logging/Logger';

export type CreditCardStripeScreenProps = CreditCardScreenProps & {
  paymentKey: string;
};

export const CreditCardStripeScreen = ({
  buttonText,
  paymentKey,
}: CreditCardStripeScreenProps) => {
  const stripePromise = loadStripe(paymentKey);
  return (
    <BackSubheader title={Localized.Labels.add_card}>
      {paymentKey && (
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({stripe, elements}) => (
              <StripeForm
                stripe={stripe}
                elements={elements}
                buttonText={buttonText}
              />
            )}
          </ElementsConsumer>
        </Elements>
      )}
    </BackSubheader>
  );
};

CreditCardStripeScreen.defaultProps = {
  buttonText: Localized.Buttons.save,
  cardAdded: null,
};

type StripeFormProps = {
  buttonText: string;
  elements: StripeElements | null;
  stripe: Stripe | null;
};

type StripeFormState = {
  country: string;
  countryMenuVisible: boolean;
  postalCode: string;
};

export class StripeForm extends React.Component<
  StripeFormProps,
  StripeFormState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: StripeFormProps) {
    super(props);
    this.state = {
      country: AccountStore.getRegion(),
      countryMenuVisible: false,
      postalCode: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePostalCode = this.onChangePostalCode.bind(this);
  }

  async handleSubmit() {
    this.context.actions.showSpinner();
    const {stripe, elements} = this.props;
    const {country, postalCode} = this.state;

    if (['GBR', 'CAN', 'USA'].includes(country)) {
      const validateResult: any = PostalCodes.validate(country, postalCode);

      if (validateResult.length) {
        this.context.actions.hideSpinner();
        alertError(validateResult);
        Events.Error.trackEvent(
          'Exception',
          'CreditCardStripeScreen:PostalCodes.validate',
          validateResult.toString(),
        );
        return;
      }
    }

    try {
      const response =
        await ActionsFactory.getAccountActions().createSetupIntent(
          AccountStore.getAccountId(),
        );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'CreateSetupIntent',
        JSON.stringify({accountId: AccountStore.getAccountId()}),
        JSON.stringify(response),
      );

      if (response && response?.clientSecret) {
        const clientSecret = response.clientSecret;
        const element = elements.getElement(CardNumberElement);
        const {token} = await stripe.createToken(element, {
          address_country: get2LetterCountryCode(country),
          address_zip: postalCode,
        });
        const {setupIntent} = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: {
              token: token.id,
            },
          },
        });
        const ccModel: CCModel = {
          CardNumberMask: `x${token.card.last4}`,
          Token: setupIntent.payment_method,
          Provider: Settings.processors.stripe,
          AccountId: AccountStore.getAccountId(),
          BillingZip: token.card.address_zip,
          CardNumber: '',
          CvvCode: '',
          ExpirationMonth: token.card.exp_month,
          ExpirationYear: token.card.exp_year,
          Issuer: token.card.brand,
        };
        await ActionsFactory.getAccountActions().addCreditCardTokenStripe(
          ccModel,
        );
        Events.AddCard.trackEvent('ADDED');
        alertSuccess(Localized.Success.credit_card_added);
        this.context.actions.hideSpinner();
        NavActions.pop();
      } else {
        alertError(Localized.Errors.error_adding_credit_card);
        Events.Error.trackEvent(
          'Exception',
          'CreditCardStripeScreen:AddedCardStripe',
          Localized.Errors.error_adding_credit_card,
        );
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'CreditCardsScreen:AddCardStripe',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.error_adding_credit_card, guid);
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'CreateSetupIntent-Error',
        JSON.stringify({accountID: AccountStore.getAccountId()}),
        JSON.stringify(error),
      );
    }

    this.context.actions.hideSpinner();
  }

  onChangeCountry(value: string) {
    this.setState({
      country: value,
      countryMenuVisible: false,
      postalCode: '',
    });
  }

  onChangePostalCode(value: string) {
    this.setState({
      postalCode: value.toUpperCase(),
    });
  }

  handleMenuVisibilityChange = (visible: boolean) => {
    this.setState({countryMenuVisible: visible});
  };

  render() {
    return (
      <>
        <ScrollView
          style={[styles.scrollView, Styles.Style.maxWidthContainer]}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.row}>
            <View style={styles.cell}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
                style={styles.text}
              >
                {Localized.Labels.card_number}
              </AVText>
              <CardNumberElement options={{style: stripeElementStyle}} />
              <View style={styles.underline} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <AVText
                style={styles.text}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              >
                {Localized.Labels.expiration}
              </AVText>
              <CardExpiryElement options={{style: stripeElementStyle}} />
              <View style={styles.underline} />
            </View>
            <View style={styles.cell}>
              <AVText
                style={styles.text}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              >
                {Localized.Labels.cvc}{' '}
              </AVText>
              <CardCvcElement options={{style: stripeElementStyle}} />
              <View style={styles.underline} />
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <AVText
                style={styles.text}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
              >
                {Localized.Labels.select_country}
              </AVText>
              <CountryDropdown
                onVisibilityChange={this.handleMenuVisibilityChange}
                onSelect={this.onChangeCountry}
                selectedValue={this.state.country}
                accessibilityValue={{text: this.state.country}}
              />
            </View>
            {this.renderPostalCode()}
          </View>
        </ScrollView>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.handleSubmit}
          accessibilityLabel="Save"
          text={this.props.buttonText}
        />
      </>
    );
  }

  renderPostalCode() {
    const {country} = this.state;

    if (['GBR', 'CAN', 'USA'].includes(country)) {
      let placeholder;
      let label;
      let maxLength;

      switch (country) {
        case 'GBR':
          placeholder = 'A9 9AA';
          label = Localized.Labels.postal_code;
          maxLength = 8;
          break;

        case 'CAN':
          placeholder = 'A1A 1A1';
          label = Localized.Labels.postal_code;
          maxLength = 7;
          break;

        case 'USA':
          placeholder = '12345';
          label = Localized.Labels.zip_code;
          maxLength = 5;
          break;
      }

      return (
        <View style={styles.cell}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
            style={styles.text}
          >
            {label}
          </AVText>
          <AVTextInput
            value={this.state.postalCode}
            onChangeText={this.onChangePostalCode}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        </View>
      );
    } else {
      return <View style={Styles.Style.flex} />;
    }
  }
}

const styles = StyleSheet.create({
  cell: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m2,
    paddingVertical: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m1,
  },
  scrollView: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  text: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    paddingBottom: Styles.Spacing.m2,
  },
  underline: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    height: '2px',
    marginTop: Styles.Spacing.m3,
    transform: [
      {
        scaleY: 0.5,
      },
    ],
  },
});
// https://stripe.com/docs/js/appendix/style
const stripeElementStyle = {
  base: {
    fontSize: '16px',
    fontFamily: '"Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  invalid: {
    color: Styles.dangerColor,
  },
};

const ConnectedCreditCardStripeScreen = connect((state: RootState) => ({
  paymentKey: state.account.paymentCredentials.key,
}))(CreditCardStripeScreen);
export default withForwardedNavigationParams<any>()(
  ConnectedCreditCardStripeScreen,
);
