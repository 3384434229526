import React from 'react';
import {ScrollView, StyleSheet, Keyboard} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import AVTextInput from '../../../elements/AVTextInput';
import AVText from '../../../elements/AVText';
import CartStore from 'src/stores/CartStore';
import KeyboardAvoidingView from '../../../elements/365KeyboardAvoidingView';
import Events from 'src/logging/Events';
import BunnApi from 'src/api/coffee/BunnApi';
import RoundedButton, {ButtonType} from '../../../elements/RoundedButton';
import Styles from '../../../Styles';
import ScreenContext from '../../../ScreenContext';
import BackSubheader from '../../../elements/BackSubheader';
import UIManager from '../../../elements/ui/UIManager';
import NavActions from 'src/actions/NavActions';
import CartService from 'src/services/CartService';
import LocationTypes from 'src/constants/LocationTypes';
import BunnStatus from 'src/constants/cart/BunnStatus';
import BunnMenuStore from 'src/stores/BunnMenuStore';
import Util from 'src/Util';
import Localized from 'src/constants/AppStrings';
import {alertSuccess, alertError} from '../../../helpers/AlertHelper';
import PurchaseTypes from 'src/constants/cart/PurchaseTypes';
import uuid from '../../../../nativeModules/UUID';
type BunnDispenseScreenProps = {
  locationName: string;
};
type BunnDispenseScreenState = {
  code: string;
};

class BunnDispenseScreen extends React.Component<
  BunnDispenseScreenProps,
  BunnDispenseScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  failureTimeout: any;
  statusInterval: any;
  checkedOut: boolean;
  invalidCodeDisplaying: boolean;

  constructor(props: BunnDispenseScreenProps) {
    super(props);
    this.checkedOut = false;
    this.invalidCodeDisplaying = false;
    this.handleDispense = this.handleDispense.bind(this);
    this.callDispenseEndpoint = this.callDispenseEndpoint.bind(this);
    this.displayStatus = this.displayStatus.bind(this);
    this.startFailureTimeout = this.startFailureTimeout.bind(this);
    this.startStatusUpdates = this.startStatusUpdates.bind(this);
    this.handleFailure = this.handleFailure.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleInvalidCode = this.handleInvalidCode.bind(this);
    this.clearTimers = this.clearTimers.bind(this);
    this.clearFailureTimeout = this.clearFailureTimeout.bind(this);
  }

  componentDidMount() {
    this.startFailureTimeout(30);
  }

  componentWillUnmount() {
    this.clearTimers();
    this.context.actions.hideSpinner();
  }

  async callDispenseEndpoint(code: string): Promise<boolean> {
    try {
      if (!Util.isOnlyNumbers(code)) {
        return false;
      }

      if (
        CartStore.CoffeeSelection.drinkId >= 0 &&
        CartStore.CoffeeSelection.sizeId >= 0
      ) {
        await BunnApi.dispense(
          CartStore.CoffeeSelection.uuid,
          CartStore.CoffeeSelection.bold,
          CartStore.CoffeeSelection.drinkId,
          CartStore.CoffeeSelection.item,
          CartStore.CoffeeSelection.leaveRoom,
          CartStore.CoffeeSelection.sizeId,
          code,
        );
        return true;
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'BunnDispenseScreen:CallDispenseEndpoint',
        error.message ? error.message : error.toString(),
        guid,
        {
          ...this.props,
          ...this.state,
          code,
        },
      );
    }

    return false;
  }

  startStatusUpdates() {
    this.statusInterval = setInterval(async () => {
      try {
        const response = await BunnApi.getStatus(
          CartStore.CoffeeSelection.uuid,
        );

        if (
          response.status === BunnStatus.DispenseFinished ||
          response.status === BunnStatus.Dispensing
        ) {
          Events.Bunn.trackEvent('SuccessStatus', {
            status: response.status,
          });
          this.handleSuccess();
        } else if (
          response.status === BunnStatus.Cancelled ||
          response.status === BunnStatus.SystemUnavailable ||
          response.status === BunnStatus.DispenseNotAvailable
        ) {
          Events.Bunn.trackEvent('ErrorStatus', {
            status: response.status,
          });
          this.handleFailure();
        } else if (response.status === BunnStatus.InvalidPinEntry) {
          this.handleInvalidCode();
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'BunnDispenseScreen:StartStatusUpdates',
          error.message ? error.message : error.toString(),
          guid,
          {
            ...this.props,
            ...this.state,
          },
        );
      }
    }, 1000);
  }

  handleSuccess() {
    this.clearTimers();

    if (!this.checkedOut) {
      this.checkedOut = true;
      alertSuccess(
        Localized.Success.collect_your_coffee,
        undefined,
        Localized.Success.now_brewing,
      );
      CartService.checkout(
        this.context,
        LocationTypes.sos,
        PurchaseTypes.TouchlessCoffee,
      );
    }
  }

  handleFailure() {
    this.clearTimers();
    alertError(
      Localized.Errors.your_item_could_not_dispense,
      null,
      undefined,
      Localized.Errors.were_sorry,
    );
    NavActions.popToTop();
  }

  startFailureTimeout(timeoutSeconds = 20) {
    try {
      this.clearFailureTimeout();
      this.failureTimeout = setTimeout(() => {
        Events.Bunn.trackEvent('ErrorStatus', {
          status: 'timeout',
        });
        this.handleFailure();
      }, timeoutSeconds * 1000);
      BunnApi.mobileMode(BunnMenuStore.MenuIdentifier);
      this.invalidCodeDisplaying = false;
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'BunnDispenseScreen:startFailureTimeout',
        error.message ? error.message : error.toString(),
        '',
        {
          ...this.props,
          ...this.state,
          timeoutSeconds,
        },
      );
    }
  }

  displayStatus() {
    Keyboard.dismiss();
    this.context.actions.showSpinner(Localized.Labels.preparing_your_drink);
    this.startStatusUpdates();
    this.startFailureTimeout();
  }

  clearTimers() {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
      this.statusInterval = null;
    }

    this.clearFailureTimeout();
  }

  clearFailureTimeout() {
    if (this.failureTimeout) {
      clearTimeout(this.failureTimeout);
      this.failureTimeout = null;
    }
  }

  handleInvalidCode() {
    this.clearTimers();
    this.context.actions.hideSpinner();

    if (!this.invalidCodeDisplaying) {
      this.invalidCodeDisplaying = true;
      alertError(Localized.Errors.invalid_code_entered);
      this.startFailureTimeout();
    }
  }

  async handleDispense() {
    const result = await this.callDispenseEndpoint(this.state.code);
    this.context.actions.hideSpinner();

    if (result) {
      this.displayStatus();
    } else {
      this.handleInvalidCode();
    }
  }

  render() {
    return (
      <BackSubheader
        title={this.props.locationName}
        rightView={UIManager.getBalanceContainer(false, Localized)}
      >
        <KeyboardAvoidingView insideTab style={styles.codeView}>
          <ScrollView contentContainerStyle={styles.codeInputContainer}>
            <AVText style={styles.instruction}>
              {Localized.Labels.please_place_cup_and_enter_code}
            </AVText>
            <AVTextInput
              autoFocus
              keyboardType={'numeric'}
              style={styles.codeInput}
              onChangeText={(value) =>
                this.setState({
                  code: value,
                })
              }
            />
          </ScrollView>
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.handleDispense}
            accessibilityLabel="Dispense"
            text={Localized.Labels.dispense}
          />
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  codeInput: {
    marginBottom: Styles.Spacing.m5,
  },
  codeInputContainer: {
    flex: 1,
    padding: Styles.Spacing.m3,
    justifyContent: 'center',
  },
  codeView: {
    flex: 1,
  },
  instruction: {
    fontSize: Styles.Fonts.f1,
  },
});
export default withForwardedNavigationParams<BunnDispenseScreenProps>()(
  BunnDispenseScreen,
);
