import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import Util, {getPreviousRouteName} from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import BaseScreen from '../BaseScreen';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import ActionsFactory from 'src/actions/ActionsFactory';
import {StyleSheet, View, Switch, ScrollView} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import {alertSuccess, alertError} from '../../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type ResetPinProps = {
  isConnected?: boolean;
  navigation?: NavigationProp<ResetPin>;
};

type ResetPinState = {
  pinRequired: boolean;
  newPin: string;
  confirmNewPin: string;
  previousRoute?: string | null;
};

class ResetPin extends React.Component<ResetPinProps, ResetPinState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ResetPinProps) {
    super(props);
    this.state = {
      newPin: '',
      confirmNewPin: '',
      pinRequired: AccountStore.getPinRequired(),
      previousRoute: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  handleClick() {
    this.handlePinChange();
  }

  async handlePinChange() {
    if (
      this.state.pinRequired !== AccountStore.getPinRequired() &&
      !this.state.pinRequired &&
      !this.state.newPin
    ) {
      try {
        await ActionsFactory.getAccountActions().pinChanged(
          AccountStore.getAccountId(),
          '',
          this.state.pinRequired,
        );

        FirebaseAnalytic.trackEvent('handlePinChange', 'ResetPinScreen', {
          ...this.props,
          ...this.state,
        });

        alertSuccess(Localized.Success.pin_no_longer_required);
        NavActions.pop();
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          error.message ? error.message : error.toString(),
        );
        alertError(Localized.Errors.problem_changing_pin);
      }
    } else if (!this.state.newPin || this.state.newPin.length === 0) {
      alertError(Localized.Errors.enter_new_pin);
    } else if (this.state.newPin !== this.state.confirmNewPin) {
      alertError(Localized.Errors.pins_do_not_match);
    } else if (this.state.newPin && this.state.newPin.length < 4) {
      alertError(Localized.Errors.pin_has_to_be_four_digits);
    } else {
      try {
        await ActionsFactory.getAccountActions().pinChanged(
          AccountStore.getAccountId(),
          this.state.newPin,
          this.state.pinRequired,
        );

        alertSuccess(Localized.Success.pin_changed);
        NavActions.pop();
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          error.message ? error.message : error.toString(),
        );
        alertError(Localized.Errors.problem_changing_pin);
      }
    }
  }

  render() {
    return (
      <BaseScreen
        title={Localized.Labels.reset_pin}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView
            style={Styles.Style.flex}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={[styles.content, Styles.Style.maxWidthContainer]}>
              <AllyTextInput
                accessible={true}
                accessibilityLabel={Localized.Labels.new_pin}
                accessibilityValue={{text: this.state.newPin}}
                label={Localized.Labels.new_pin}
                value={this.state.newPin}
                secureTextEntry={true}
                keyboardType="numeric"
                maxLength={4}
                onChangeText={(text: string) =>
                  this.setState({
                    newPin: Util.onlyNumbers(text),
                  })
                }
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />

              <AllyTextInput
                accessible={true}
                accessibilityLabel={Localized.Labels.confirm_new_pin}
                accessibilityValue={{text: this.state.confirmNewPin}}
                label={Localized.Labels.confirm_new_pin}
                value={this.state.confirmNewPin}
                secureTextEntry={true}
                keyboardType="numeric"
                maxLength={4}
                onChangeText={(text: string) =>
                  this.setState({
                    confirmNewPin: Util.onlyNumbers(text),
                  })
                }
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />

              <View style={styles.row}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  style={styles.label}
                >
                  {Localized.Labels.pin_required}
                </AVText>
                <Switch
                  accessibilityLabel="PIN Required Switch"
                  accessibilityHint={
                    'Double tap to set pin required to true or false'
                  }
                  accessibilityState={{checked: this.state.pinRequired}}
                  value={this.state.pinRequired}
                  onValueChange={(value) =>
                    this.setState({
                      pinRequired: value,
                    })
                  }
                  trackColor={{
                    false: Styles.lightGray,
                    true: Styles.primaryColor,
                  }}
                />
              </View>
              <View style={Styles.Style.bottomButtonContainer} />
            </View>
          </ScrollView>
          {this.props.isConnected && (
            <RoundedButton
              buttonType={ButtonType.action}
              accessible={true}
              accessibilityRole="button"
              accessibilityLabel={Localized.Buttons.save}
              aria-label={Localized.Buttons.save}
              role="button"
              onPress={this.handleClick}
              text={Localized.Buttons.save}
            />
          )}
        </KeyboardAvoidingView>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m5,
  },
  label: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: Styles.Spacing.m3,
  },
});
export default withForwardedNavigationParams()(withIsConnected(ResetPin));
