import React from 'react';
import {View, StyleSheet, Image, Platform} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Util from 'src/Util';
import AutoFundingControl from '../../elements/funding/ExpressFundingControlLight';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import AccountStore from 'src/stores/AccountStore';
import AccountConstants from 'src/constants/AccountConstants';
import Localized from 'src/constants/AppStrings';
import {CreditCard} from 'src/models/CreditCard';
import NavActions from '../../../actions/NavActions';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
type AccountSetupAutoFundScreenProps = WithGlobalizeProps & {
  creditCard: CreditCard;
  accountId: string;
  username: string;
  password: string;
  startTime: moment.Moment;
  isLinkingKioskAccount: boolean;
};
type AccountSetupAutoFundScreenState = {
  cards: Array<CreditCard>;
  fallBelowAmount: number;
  amount: number;
  autoState: string;
};

class AccountSetupAutoFundScreen extends React.Component<
  AccountSetupAutoFundScreenProps,
  AccountSetupAutoFundScreenState
> {
  _token: string;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: AccountSetupAutoFundScreenProps) {
    super(props);
    this.state = {
      fallBelowAmount:
        AccountStore.getAutoFundFallBelowAmount() ||
        AccountConstants.DEFAULT_FALL_BELOW_AMOUNT,
      cards: [props.creditCard],
      amount:
        AccountStore.getAutoFundAmount() || AccountStore.getDefaultAmount(),
      autoState: 'prompt',
    };
    this.formatNumber = this.formatNumber.bind(this);
    this.cardSelected = this.cardSelected.bind(this);
    this.amountSelected = this.amountSelected.bind(this);
    this.fallBelowSelected = this.fallBelowSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
    this.parseNumber = this.parseNumber.bind(this);
    this.handleAutoFundClick = this.handleAutoFundClick.bind(this);
  }

  formatNumber(value: number): string {
    return Util.formatNumber(this.props, value);
  }

  parseNumber(numberString: number | string): number {
    const parser = this.props.globalize.getNumberParser({});
    return typeof numberString === 'number'
      ? numberString
      : parser(numberString);
  }

  fallBelowSelected(val: number) {
    this.setState({
      fallBelowAmount: val,
    });
  }

  cardSelected(val: string) {
    this._token = val;
  }

  amountSelected(val: number) {
    this.setState({
      amount: val,
    });
  }

  async handleClick() {
    const amount = this.parseNumber(this.state.amount);
    const fallBelowAmount = this.parseNumber(this.state.fallBelowAmount);

    FirebaseAnalytic.trackEvent('handleClick', 'AccountSetupAutoFundScreen', {
      ...this.props,
      ...this.state,
      amount,
      fallBelowAmount,
    });
    if (!this._token || !amount || !fallBelowAmount) {
      alertError(Localized.Errors.all_fields_required);
    } else {
      this.context.actions.showSpinner();

      try {
        const response = await ActionsFactory.getAccountActions().setupAutoFund(
          this.props.accountId,
          AccountStore.getAccountBalanceId(),
          this._token,
          amount,
          fallBelowAmount,
        );
        FirebaseAnalytic.trackEvent(
          'handleClick setupAutoFund',
          'AccountSetupAutoFundScreen',
          {
            ...this.props,
            ...this.state,
            amount,
            fallBelowAmount,
            response,
          },
        );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetupAutoFund',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            accountBalanceId: AccountStore.getAccountBalanceId(),
            cardToken: this._token,
            amount,
            fallBelowAmount,
          }),
          JSON.stringify(response),
        );

        if (response) {
          Events.AccountCreation.trackEvent(
            this.props.startTime,
            '',
            'AUTO_FUND_SETUP',
          );
          alertSuccess(
            Localized.Success.auto_funding_successfully_set_login,
            () => {
              this.navigateToMain();
            },
          );
        } else {
          alertError(Localized.Errors.error_setting_auto_funding);
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'AccountSetupAutoFundScreen:HandleClick',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.error_setting_auto_funding, guid);
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetupAutoFund',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            fallBelowAmount,
            balanceId: AccountStore.getAccountBalanceId(),
            amount,
          }),
          JSON.stringify(error),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  handleSkipClick() {
    FirebaseAnalytic.trackEvent(
      'handleSkipClick',
      'AccountSetupAutoFundScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    alertSuccess(Localized.Success.create_account_success, () =>
      this.navigateToMain(),
    );
  }

  handleAutoFundClick() {
    FirebaseAnalytic.trackEvent(
      'handleAutoFundClick',
      'AccountSetupAutoFundScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    this.setState({
      autoState: 'autofund',
    });
  }

  navigateToMain() {
    FirebaseAnalytic.trackEvent(
      'navigateToMain',
      'AccountSetupAutoFundScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (Platform.OS === 'web') {
      this.context?.actions.changeRootRoute(AppRoutes.App);
      NavActions.replace(AppRoutes.MainConsumer);
    } else {
      if (this.props.isLinkingKioskAccount) {
        this.context.actions.changeRootRoute(AppRoutes.App);
      } else {
        this.context.actions.changeRootRoute(AppRoutes.AppProductTour);
      }
    }
  }

  render() {
    if (!this._token) {
      this._token = this.state.cards[0].id || '';
    }

    let autoState = <View />;

    switch (this.state.autoState) {
      case 'prompt':
        autoState = (
          <View style={styles.content}>
            <View style={styles.imgContent}>
              <Image
                style={styles.image}
                accessibilityLabel={'Icon'}
                nativeID="Icon"
                source={{
                  uri: 'https://img.365retailmarkets.com/autofunding-img.png',
                }}
                resizeMode="contain"
              />
            </View>
            <View style={styles.buttonRow}>
              <RoundedButton
                buttonType={ButtonType.outline}
                containerStyle={styles.button}
                accessibilityLabel="Setup Later"
                onPress={this.handleSkipClick}
                text={Localized.Labels.setup_later}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                containerStyle={styles.button}
                accessibilityLabel="Setup Auto Funding"
                onPress={this.handleAutoFundClick}
                text={Localized.Buttons.setup_auto_funding}
              />
            </View>
          </View>
        );
        break;

      case 'autofund':
        autoState = (
          <View style={styles.content}>
            <AutoFundingControl
              style={styles.autoFundControls}
              cards={this.state.cards}
              selectedMinAmount={this.state.fallBelowAmount}
              selectedAmount={this.state.amount}
              selectedCard={this._token}
              cardSelected={this.cardSelected}
              fallBelowSelected={this.fallBelowSelected}
              amountSelected={this.amountSelected}
              buttonText={Localized.Buttons.setup_auto_funding}
              buttonClick={this.handleClick}
              payrollAvailable={false}
            />
            <RoundedButton
              buttonType={ButtonType.outline}
              accessibilityLabel="Setup Later"
              containerStyle={styles.button}
              onPress={this.handleSkipClick}
              text={Localized.Labels.setup_later}
            />
            <RoundedButton
              containerStyle={styles.button}
              buttonType={ButtonType.action}
              onPress={this.handleClick}
              accessibilityLabel="Setup AutoFunding"
              text={Localized.Buttons.setup_auto_funding}
            />
          </View>
        );
    }

    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.auto_funding}
        instructionText={Localized.Labels.automatically_fund_your_account}
      >
        {autoState}
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  autoFundControls: {
    alignSelf: 'stretch',
    paddingBottom: Styles.Heights.h2,
  },
  buttonRow: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  button: {
    marginHorizontal: Styles.Spacing.m1,
    marginVertical: Styles.Spacing.m1,
  },
  content: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
    paddingHorizontal: Styles.Spacing.m2,
  },
  image: {
    alignSelf: 'stretch',
    flex: 1,
  },
  imgContent: {
    alignSelf: 'stretch',
    flex: 1,
    padding: 40,
  },
  negativeBtn: {
    marginVertical: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<AccountSetupAutoFundScreenProps>()(
  withGlobalize(AccountSetupAutoFundScreen),
);
