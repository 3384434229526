import React from 'react';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import NavActions from 'src/actions/NavActions';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import {KeyboardTypeOptions, ScrollView, StyleSheet} from 'react-native';
import BackSubheader from '../../elements/BackSubheader';
import Localized from 'src/constants/AppStrings';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import {InputTypes, Keyboards} from 'src/constants/KeyboardTypes';
import ScreenContext from '../../ScreenContext';
import {isValidEmail, isValidPhoneNumber} from 'src/services/ValidatorService';
import Settings from 'src/Settings';
import HttpClient from 'src/api/HttpClient';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import Events from 'src/logging/Events';
import TransactionActions from 'src/actions/TransactionActions';
import AccountStore from 'src/stores/AccountStore';
import {alertSuccess, alertError} from '../../helpers/AlertHelper';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

type ShareReceiptScreenProps = WithGlobalizeProps & {
  inputType: keyof typeof InputTypes;
  transactionId: string;
  transactionDate: string;
};
type ShareReceiptScreenState = {
  value: string;
  error: string;
};

class ShareReceiptScreen extends React.Component<
  ShareReceiptScreenProps,
  ShareReceiptScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: '',
    };
    this.onSend = this.onSend.bind(this);
  }

  async onSend() {
    FirebaseAnalytic.trackEvent('onSend', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });

    const {value} = this.state;
    const {inputType, transactionId, transactionDate} = this.props;

    if (inputType === InputTypes.email && !isValidEmail(value)) {
      this.setState({
        error: Localized.Errors.please_enter_the_valid_email,
      });
      return;
    }

    if (inputType === InputTypes.text && !isValidPhoneNumber(value)) {
      this.setState({
        error: Localized.Errors.please_enter_valid_phone_number,
      });
      return;
    }

    this.context.actions.showSpinner();
    this.setState({
      error: '',
    });

    FirebaseAnalytic.trackEvent('onSend', 'ShareReceiptScreen.tsx', {
      ...this.props,
      ...this.state,
      transactionId,
      buildType: Settings.buildType,
      inputType,
      value,
      transactionDate,
    });

    try {
      const response = await TransactionActions.shareReceipt(
        AccountStore.getAccountId(),
        transactionId,
        Settings.buildType,
        inputType,
        value,
        transactionDate,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'ShareReceipt',
        JSON.stringify({
          transactionId,
          inputType,
          accountId: AccountStore.getAccountId(),
          value,
          transactionDate,
        }),
        JSON.stringify(response),
      );

      FirebaseAnalytic.trackEvent(
        'onSend shareReceipt',
        'ShareReceiptScreen.tsx',
        {
          ...this.props,
          ...this.state,
          transactionId,
          buildType: Settings.buildType,
          inputType,
          value,
          transactionDate,
          response,
        },
      );

      alertSuccess(Localized.Success.sent_successfully, () => {
        NavActions.pop();
      });
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());

      if (error?.code === PlatformApiErrors.SendingEmail) {
        alertError(Localized.Labels.error_sending_the_email);
      } else if (error?.code === PlatformApiErrors.SendingSMS) {
        alertError(Localized.Labels.error_sending_the_sms);
      } else {
        alertError(Localized.Errors.error_sharing_receipt);
      }

      Events.Error.trackEvent('Exception', 'ShareReceiptScreen:onSend', error);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'ShareReceipt-Error',
        JSON.stringify({
          transactionDate,
          transId: transactionId,
          accountId: AccountStore.getAccountId(),
          inputType,
          value,
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    const {value, error} = this.state;
    const {inputType} = this.props;
    const keyboard = Keyboards[inputType];
    const label =
      inputType === InputTypes.email
        ? Localized.Labels.email_receipt_to
        : Localized.Labels.text_receipt_to;
    return (
      <BackSubheader title={Localized.Labels.receipt}>
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView contentContainerStyle={styles.content}>
            <AllyTextInput
              label={label}
              value={value}
              accessible={true}
              testID="emailOrText"
              accessibilityLabel={label}
              aria-label={label}
              accessibilityValue={{text: value}}
              onChangeText={(text) => this.setState({value: text})}
              autoCapitalize="none"
              keyboardType={keyboard.type as KeyboardTypeOptions}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            />
            <AVText style={styles.errorLabel}>{error}</AVText>
          </ScrollView>
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.onSend}
            accessible={true}
            accessibilityRole="button"
            accessibilityLabel="Send Receipt"
            role="button"
            aria-label="Send receipt"
            text={Localized.Buttons.send}
          />
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: Styles.Spacing.m3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: Styles.Spacing.m5,
  },
  errorLabel: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
    marginBottom: Styles.Spacing.m6,
  },
});
export default withForwardedNavigationParams<ShareReceiptScreenProps>()(
  withGlobalize(ShareReceiptScreen),
);
