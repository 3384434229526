import React, {useContext, useState} from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BackSubheader from '../../elements/BackSubheader';
import ScanditView from '../../elements/ScanditView';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import type {ScreenProps} from 'src/types/Screen';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

function EditMarketCard() {
  const {actions}: ScreenProps = useContext(ScreenContext) as any;
  const currentMarketCard = AccountStore.getMarketCard();
  const [scanning, setScanning] = useState(true);
  const [card, setCard] = useState(currentMarketCard);

  async function onSaveCard(code: string) {
    setScanning(false);
    actions.showSpinner();

    try {
      const response =
        await ActionsFactory.getAccountActions().addOrUpdateMarketCard(code);
      FirebaseAnalytic.trackEvent('onSaveCard', 'EditMarketCard', {
        card,
        response,
      });
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddOrUpdateMarketCard',
        JSON.stringify({}),
        JSON.stringify(response),
      );
      if (response.status === 'ok') {
        const successText = currentMarketCard
          ? Localized.Success.market_card_changed
          : Localized.Success.market_card_added;
        alertSuccess(successText);
        actions.hideSpinner();
        NavActions.pop();
      } else {
        throw new Error('unable to update');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(
        Localized.Errors.please_scan_valid_market_card,
        guid,
        undefined,
        Localized.Errors.invalid_market_card,
      );
      actions.hideSpinner();
      setScanning(true);
      Events.Error.trackEvent(
        'Exception',
        'EditMarketCardScreen:onSaveCard',
        error.message ? error.message : error.toString(),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddOrUpdateMarketCard',
        JSON.stringify({}),
        JSON.stringify(error),
      );
    }
  }

  return (
    <BackSubheader
      title={
        currentMarketCard
          ? Localized.Labels.edit_market_card
          : Localized.Labels.add_market_card
      }
    >
      {Platform.select({
        web: (
          <>
            <View style={Styles.Style.maxWidthContainer}>
              <AllyTextInput
                label={Localized.Labels.market_card}
                value={card}
                accessible={true}
                accessibilityLabel={Localized.Labels.market_card}
                accessibilityValue={{text: card}}
                onChangeText={(text) => setCard(text)}
                autoCapitalize="none"
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              />
            </View>
            <RoundedButton
              buttonType={ButtonType.action}
              accessibilityLabel="Save"
              onPress={() => onSaveCard(card)}
              text={Localized.Buttons.save}
            />
          </>
        ),
        default: (
          <>
            <AVText
              style={styles.header}
              accessibilityRole="header"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessibilityLabel={Localized.Labels.scan_your_market_card}
            >
              {Localized.Labels.scan_your_market_card}
            </AVText>
            <ScanditView
              style={styles.scanditContainer}
              barcodeScanned={onSaveCard}
              scanning={scanning}
            />
          </>
        ),
      })}
    </BackSubheader>
  );
}

const styles = StyleSheet.create({
  header: {
    color: Styles.black,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    paddingStart: Styles.Spacing.m3,
  },
  marketCard: {
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m2,
  },
  scanditContainer: {
    alignSelf: 'stretch',
    borderRadius: Styles.Spacing.m2,
    flex: 1,
    marginBottom: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
  },
});
export default withForwardedNavigationParams()(EditMarketCard);
