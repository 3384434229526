import * as React from 'react';
import {StyleSheet, View, Switch, ScrollView} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import moment from 'moment';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import PaperBill from '../../img/svg/cards/paperBill';
import Localized from 'src/constants/AppStrings';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import AppRoutes from 'src/AppRoutes';
import Util from 'src/Util';
import {alertError} from '../../helpers/AlertHelper';
import ProgressBar from '../../elements/ProgressBar';
import MainConsumerContext from '../../MainConsumerContext';
import {AppDispatch, RootState} from 'src/redux/store';
import {connect} from 'react-redux';
import {payrollDeductOptInChanged} from 'src/redux/slices/accountSlice';
import {PayrollDeductInformation} from 'src/models/PayrollDeductInformation';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type PayrollDeductScreenState = {
  showSave: boolean;
  optIn: boolean;
};

type PayrollDeductScreenProps = WithGlobalizeProps & {
  dispatch: AppDispatch;
  payrollId: string;
  optIn: boolean;
  payrollDeductInformation: PayrollDeductInformation;
};

class PayrollDeductScreen extends React.Component<
  PayrollDeductScreenProps,
  PayrollDeductScreenState
> {
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: PayrollDeductScreenProps) {
    super(props);
    this.state = {
      optIn: props.optIn,
      showSave: false,
    };
    this.onBackSelect = this.onBackSelect.bind(this);
    this.renderPending = this.renderPending.bind(this);
    this.handleAgreementPressed = this.handleAgreementPressed.bind(this);
    this.optInChanged = this.optInChanged.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  onBackSelect() {
    Events.PayrollDeduct.trackEvent('CANCELED');
  }

  optInChanged(value: boolean) {
    this.setState({
      showSave: value !== this.props.optIn,
      optIn: value,
    });
  }

  async saveChanges() {
    this.context.actions.showSpinner();

    try {
      await this.props.dispatch(payrollDeductOptInChanged(this.state.optIn));
      FirebaseAnalytic.trackEvent(
        'payrollDeductOptInChanged',
        'PayrollDeductScreen: OptedIn',
        {
          ...this.props,
          ...this.state,
        },
      );
      NavActions.pop();
    } catch (e) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'PayrollDeductScreen:SaveChanges',
        '',
        guid,
      );
      alertError(Localized.Errors.error_saving_your_selection, guid);
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  handleAgreementPressed() {
    FirebaseAnalytic.trackEvent(
      'handleAgreementPressed',
      'PayrollDeductScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    NavActions.push(AppRoutes.PayrollDeductAgreement);
  }

  renderPayrollAgreement(marginTop: number) {
    return (
      <AVTouchableOpacity
        onPress={this.handleAgreementPressed}
        testID="payRollAgreement"
      >
        <AVText
          style={[
            styles.agreementText,
            {
              marginTop,
            },
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.payroll_deduct_authorization_agreement}
        </AVText>
      </AVTouchableOpacity>
    );
  }

  renderPending(): React.ReactNode {
    return (
      <BackSubheader title={Localized.Labels.payroll_deduct}>
        <ScrollView>
          <View style={[styles.content, Styles.Style.maxWidthContainer]}>
            <View style={styles.iconContainer}>
              <PaperBill size={Styles.Fonts.f5} />
            </View>
            <AVText style={styles.pendingText}>
              {Localized.Labels.enrollment_pending}
            </AVText>
            <AVText style={styles.notifyText}>
              {Localized.Labels.notify_your_office_admin}
            </AVText>
            {this.renderPayrollAgreement(Styles.Spacing.m4)}
          </View>
        </ScrollView>
      </BackSubheader>
    );
  }

  renderRemainingSection() {
    const availableBalance =
      this.props.payrollDeductInformation.availableBalance ?? 0;
    const spendLimit = this.props.payrollDeductInformation?.spendLimit ?? 0;
    const progress = this.props.payrollDeductInformation
      ? (spendLimit - availableBalance) / spendLimit
      : 0;
    const showRemaining = spendLimit < 999;

    if (showRemaining) {
      return (
        <>
          <AVText
            style={styles.spendLimit}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {Localized.Labels.spend_limit}
          </AVText>
          <View style={styles.progressContainer}>
            <ProgressBar progress={progress} />
          </View>
          <AVText
            style={styles.remainingText}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {`${Localized.Labels.remaining} ${Localized.Labels.formatString(
              Localized.Labels.x_of_y,
              Util.formatCurrency(
                this.props,
                availableBalance,
                AccountStore.getCurrency(),
              ),
              Util.formatCurrency(
                this.props,
                spendLimit,
                AccountStore.getCurrency(),
              ),
            )}`}
          </AVText>
        </>
      );
    } else {
      return null;
    }
  }

  renderPayCycleSection() {
    const spendLimit = this.props.payrollDeductInformation?.spendLimit ?? 0;
    const showRemaining = spendLimit < 999;
    const resetLabel = (
      <AVText
        style={styles.resetLabel}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      >
        {Localized.Labels.formatString(
          Localized.Labels.resets_on,
          moment(this.props.payrollDeductInformation?.resetDate).format('L'),
        )}
      </AVText>
    );
    const payrollRow = (
      <View style={[styles.payCycleRow, !showRemaining && styles.noMargin]}>
        <AVText
          style={styles.payCycle}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.pay_cycle}
        </AVText>
        <AVText
          style={[styles.payCycle, styles.payCycleValue]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {this.props.payrollDeductInformation?.payCycle}
        </AVText>
      </View>
    );

    if (showRemaining) {
      return (
        <>
          {resetLabel}
          {payrollRow}
        </>
      );
    } else {
      return (
        <>
          {payrollRow}
          {resetLabel}
        </>
      );
    }
  }

  renderNormalContent(): React.ReactNode {
    return (
      <ScrollView>
        <View
          style={[
            styles.content,
            styles.normalContent,
            Styles.Style.maxWidthContainer,
          ]}
        >
          {this.renderRemainingSection()}
          {this.renderPayCycleSection()}
          <View style={styles.bottomContainer}>
            <View style={[styles.optInRow]}>
              <AVText
                style={styles.switchText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {Localized.Labels.opt_in}
              </AVText>
              <Switch
                accessibilityLabel="Opt In To Payroll Deduct"
                nativeID="Opt In To Payroll Deduct"
                value={this.state.optIn}
                onValueChange={this.optInChanged}
                trackColor={{
                  false: Styles.lightGray,
                  true: Styles.primaryColor,
                }}
              />
            </View>
            <AVText
              style={styles.infoText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Labels.opting_out_of_payroll_deduct}
            </AVText>
            {this.renderPayrollAgreement(0)}
          </View>
        </View>
      </ScrollView>
    );
  }

  renderNormal(): React.ReactNode {
    let content = null;

    if (this.props.payrollDeductInformation?.payCycle) {
      content = this.renderNormalContent();
    }

    return (
      <BackSubheader title={Localized.Labels.payroll_deduct}>
        {content}
        {this.state.showSave && (
          <RoundedButton
            buttonType={ButtonType.action}
            accessibilityLabel="Save Payroll Deduct"
            onPress={this.saveChanges}
            text={Localized.Buttons.save}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            testID="savePayrollDeduct"
          />
        )}
      </BackSubheader>
    );
  }

  render() {
    if (this.props.payrollId) {
      return this.renderNormal();
    } else {
      return this.renderPending();
    }
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  normalContent: {
    paddingBottom: Styles.Spacing.m5,
  },
  label: {
    marginVertical: Styles.Spacing.m3,
  },
  iconContainer: {
    marginVertical: Styles.Spacing.m4,
    height: Styles.Fonts.f5,
    alignItems: 'center',
  },
  pendingText: {
    fontSize: Styles.Fonts.f2,
    textAlign: 'center',
  },
  notifyText: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m1,
  },
  agreementText: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    color: Styles.primaryColor,
    textDecorationLine: 'underline',
  },
  remainingBalance: {
    fontSize: Styles.Fonts.f2,
    color: Styles.primaryColor,
    textAlign: 'center',
  },
  payCycle: {
    fontSize: Styles.Fonts.f1,
  },
  payCycleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m4,
  },
  payCycleValue: {
    textTransform: 'capitalize',
  },
  resetLabel: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
  },
  optInRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    textAlign: 'center',
    marginVertical: Styles.Spacing.m3,
  },
  bottomContainer: {
    marginTop: Styles.Spacing.m4,
  },
  switchText: {
    fontSize: Styles.Fonts.f1,
  },
  progressContainer: {
    marginVertical: Styles.Spacing.m2,
    alignSelf: 'stretch',
  },
  remainingText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  spendLimit: {
    fontSize: Styles.Fonts.f2,
  },
  noMargin: {
    marginTop: 0,
  },
});
const ConnectedPayrollDeductScreen = connect(
  (state: RootState) => ({
    payrollId: state.account.account.payrollIdentifier?.value,
    optIn: state.account.account.isPayrollDeductOptIn,
    payrollDeductInformation: state.account.payrollDeductInformation,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(PayrollDeductScreen);
export default withForwardedNavigationParams()(
  withIsConnected(withGlobalize(ConnectedPayrollDeductScreen)),
);
