import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withScrollToElement from '../../hoc/withScrollToElement';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import {ScrollToElementProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import {StyleSheet, View, Switch, ScrollView} from 'react-native';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../../helpers/AlertHelper';
import PaymentCredentialsBuilder from 'src/models/PaymentCredentials';
import AppEventTypes from 'src/constants/AppEventTypes';
import Util from 'src/Util';
import moment from 'moment';
import PasswordInput from '../../PasswordInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

type KioskCreatePasswordProps = ScrollToElementProps & {
  data: {
    isPasswordSet: boolean;
    accountId: string;
    email: string;
  };
};

type KioskCreatePasswordState = {
  password: string;
  confirmPassword: string;
  acceptUserAgreement: boolean;
};

class KioskCreatePassword extends React.Component<
  KioskCreatePasswordProps,
  KioskCreatePasswordState
> {
  scrollView: ScrollView | null;
  password: AVTextInput | null;
  confirmPassword: AVTextInput | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: KioskCreatePasswordProps) {
    super(props);
    this.state = {
      acceptUserAgreement: false,
      password: '',
      confirmPassword: '',
    };
    this.userAgreementPressed = this.userAgreementPressed.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.inputFocused = this.inputFocused.bind(this);
  }

  componentDidMount() {
    if (this.props.data.isPasswordSet) {
      confirm(
        Localized.Labels.kiosk_account_already_has_password,
        () => {
          NavActions.reset(AppRoutes.Welcome);
        },
        () => {
          NavActions.pop();
        },
      );
    }
  }

  userAgreementPressed() {
    const url = Settings.getTermsUrl(
      Settings.getLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    const backupUrl = Settings.getTermsUrl(
      Settings.getDefaultLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    NavActions.push(AppRoutes.WebContent, {
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
    });
  }

  async handleClick() {
    this.context.actions.showSpinner();
    const errorMessage = this.validate();

    FirebaseAnalytic.trackEvent('handleClick', 'KioskCreatePasswordScreen', {
      ...this.props,
      ...this.state,
      errorMessage,
    });

    if (errorMessage) {
      alertError(errorMessage);
      this.context.actions.hideSpinner();
    } else {
      try {
        const passwordResponse =
          await ActionsFactory.getAccountActions().setPassword(
            this.props.data.accountId,
            this.state.password,
            true,
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetPassword',
          JSON.stringify({accountId: this.props.data.accountId}),
          JSON.stringify(passwordResponse),
        );
        if (!passwordResponse) {
          // Determine if they can add a credit card
          const processor =
            await ActionsFactory.getAccountActions().getProcessorCreds(
              this.props.data.accountId,
            );
          Logger.Log.LogAPIEvent(
            'AppAPI',
            'GetPaymentCredentials',
            JSON.stringify({accountId: this.props.data.accountId}),
            JSON.stringify(processor),
          );
          this.associateAccountEvent(this.props.data.accountId);

          FirebaseAnalytic.trackEvent(
            'handleClick ActionsFactory.getAccountActions().setPassword',
            'KioskCreatePasswordScreen',
            {
              ...this.props,
              ...this.state,
              passwordResponse,
            },
          );

          if (processor.type === Settings.processorsAsString.heartland) {
            NavActions.push(AppRoutes.CreditCardAdd, {
              username: this.props.data.email,
              password: this.state.password,
              accountId: this.props.data.accountId,
              isLinkingKioskAccount: true,
              paymentCredentials: await PaymentCredentialsBuilder.build(
                processor,
              ),
            });
          } else {
            NavActions.reset(AppRoutes.Welcome);
          }
        } else {
          const error: any = new Error(Localized.Errors.unable_to_set_password);
          error.showMessage = true;
          throw error;
        }
      } catch (e) {
        if (!e.showMessage) {
          const guid = await uuid.getRandomUUID();
          Events.Error.trackEvent(
            'Exception',
            'KioskCreatePasswordScreen:HandleClick',
            e.message ? e.message : e.toString(),
            guid,
          );
          alertError(Localized.Errors.error, guid);
        } else {
          alertError(e.message);
        }
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'SetPassword',
          JSON.stringify({accountId: this.props.data.accountId}),
          JSON.stringify(e),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  async associateAccountEvent(accountId: string) {
    try {
      await ActionsFactory.getAccountActions().updateAppEvent(
        accountId,
        AppEventTypes.AccountAssociated,
        Util.getCurrentUTCDate(),
        Util.getCurrentDate(),
        moment.tz.guess(),
        Util.getAppOsName(),
      );
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'KioskCreatePasswordScreen:AssociateAccountEvent',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  validate() {
    if (!this.state.acceptUserAgreement) {
      return Localized.Errors.must_accept_user_agreement;
    }

    if (this.state.password !== this.state.confirmPassword) {
      return Localized.Errors.passwords_must_match;
    }

    if (!this.state.password) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  inputFocused(ref: AVTextInput | null) {
    this.props.scrollToElement(this.scrollView, ref);
  }

  render() {
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.create_password}
        instructionText={Localized.Labels.enter_password_below}
      >
        <ScrollView
          style={styles.scrollView}
          ref={(scrollView) => {
            this.scrollView = scrollView;
          }}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <AVText style={styles.email}>{this.props.data.email}</AVText>
          <View style={styles.inputsContainer}>
            <PasswordInput
              label={Localized.Labels.enter_password}
              onChangeText={(text) =>
                this.setState({
                  password: text,
                })
              }
              accessible={true}
              accessibilityLabel={Localized.Labels.enter_password}
              aria-label={Localized.Labels.enter_password}
              value={this.state.password}
            />
            <PasswordInput
              label={Localized.Labels.confirm_your_password}
              onChangeText={(text) =>
                this.setState({
                  confirmPassword: text,
                })
              }
              onSubmitEditing={this.handleClick}
              accessible={true}
              accessibilityLabel={Localized.Labels.confirm_password}
              aria-label={Localized.Labels.confirm_password}
              value={this.state.confirmPassword}
              needValidation
            />
            <View style={styles.rowOption}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                accessibilityLabel={`${Localized.Labels.accept_the} ${Localized.Labels.user_agreement}`}
                aria-label={`${Localized.Labels.accept_the} ${Localized.Labels.user_agreement}`}
                style={styles.switchText}
              >
                {Localized.Labels.accept_the}
                <AVText
                  accessible={true}
                  accessibilityLabel="View user agreement"
                  aria-label="View user agreement"
                  accessibilityRole="link"
                  role="link"
                  onPress={this.userAgreementPressed}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                  style={styles.underline}
                >
                  {' '}
                  {Localized.Labels.user_agreement}
                </AVText>
              </AVText>
              <Switch
                accessible={true}
                accessibilityLabel="Accept user agreement"
                accessibilityRole="switch"
                accessibilityState={{checked: this.state.acceptUserAgreement}}
                aria-checked={this.state.acceptUserAgreement}
                role="switch"
                aria-label="Accept user agreement"
                value={this.state.acceptUserAgreement}
                onValueChange={(value) =>
                  this.setState({
                    acceptUserAgreement: value,
                  })
                }
                trackColor={{
                  false: Styles.lightGray,
                  true: Styles.primaryColor,
                }}
              />
            </View>
          </View>
        </ScrollView>
        <RoundedButton
          accessible={true}
          accessibilityRole="button"
          accessibilityLabel={Localized.Buttons.next}
          role="button"
          aria-label={Localized.Buttons.next}
          buttonType={ButtonType.action}
          onPress={this.handleClick}
          text={Localized.Buttons.next}
          textStyle={styles.nextButtonText}
        />
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  email: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f2,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m5,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  rowOption: {
    alignItems: 'center',
    flexDirection: 'row',
    height: Styles.Heights.h4,
    justifyContent: 'space-between',
  },
  scrollView: {
    alignSelf: 'stretch',
    paddingBottom: Styles.Spacing.m2,
  },
  switchText: {
    alignItems: 'center',
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(KioskCreatePassword),
);
