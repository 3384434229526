import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import CreatePasswordScanScreen from '../../elements/createAccount/CreatePasswordScan';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import ActionsFactory from 'src/actions/ActionsFactory';
import {linkPayroll} from '../../helpers/LinkPayrollHelper';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';

type ScanKioskQRProps = {
  importId?: string;
  importLocationId?: string;
  navigation?: NavigationProp<ScanKioskQR>;
};
type ScanKioskQRState = {
  scanning: boolean;
  previousRoute: string | null;
};

class ScanKioskQR extends React.Component<ScanKioskQRProps, ScanKioskQRState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ScanKioskQRProps) {
    super(props);
    this.state = {
      scanning: true,
      previousRoute: null,
    };
    this.qrCodeScanned = this.qrCodeScanned.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState()?.routes,
    );

    this.setState({previousRoute: previousRoute});
  }

  async qrCodeScanned(qrCode: string) {
    this.setState({
      scanning: false,
    });

    try {
      const response = await ActionsFactory.getAccountActions().loadAccountByQR(
        qrCode,
      );

      if (response.status === 'ok') {
        if (this.props.importId) {
          //Payroll Link API call
          const linkResult = await linkPayroll(
            this.props.importId,
            this.props.importLocationId,
            response.data.id,
            response.data.location,
          );

          if (!linkResult) {
            this.setState({
              scanning: true,
            });
            return;
          }
        }
        FirebaseAnalytic.trackEvent(
          'qrCodeScanned scanQR',
          'ScanKioskQRScreen',
          {
            ...this.props,
            ...this.state,
          },
        );
        if (response.data.email || this.props.importId) {
          NavActions.replace(AppRoutes.KioskCreatePassword, {
            data: {...response.data, accountId: response.id},
          });
        } else {
          NavActions.replace(AppRoutes.KioskChangeEmail, {
            data: {...response.data, accountId: response.id},
          });
        }
      } else {
        alertError(Localized.Errors.qr_code_not_recognized);
        this.setState({
          scanning: true,
        });
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();

      alertError(Localized.Errors.qr_code_not_recognized, guid);
      this.setState({
        scanning: true,
      });
      Events.Error.trackEvent(
        'Exception',
        'ScanKioskQRScreen:QrCodeScanned',
        error.message ? error.message : error.toString(),
        guid,
      );
    }
  }

  render() {
    return (
      <CreatePasswordScanScreen
        label={Localized.Labels.open_your_account_kiosk_scan_QR_code_shown}
        barcodeScanned={this.qrCodeScanned}
        scanning={this.state.scanning}
        previousRoute={this.state.previousRoute}
        strings={Localized}
      />
    );
  }
}

export default withForwardedNavigationParams<ScanKioskQRProps>()(ScanKioskQR);
