import Api, {HttpVerbs} from './Api';
import {UrlTypes} from 'src/types/UrlTypes';
import {Location} from 'src/types/serverTypes/Location';

export enum PlatformApiErrors {
  AccountAlreadyExists = 100,
  CampaignAlreadyExpired = 101,
  ReferralAlreadyRedeemed = 102,
  SendingSMS = 103,
  BuildReferralLink = 104,
  SendingEmail = 107,
  SnackAccepted = 108,
  SnackCancelled = 109,
  SnackExpired = 110,
  SnackUserBlocked = 111,
}
export class PlatformApi {
  loadSaleDetail(
    accountId: string,
    transactionId: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl(`/sales/${transactionId}`, {
      marketAccount: accountId,
      transactionDate,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  loadFundingDetail(
    accountId: string,
    transactionId: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl(`/fundings/${transactionId}`, {
      marketAccount: accountId,
      transactionDate,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  loadRefundDetail(
    accountId: string,
    transactionId: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl(`/refunds/${transactionId}`, {
      marketAccount: accountId,
      transactionDate,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  async fetchCampaignDetails(locationId: string, active: boolean) {
    const url = this.getFullUrl('/campaigns', {
      locationId,
      active,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchLocation(locationId: string): Promise<Location> {
    const url = this.getFullUrl(`/locations/${locationId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchOrg(orgId: string) {
    const url = this.getFullUrl(`/orgs/${orgId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchUserReferralStat(accountId: string) {
    const url = this.getFullUrl('/referrals/stats', {
      accountId,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  sendReferralInvite(
    campaignId: string,
    accountId: string,
    method: string,
    contact: string,
    appName: string,
  ) {
    const url = this.getFullUrl('/referrals');
    const body = {
      accountId: accountId,
      method: method,
      contact: contact,
      appName: appName,
      campaignId: campaignId,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  fetchReferralDetails(referralId: string) {
    const url = this.getLegacyUrl(`/referrals/${referralId}`);
    return Api.fetch(url, null, HttpVerbs.Get, false);
  }

  fetchReferrals(accountId: string) {
    const url = this.getFullUrl('/referrals', {
      accountId,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchReferralSummary(referralId: string) {
    const url = this.getFullUrl(`/referrals/${referralId}/summary`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  redeemReferral(
    accountId: string,
    referralId: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl('/actions/referrals/redeem');
    const body = {
      accountId,
      referralId,
      transactionDate,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  fetchRecentOrders(accountId: string, numHours = 2) {
    const url = this.getFullUrl('/sales', {
      accountId,
      numHours,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchSalesOrders(accountId: string, locationId: string, numDays = 30) {
    const url = this.getFullUrl('/sales/orders', {
      accountId,
      locationId,
      numDays,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  shareReceipt(
    accountId: string,
    transactionId: string,
    appName: string,
    method: string,
    contact: string,
    transactionDate: string,
  ) {
    const url = this.getFullUrl('/actions/receipt/share');
    const body = {
      accountId,
      transactionId,
      appName,
      method,
      contact,
      transactionDate,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  fetchSnacks(
    locationId: string,
    offset: number,
    limit: number,
    accountId?: string,
  ) {
    const url = this.getFullUrl(
      `/sendaSnack/${locationId}/snacks`,
      accountId
        ? {
            offset,
            limit,
            accountId,
          }
        : {
            offset,
            limit,
          },
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  getSnackBlockedUsers(accountId: string) {
    const url = this.getFullUrl(`/sendaSnack/blocks/${accountId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  unblockSnackUser(accountId: string, blockedAccountId: string) {
    const url = this.getFullUrl(
      `/sendaSnack/blocks/${accountId}/${blockedAccountId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Delete);
  }

  sendSnackToUser(
    locationId: string,
    amount: number,
    description: string | null,
    email: string,
    isPrivate: boolean,
    senderAccountId: string,
    stickerId: number,
    snackSent: string,
    senderAccountBalanceId: string,
    appName: string,
  ) {
    const url = this.getFullUrl(`/sendaSnack/${locationId}/snacks`);
    const body = {
      amount,
      description,
      email,
      isPrivate,
      senderAccountId,
      stickerId,
      snackSent,
      senderAccountBalanceId,
      appName,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  loadSnackDetails(locationId: string, snackId: string) {
    const url = this.getFullUrl(`/sendaSnack/${locationId}/snacks/${snackId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  acceptSnack(
    locationId: string,
    snackId: string,
    receiverAccountId: string,
    receiverAccountBalanceId: string,
    snackTime: string,
  ) {
    const url = this.getFullUrl('/sendaSnack/actions/accept-snack');
    const body = {
      locationId,
      snackId,
      receiverAccountId,
      receiverAccountBalanceId,
      snackTime,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  cancelSnack(locationId: string, snackId: string, snackTime: string) {
    const url = this.getFullUrl('/sendaSnack/actions/cancel-snack');
    const body = {
      locationId,
      snackId,
      snackTime,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  blockSnackUser(
    accountId: string,
    blockedAccountId: string,
    dateCreated: string,
  ) {
    const url = this.getFullUrl(`/sendaSnack/blocks/${accountId}`);
    const body = {
      blockedAccountId,
      dateCreated,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }

  retrievePayrollDeductData(
    currentDate: string,
    payrollDeductGroupId: string | null | undefined,
    locationId: string | null | undefined,
  ) {
    const url = this.getFullUrl('/payroll-deduct', {
      currentDate,
      payrollDeductGroupId,
      locationId,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchPayrollDeductGroup(locationId: string) {
    const url = this.getFullUrl('/payroll-deduct/default-group', {
      locationId,
    });
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchNearbyLocations(lat: number, long: number) {
    const url = this.getLegacyUrl('/locations', {
      lat,
      long,
    });
    return Api.fetch(url, null, HttpVerbs.Get, false);
  }

  fetchOrderAheadAvailableTimes(
    pickupDateTime: string,
    localTimeZone: string,
    locationId: string,
    pickupLocation: string,
  ) {
    const params = {
      pickupDateTime,
      localTimeZone,
    };

    const url = this.getFullUrl(
      `/order-ahead-menus/time-slots/${locationId}/${pickupLocation}`,
      params,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchMenu(
    pickupTime: string,
    locationId: string,
    pickupLocation: string,
    dayOfWeek?: number,
    includeUpsells = true,
  ) {
    const params = {
      availableTime: pickupTime,
      dayOfWeek,
      includeUpsells,
    };

    const url = this.getFullUrl(
      `/order-ahead-menus/menus/${locationId}/${pickupLocation}`,
      params,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchProductModifiers(
    locationId: string,
    productId: string,
    includeUpsells = true,
  ) {
    const url = this.getFullUrl(
      `/order-ahead-menus/product-location-modifiers/${locationId}/${productId}`,
      {includeUpsells},
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchProductNutrition(locationId: string, productId: string) {
    const url = this.getFullUrl(
      `/order-ahead-menus/nutrition/${locationId}/${productId}`,
    );
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  fetchDeviceListing(deviceId: string, startDate: string) {
    const url = this.getFullUrl(`/devices/listing/${deviceId}`, {startDate});
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  getLegacyUrl(path: string, params?: any) {
    const baseUrl = Api.getTallyUrl(UrlTypes.legacyplatformapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  getFullUrl(path: string, params?: any) {
    const baseUrl = Api.getGatewayUrl(UrlTypes.platformapi);
    return Api.getFullUrl(baseUrl, path, params);
  }

  getLocationFromId(locationId: string) {
    const url = this.getFullUrl(`/locations/${locationId}`);
    return Api.fetchGateway(url, null, HttpVerbs.Get);
  }

  validateDiscountCode(locationId: string, scanCode: string) {
    const url = this.getFullUrl(
      `/actions/consumer-roles/${locationId}/validate-role`,
    );
    const body = {
      scanCode,
    };
    return Api.fetchGateway(url, body, HttpVerbs.Post);
  }
}
export default new PlatformApi();
