import * as React from 'react';
import {Text, StyleSheet, View, FlatList, PixelRatio} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import FontAwesome5Pro from '../../../icons/FontAwesomeIcon';
import {CheckBox} from 'react-native-elements';
import ScreenContext from '../../../ScreenContext';
import BackSubheader from '../../../elements/BackSubheader';
import Styles from '../../../Styles';
import Util from 'src/Util';
import BunnApi from 'src/api/coffee/BunnApi';
import type {BunnRecipe, BunnRecipeSize} from 'src/api/coffee/BunnApi';
import uuid from 'src/nativeModules/UUID';
import Events from 'src/logging/Events';
import RoundedButton, {ButtonType} from '../../../elements/RoundedButton';
import UIManager from '../../../elements/ui/UIManager';
import TouchableOpacity from '../../../elements/AVTouchableOpacity';
import MenuOption from '../../../elements/MenuOption';
import NavActions from 'src/actions/NavActions';
import Localized from 'src/constants/AppStrings';
import AVFormattedCurrency from '../../../elements/AVFormattedCurrency';
import AccountStore from 'src/stores/AccountStore';
import CartService from 'src/services/CartService';
import AccountConstants from 'src/constants/AccountConstants';
import CoffeeMachineTypes from 'src/constants/CoffeeMachineTypes';
import AppRoutes from 'src/AppRoutes';
import CartTypes from 'src/constants/cart/CartTypes';
import CartStore from 'src/stores/CartStore';
import BunnSizes from 'src/constants/cart/BunnSizes';
import BunnMenuStore from 'src/stores/BunnMenuStore';
import ScanditView from '../../../elements/ScanditView';
import {alertError} from '../../../helpers/AlertHelper';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
type CoffeeMachineScreenProps = {
  marketName: string;
  locationId: string;
  locationType: string;
  beaconId: string;
};
type CoffeeMachineScreenState = {
  state: ViewStates;
  code: string;
  headerText: string;
  leaveRoom: boolean;
  bold: boolean;
  selectedSize: number;
  price: number;
  scanning: boolean;
};
enum ViewStates {
  scan,
  selection,
  size,
}

class CoffeeMachineScreen extends React.Component<
  CoffeeMachineScreenProps,
  CoffeeMachineScreenState
> {
  buttonWidth: number;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      state: ViewStates.scan,
      code: '',
      headerText: Localized.Labels.coffee_machine,
      leaveRoom: false,
      bold: false,
      selectedSize: -1,
      price: 0,
      scanning: true,
    };
    this.buttonWidth = (Styles.ScreenWidth - 4 * Styles.Spacing.m3) / 3;
    this.renderScanning = this.renderScanning.bind(this);
    this.urlScanned = this.urlScanned.bind(this);
    this.handleRecipePress = this.handleRecipePress.bind(this);
    this.handleSizePress = this.handleSizePress.bind(this);
    this.onBackSelect = this.onBackSelect.bind(this);
    this.addItemClick = this.addItemClick.bind(this);
    this.stopScanning = this.stopScanning.bind(this);
    this.startScanning = this.startScanning.bind(this);
  }

  // componentDidMount() {
  //   this.urlScanned('https://marketconnect.app.link/bunn?ref=ec191e2e-3d1a-43de-b74d-1acb0c412cd2');
  // }
  stopScanning() {
    this.setState({
      scanning: false,
    });
  }

  startScanning() {
    this.setState({
      scanning: true,
    });
  }

  onBackSelect() {
    if (this.state.state === ViewStates.scan) {
      NavActions.pop();
    } else if (this.state.state === ViewStates.selection) {
      this.setState({
        state: ViewStates.scan,
      });
    } else if (this.state.state === ViewStates.size) {
      this.setState({
        headerText: Localized.Labels.coffee_machine,
        state: ViewStates.selection,
      });
    }
  }

  async addItemClick() {
    const selectedSize = BunnMenuStore.SelectedSize;
    const selectedRecipe = BunnMenuStore.SelectedRecipe;

    try {
      FirebaseAnalytic.trackEvent('addItemClick', 'CoffeeMachineScreen', {
        ...this.props,
        ...this.state,
        selectedSize,
        selectedRecipe,
      });
      if (selectedSize && selectedRecipe) {
        const result = await CartService.updateCart(
          this.props.locationId,
          AccountStore.getAccountId(),
          [],
          [
            {
              BarCode: CoffeeMachineTypes.Bunn.scanCode,
              Price: selectedSize.price,
              Description: CoffeeMachineTypes.Bunn.name,
            },
          ],
          AccountConstants.SOS_LOCATION_TYPE,
          CoffeeMachineTypes.Bunn.scanCode,
          null,
          '',
          this.context,
        );

        if (result) {
          CartStore.setCoffeeSelection({
            uuid: BunnMenuStore.MenuIdentifier,
            bold: this.state.bold,
            drinkId: selectedRecipe.id,
            item: selectedRecipe.customName,
            leaveRoom: this.state.leaveRoom,
            sizeId: selectedSize.id,
            sizeName: selectedSize.sizeName,
          });
          NavActions.replace(AppRoutes.Scan, {
            ...this.props,
            cartType: CartTypes.TouchlessCoffee,
          });
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'CoffeeMachineScreen:addItemClick',
        error.message ? error.message : error.toString(),
        guid,
        {
          ...this.props,
          ...this.state,
        },
      );
    }
  }

  handleRecipePress(recipe: BunnRecipe) {
    try {
      BunnMenuStore.selectRecipe(recipe);

      if (BunnMenuStore.AvailableSizes.length > 0) {
        const selectedSize = BunnMenuStore.AvailableSizes[0];
        BunnMenuStore.selectSize(selectedSize);
        this.setState({
          state: ViewStates.size,
          headerText: recipe.customName,
          selectedSize: selectedSize.id,
          price: parseFloat(selectedSize.price),
        });
      } else {
        alertError(Localized.Errors.no_size_options_available);
      }
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CoffeeMachineScreen:handleRecipePress',
        error.message ? error.message : error.toString(),
        '',
        {
          ...this.props,
          ...this.state,
          recipe,
        },
      );
    }
  }

  handleSizePress(size: BunnRecipeSize) {
    try {
      BunnMenuStore.selectSize(size);
      this.setState({
        selectedSize: size.id,
        price: parseFloat(size.price),
      });
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'CoffeeMachineScreen:handleSizePress',
        error.message ? error.message : error.toString(),
        '',
        {
          ...this.props,
          ...this.state,
          size,
        },
      );
    }
  }

  handleScanningError(url: string, message: string, guid?: string) {
    this.stopScanning();
    alertError(message, guid, () => {
      this.startScanning();
    });
    Events.QRCodeNotRecognized.trackEvent(url, guid ? 'ERROR' : 'OK');
  }

  async urlScanned(url: string) {
    try {
      this.context.actions.showSpinner();
      const params = Util.parseUrlParams(url);
      const menuId = params.ref;

      if (!menuId) {
        this.handleScanningError(url, Localized.Errors.error_scanning_qr_code);
        return;
      }

      await BunnApi.startSession(menuId);
      const menu = await BunnApi.getMenu(menuId);
      BunnMenuStore.resetMenu(menu, menuId);

      if (BunnMenuStore.AvailableRecipes.length === 0) {
        this.handleScanningError(url, Localized.Errors.unable_to_retrieve_menu);
      } else {
        Events.Bunn.trackEvent('MachineScanned', {
          serialNumber: menu.serialNumber,
          ownerName: menu.serialNumber,
          machineType: menu.machineType,
          machineName: menu.machineName,
          machineModel: menu.machineModel,
          machineId: menu.machineId,
        });
        this.setState({
          state: ViewStates.selection,
        });
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'CoffeeMachineScreen:URLScanned',
        error.message ? error.message : error.toString(),
        guid,
      );
      this.handleScanningError(
        url,
        Localized.Errors.error_scanning_qr_code,
        guid,
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  renderRecipeSelection(
    text: string = Localized.Labels.start_brewing,
  ): React.ReactNode {
    return (
      <View style={styles.content}>
        <FlatList
          data={BunnMenuStore.AvailableRecipes}
          renderItem={({item, index, separators}) => (
            <MenuOption
              accessibilityLabel={item.customName}
              onPress={() => this.handleRecipePress(item)}
              label={item.customName}
              disabled={!item.hasProduct}
            />
          )}
        />
      </View>
    );
  }

  getSizeLogoSize(size: BunnRecipeSize): number {
    switch (size.id) {
      case BunnSizes.Small:
        return Styles.Fonts.f1;

      case BunnSizes.Medium:
        return Styles.Fonts.f2;

      case BunnSizes.Large:
        return Styles.Fonts.f3;

      case BunnSizes.XLarge:
        return Styles.Fonts.f4;

      case BunnSizes.Carafe:
        return Styles.Fonts.f5;

      default:
        return Styles.Fonts.f2;
    }
  }

  renderSizeSelection() {
    return (
      <View style={styles.content}>
        <Text style={styles.labelText}>{Localized.Labels.size}</Text>
        <FlatList
          style={styles.recipes}
          numColumns={3}
          data={BunnMenuStore.AvailableSizes}
          renderItem={({item, index, separators}) => {
            const selected = item.id === this.state.selectedSize;
            return (
              <TouchableOpacity
                style={[
                  styles.recipeButton,
                  {
                    width: this.buttonWidth,
                    height: this.buttonWidth,
                  },
                  selected && styles.selectedSize,
                ]}
                onPress={() => this.handleSizePress(item)}
              >
                <View style={styles.icon}>
                  <FontAwesome5Pro
                    name="coffee-togo"
                    color={selected ? Styles.white : Styles.lightGray}
                    size={this.getSizeLogoSize(item)}
                    solid
                  />
                </View>
                <>
                  <Text
                    style={[
                      styles.sizeText,
                      selected && styles.selectedSizeText,
                    ]}
                  >
                    {item.sizeName}
                  </Text>
                  <AVFormattedCurrency
                    accessibilityLabel="price"
                    style={[
                      styles.recipeText,
                      selected && styles.selectedSizeText,
                    ]}
                    value={parseFloat(item.price)}
                    currency={AccountStore.getCurrency()}
                  />
                </>
              </TouchableOpacity>
            );
          }}
        />
        {BunnMenuStore.SelectedRecipe &&
          BunnMenuStore.SelectedRecipe.leaveRoom && (
            <CheckBox
              title={Localized.Labels.leave_room_in_coffee}
              checked={this.state.leaveRoom}
              key="leaveRoom"
              size={Styles.Fonts.f2}
              textStyle={styles.optionText}
              containerStyle={styles.radioButtonWrapper}
              onPress={() =>
                this.setState({
                  leaveRoom: !this.state.leaveRoom,
                })
              }
              checkedColor={Styles.primaryColor}
              uncheckedColor={Styles.lightGray}
              accessibilityLabel={Localized.Labels.leave_room_in_coffee}
              //@ts-ignore
              nativeID={Localized.Labels.leave_room_in_coffee}
            />
          )}
        {BunnMenuStore.SelectedRecipe && BunnMenuStore.SelectedRecipe.bold && (
          <CheckBox
            title={Localized.Labels.bold}
            checked={this.state.bold}
            key="bold"
            size={Styles.Fonts.f2}
            textStyle={styles.optionText}
            containerStyle={styles.radioButtonWrapper}
            onPress={() =>
              this.setState({
                bold: !this.state.bold,
              })
            }
            checkedColor={Styles.primaryColor}
            uncheckedColor={Styles.lightGray}
            accessibilityLabel={Localized.Labels.bold}
            //@ts-ignore
            nativeID={Localized.Labels.bold}
          />
        )}
        <RoundedButton
          accessibilityLabel="Add item"
          buttonType={ButtonType.action}
          text={`${Localized.Buttons.add_item} ${Util.formatCurrency(
            this.props,
            this.state.price,
            AccountStore.getCurrency(),
          )}`}
          onPress={this.addItemClick}
        />
      </View>
    );
  }

  renderScanning(): React.ReactNode {
    return (
      <View style={styles.content}>
        <Text style={styles.instruction}>
          {Localized.Labels.scan_qr_code_from_coffee_machine}
        </Text>
        <ScanditView
          style={styles.scanditContainer}
          barcodeScanned={this.urlScanned}
          scanning={this.state.scanning}
        />
      </View>
    );
  }

  getContent(): React.ReactNode | null {
    if (this.state.state === ViewStates.scan) {
      return this.renderScanning();
    } else if (this.state.state === ViewStates.selection) {
      return this.renderRecipeSelection();
    } else if (this.state.state === ViewStates.size) {
      return this.renderSizeSelection();
    }

    return null;
  }

  render(): React.ReactNode {
    const content = this.getContent();
    const rightView = UIManager.getBalanceContainer(false, Localized);
    return (
      <BackSubheader
        title={this.state.headerText}
        rightView={rightView}
        pop={false}
        onBackSelect={this.onBackSelect}
      >
        {content}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  mainButton: {
    flex: 1,
    backgroundColor: Styles.primaryColor,
    borderRadius: Styles.Spacing.m3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  brewingText: {
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f4,
    color: Styles.white,
  },
  scanView: {
    flex: 1,
  },
  scanditContainer: {
    alignSelf: 'stretch',
    borderRadius: Styles.Spacing.m3,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
  },
  instruction: {
    fontSize: Styles.Fonts.f1,
  },
  labelText: {
    alignSelf: 'stretch',
    fontSize: Styles.Fonts.f1,
  },
  recipeButton: {
    alignItems: 'center',
    backgroundColor: Styles.bgColor,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m2),
    marginRight: Styles.Spacing.m3,
    justifyContent: 'space-between',
    marginBottom: Styles.Spacing.m3,
    padding: Styles.Spacing.m3,
  },
  selectedSize: {
    backgroundColor: Styles.primaryColor,
  },
  recipeText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    fontWeight: undefined,
  },
  sizeText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
  },
  selectedSizeText: {
    color: Styles.white,
  },
  recipes: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignSelf: 'center',
    marginTop: Styles.Spacing.m2,
    flexGrow: undefined,
  },
  icon: {
    height: Styles.Fonts.f3,
    justifyContent: 'flex-end',
  },
  optionText: {
    fontSize: Styles.Fonts.f1,
    marginLeft: Styles.Spacing.m1,
    color: Styles.darkColor,
    marginTop: -Styles.Spacing.m1 / 2,
  },
  radioButtonWrapper: {
    paddingVertical: Styles.Spacing.m1,
    backgroundColor: Styles.white,
    borderWidth: 0,
  },
});
export default withForwardedNavigationParams<CoffeeMachineScreenProps>()(
  CoffeeMachineScreen,
);
