import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import Util from 'src/Util';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import {alertError} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import HttpClient from 'src/api/HttpClient';
import Logger from 'src/logging/Logger';

export type BlockUserParams = {
  snackDetails: GiftItemType;
};
export const blockUser = createAsyncThunk<
  GiftItemType,
  BlockUserParams,
  {dispatch: AppDispatch; state: RootState}
>('snack/blockUser', async ({snackDetails}, {dispatch, getState}) => {
  dispatch(showSpinner());
  const accountId = getState().account.account.id;

  try {
    const blockSnackUserRes = await PlatformApi.blockSnackUser(
      accountId,
      snackDetails.senderAccountId,
      Util.getCurrentDate(),
    );

    Logger.Log.LogAPIEvent(
      'PlatformAPI',
      'BlockSnackUser',
      JSON.stringify({
        accountId,
        senderAccountId: snackDetails.senderAccountId,
      }),
      JSON.stringify(blockSnackUserRes),
    );

    return {
      ...snackDetails,
      isBlocked: true,
    };
  } catch (err) {
    const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());

    if (error?.code === PlatformApiErrors.SnackUserBlocked) {
      alertError(Localized.Errors.snack_user_already_blocked);
    } else {
      alertError(Localized.Errors.error);
      Events.Error.trackEvent(
        'Exception',
        'snack/blockUser',
        error.message ? error.message : JSON.stringify(error),
      );
    }
    Logger.Log.LogAPIEvent(
      'PlatformAPI',
      'BlockSnackUser',
      JSON.stringify({
        accountId,
        senderAccountId: snackDetails.senderAccountId,
      }),
      JSON.stringify(err),
    );
  } finally {
    dispatch(hideSpinner());
  }
});
