import './src/TestBrowser';
import 'react-native-gesture-handler';
import * as React from 'react';
import {AppRegistry} from 'react-native';
import {FormattedProvider, loadCldr} from 'react-native-globalize';
import {MenuProvider} from 'react-native-popup-menu';
import {Provider as PaperProvider} from 'react-native-paper';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from './src/redux/store';
import {PaperTheme} from './src/components/PaperTheme';
import Settings from './src/Settings';
import App from './src/components/App';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

loadCldr(
  require('react-native-globalize/locale-data/en'),
  require('react-native-globalize/locale-data/en-CA'),
  require('react-native-globalize/locale-data/en-AU'),
  require('react-native-globalize/locale-data/en-GB'),
  require('react-native-globalize/locale-data/en-IE'),
  require('react-native-globalize/locale-data/fr-CA'),
  require('react-native-globalize/locale-data/fr'),
  require('react-native-globalize/locale-data/it'),
  require('react-native-globalize/locale-data/es'),
  require('react-native-globalize/locale-data/es-US'),
  require('react-native-globalize/locale-data/nl'),
  require('react-native-globalize/locale-data/de-CH'),
  require('react-native-globalize/locale-data/de'),
  require('react-native-globalize/locale-data/it-CH'),
  require('react-native-globalize/locale-data/fr-CH'),
  require('react-native-globalize/locale-data/sv-FI'),
  require('react-native-globalize/locale-data/sv'),
  require('react-native-globalize/locale-data/da'),
  require('react-native-globalize/locale-data/nb'),
  require('react-native-globalize/locale-data/fi'),
);

AppRegistry.registerComponent('App', () => () => {
  const locale = Settings.getLocale();
  return (
    <GestureHandlerRootView style={{flex:1}}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FormattedProvider locale={locale} localeFallback>
            <MenuProvider>
              <PaperProvider theme={PaperTheme}>
                <App />
              </PaperProvider>
            </MenuProvider>
          </FormattedProvider>
        </PersistGate>
      </Provider>
    </GestureHandlerRootView>
  );
});

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
