import Auth from '@aws-amplify/auth';
import AppSyncClientProvider from './AppSyncClientProvider';
import AccountStore from 'src/stores/AccountStore';
import Events from 'src/logging/Events';

class AppSyncAuthenticate {
  authenticatePromise: Promise<void> | null | undefined;

  constructor() {
    AccountStore.addChangeListener(() => {
      const accountId = AccountStore.getAccountId();

      if (accountId) {
        if (accountId === '-1') {
          this.signOut();
        }
      }
    });
  }

  async authenticate() {
    const accountId = AccountStore.getAccountId();

    if (accountId && accountId !== '-1') {
      await AppSyncClientProvider.updateEnvironment();

      if (this.authenticatePromise) {
        return this.authenticatePromise;
      } else {
        this.authenticatePromise = (async () => {
          try {
            let signedIn =
              (await this._isSignedIn(accountId)) ||
              (await this._attemptSignIn(accountId));

            if (!signedIn) {
              signedIn =
                (await this._attemptSignUp(accountId)) &&
                (await this._attemptSignIn(accountId));
            }
          } catch (e) {
            Events.Error.trackEvent(
              'Exception',
              'AppSyncAuthenticate:Authenticate',
              e.message ? e.message : e.toString(),
            );
          }
        })();

        return this.authenticatePromise;
      }
    }
  }

  async _isSignedIn(uuid: string): Promise<boolean> {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });

      if (user.username && user.username === uuid) {
        Events.AppSync.trackEvent(
          'AppSync:UserAuthenticationSuccess',
          user.username,
          uuid,
        );
        return true;
      }
    } catch (e) {
      Events.AppSync.trackEvent(
        'AppSync:UserAuthenticationFailed',
        e.message ? e.message : e.toString(),
        uuid,
      );
    }

    return false;
  }

  async _attemptSignIn(uuid: string): Promise<boolean> {
    try {
      const user = await Auth.signIn({
        username: uuid,
        password: uuid,
      });

      if (user.username && user.username === uuid) {
        return true;
      }
    } catch (e) {
      Events.AppSync.trackEvent(
        'AppSync:UserSignInFailed',
        e.message ? e.message : e.toString(),
        uuid,
      );
    }

    return false;
  }

  async _attemptSignUp(uuid: string): Promise<boolean> {
    try {
      const result = await Auth.signUp({
        username: uuid,
        password: uuid,
      });

      if (result.user && result.user.getUsername() === uuid) {
        return true;
      }
    } catch (e) {
      Events.AppSync.trackEvent(
        'AppSync:UserSignUpFailed',
        e.message ? e.message : e.toString(),
        uuid,
      );
    }

    return false;
  }

  async signOut(): Promise<void> {
    try {
      await this.authenticate();
      await Auth.signOut();
      this.authenticatePromise = null;
    } catch (e) {
      Events.AppSync.trackEvent(
        'AppSyncAuthenticate:SignOut',
        e.message ? e.message : e.toString(),
      );
    }
  }
}

const instance = new AppSyncAuthenticate();
export default instance;
