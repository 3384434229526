import {createAsyncThunk} from '@reduxjs/toolkit';
import AccountStore from 'src/stores/AccountStore';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import {RootState} from '../../store';
import Logger from 'src/logging/Logger';

export type LoadSnacksParams = {
  locationId: string;
  offset?: number;
  limit?: number;
};
export const loadSnacks = createAsyncThunk<
  Array<GiftItemType>,
  LoadSnacksParams,
  {state: RootState}
>(
  'snack/loadSnacks',
  async ({locationId, offset = 0, limit = 10}, {getState}) => {
    const shouldLoadSnacks =
      AccountStore.isSendSnackEnabledForLocation() &&
      AccountStore.isConsumerSendSnackEnabled();

    if (!shouldLoadSnacks) {
      return [];
    }

    try {
      const accountId = getState().account.account.id;
      const response = await PlatformApi.fetchSnacks(
        locationId,
        offset,
        limit,
        accountId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchSnacks',
        JSON.stringify({locationId, offset, limit, accountId}),
        JSON.stringify(response),
      );
      return response?.items ?? [];
    } catch (error) {
      Events.Error.trackEvent(
        'Exception',
        'snack/loadSnacks',
        error.message ? error.message : JSON.stringify(error),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchSnacks',
        JSON.stringify({locationId, limit, offset}),
        JSON.stringify(error),
      );
    }
  },
);
