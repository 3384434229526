import * as React from 'react';
import {StyleSheet, View} from 'react-native';
import Switch from 'src/components/elements/Switch';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import BackSubheader from '../../elements/BackSubheader';
import CreditCardInput from '../../elements/funding/CreditCardInput';
import KeyboardAwareScrollView from '../../elements/KeyboardAwareScrollView';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import PersistentStore from 'src/services/PersistentStoreService';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../../helpers/AlertHelper';
import CreditCard from 'src/models/CreditCard';
import AVText from 'src/components/elements/AVText';
import Star from 'src/components/img/svg/inbox/Star';
import Events from 'src/logging/Events';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type CreditCardDetailScreenProps = IsConnectedProps & {
  creditCard: CreditCard;
  navigation: NavigationProp<CreditCardDetailScreen>;
};
type CreditCardDetailScreenState = {
  defaultTokenStatus: boolean;
  previousRoute: string;
};

class CreditCardDetailScreen extends React.Component<
  CreditCardDetailScreenProps,
  CreditCardDetailScreenState
> {
  scrollView: KeyboardAwareScrollView | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CreditCardDetailScreenProps) {
    super(props);
    this.state = {
      defaultTokenStatus: this.props.creditCard.isDefault,
      previousRoute: '',
    };
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSetAsDefaultClick = this.handleSetAsDefaultClick.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  async handleDeleteClick() {
    confirm(Localized.Labels.confirm_delete_card, async () => {
      try {
        this.context.actions.showSpinner();
        const accountId = await PersistentStore.getAccountId();
        const response =
          await ActionsFactory.getAccountActions().deleteCreditCard(
            accountId || '',
            this.props.creditCard.id || '',
            this.props.creditCard.accountBalanceId || '',
            this.props.creditCard.isDefault,
          );
        this.context.actions.hideSpinner();
        if (response?.status === 'ok') {
          FirebaseAnalytic.trackEvent('DeleteCard', 'CreditCardAddScreen', {
            ...this.props,
            ...this.state,
          });
          NavActions.pop();
        } else {
          alertError(Localized.Errors.error_updating_credit_card);
        }
      } catch (e) {
        alertError(Localized.Errors.error_updating_credit_card);
        Events.Error.trackEvent(
          'Exception',
          'CreditCardDetailScreen:handleDeleteClick',
          e.message ? e.message : e.toString(),
        );
      }
    });
  }

  async handleSetAsDefaultClick() {
    FirebaseAnalytic.trackEvent(
      'handleSetAsDefaultClick',
      'CreditCardAddScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    confirm(
      Localized.Labels.confirm_set_as_default_card,
      async () => {
        this.context.actions.showSpinner();
        try {
          const accountId = await PersistentStore.getAccountId();
          const response =
            await ActionsFactory.getAccountActions().updateDefaultToken(
              accountId || '',
              this.props.creditCard.accountBalanceId || '',
              this.props.creditCard.id || '',
            );

          FirebaseAnalytic.trackEvent(
            'handleSetAsDefaultClick updateDefaultToken',
            'CreditCardAddScreen',
            {
              ...this.props,
              ...this.state,
              accountId,
              response,
            },
          );
          this.context.actions.hideSpinner();
          NavActions.pop();
        } catch (error) {
          Events.Error.trackEvent(
            'Exception',
            'CreditCardDetailScreen:HandleSetAsDefaultClick',
            error.message ? error.message : error.toString(),
          );
          alertError(Localized.Errors.error_setting_default_credit_card);
        }
      },
      () => NavActions.pop(),
    );
  }

  renderCardDefaultStatus() {
    if (this.props.creditCard.isDefault) {
      return (
        <View style={styles.defaultStatusView}>
          <Star width={Styles.Fonts.f4} height={Styles.Fonts.f4} />
          <AVText
            accessible={true}
            accessibilityLabel={
              Localized.Labels.this_card_is_set_as_your_default
            }
            accessibilityRole="text"
            aria-label={`${Localized.Labels.this_card_is_set_as_your_default}, text`}
            style={styles.defaultLabel}
          >
            {Localized.Labels.this_card_is_set_as_your_default}
          </AVText>
        </View>
      );
    } else {
      return (
        <View style={styles.defaultStatusView}>
          <Switch
            accessibilityLabel={Localized.Labels.set_as_default}
            testID="setAsDefault"
            text={Localized.Labels.set_as_default}
            accessibilityState={{checked: this.state.defaultTokenStatus}}
            value={this.state.defaultTokenStatus}
            onValueChange={(value) => {
              this.setState({defaultTokenStatus: value});
              this.handleSetAsDefaultClick();
            }}
          />
        </View>
      );
    }
  }

  render() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.credit_card}
      >
        <View style={styles.content}>
          <KeyboardAwareScrollView
            contentContainerStyle={styles.scrollView}
            keyboardDismissMode="interactive"
            style={styles.scrollView}
            ref={(scrollView) => {
              this.scrollView = scrollView;
            }}
            automaticallyAdjustContentInsets={false}
          >
            <View style={styles.container}>
              <View style={styles.row}>
                <CreditCardInput
                  accessibilityLabel={Localized.Labels.card_number}
                  label={Localized.Labels.card_number}
                  editable={false}
                  issuer={this.props.creditCard.cardIssuer}
                  value={this.props.creditCard.name}
                />
              </View>
              <View style={[styles.row, {marginTop: -30}]}>
                <View style={styles.leftInput}>
                  <AllyTextInput
                    useHalfSize={true}
                    label={Localized.Labels.expiration_month}
                    value={this.props.creditCard.expirationMonth.toString()}
                    defaultValue={this.props.creditCard.expirationMonth.toString()}
                    editable={false}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.expiration_month}
                    accessibilityValue={{
                      text: this.props.creditCard.expirationMonth.toString(),
                    }}
                    accessibilityState={{disabled: true}}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>
                <View style={styles.rightInput}>
                  <AllyTextInput
                    useHalfSize={true}
                    label={Localized.Labels.expiration_year}
                    value={this.props.creditCard.expirationYear.toString()}
                    defaultValue={this.props.creditCard.expirationYear.toString()}
                    editable={false}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.expiration_year}
                    accessibilityValue={{
                      text: this.props.creditCard.expirationYear.toString(),
                    }}
                    accessibilityState={{disabled: true}}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  />
                </View>
              </View>
              <View>
                <AllyTextInput
                  label={Localized.Labels.card_type}
                  value={this.props.creditCard.cardIssuer}
                  defaultValue={this.props.creditCard.cardIssuer}
                  editable={false}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.card_type}
                  accessibilityValue={{text: this.props.creditCard.cardIssuer}}
                  accessibilityState={{disabled: true}}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                />
              </View>
              <View style={styles.row}>{this.renderCardDefaultStatus()}</View>
            </View>
          </KeyboardAwareScrollView>

          {this.props.isConnected && (
            <RoundedButton
              buttonType={ButtonType.action}
              color={Styles.dangerColor}
              accessible={true}
              accessibilityLabel={Localized.Buttons.delete_card}
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.delete_card}
              onPress={this.handleDeleteClick}
              text={Localized.Buttons.delete_card}
            />
          )}
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  container: {
    flexDirection: 'column',
    flex: 1,
    marginHorizontal: Styles.Spacing.m3,
    marginBottom: 100,
  },
  leftInput: {
    flexDirection: 'column',
    flex: 0.5,
    marginRight: Styles.Spacing.m1,
  },
  rightInput: {
    flexDirection: 'column',
    flex: 0.5,
    marginLeft: Styles.Spacing.m1,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  defaultStatusView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  defaultSwitch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  defaultLabel: {
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m1,
    fontStyle: 'italic',
  },
  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
});
export default withForwardedNavigationParams()(
  withIsConnected(CreditCardDetailScreen),
);
