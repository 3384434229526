import * as React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {NavigationProp} from '@react-navigation/native';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import BaseScreen from '../BaseScreen';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import {StyleSheet, View, ScrollView, Platform} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {getPreviousRouteName} from 'src/Util';
import PasswordInput from '../../PasswordInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type ChangePasswordScreenProps = {
  isConnected: boolean;
  navigation?: NavigationProp<ChangePasswordScreen>;
};
type ChangePasswordScreenState = {
  password: string;
  confirmPassword: string;
  currentPassword: string;
  errorMessage: string[];
  previousRoute?: string | null;
};

class ChangePasswordScreen extends React.Component<
  ChangePasswordScreenProps,
  ChangePasswordScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ChangePasswordScreenProps) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      currentPassword: '',
      errorMessage: [],
      previousRoute: null,
    };
    this.saveClick = this.saveClick.bind(this);
    this.validate = this.validate.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  saveClick() {
    if (!this.validate()) {
      alertError(Localized.Errors.must_enter_matching_password);
    } else if (this.state.errorMessage.length > 0) {
      alertError(this.state.errorMessage.join('\n'));
    } else {
      this.changePassword();
    }
  }

  validate() {
    return (
      this.state.password &&
      this.state.confirmPassword &&
      this.state.password === this.state.confirmPassword
    );
  }

  async changePassword() {
    if (!this.state.currentPassword) {
      alertError(Localized.Errors.current_password_is_not_correct);
    } else {
      this.context.actions.showSpinner();

      try {
        const response =
          await ActionsFactory.getAccountActions().changePassword(
            AccountStore.getAccountId(),
            this.state.currentPassword,
            this.state.password,
          );

        FirebaseAnalytic.trackEvent('changePassword', 'ChangePasswordScreen', {
          ...this.props,
          ...this.state,
          response,
        });

        if (response.Success) {
          alertSuccess(Localized.Success.password_changed);
          NavActions.pop();
        } else if (response.InvalidPassword) {
          alertError(Localized.Errors.current_password_is_not_correct);
        } else {
          alertError(Localized.Errors.unable_to_set_password);
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'ChangePasswordScreen:ChangePassword',
          error.message ? error.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.unable_to_set_password, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  render() {
    return (
      <BaseScreen
        title={Localized.Labels.change_password}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView
            contentContainerStyle={Styles.Style.fex}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={styles.content}>
              <PasswordInput
                accessible={true}
                accessibilityLabel={Localized.Labels.current_password}
                accessibilityValue={{text: this.state.currentPassword}}
                label={Localized.Labels.current_password}
                value={this.state.currentPassword}
                onChangeText={(text: string) =>
                  this.setState({currentPassword: text})
                }
              />
              <PasswordInput
                accessible={true}
                accessibilityLabel={Localized.Labels.new_password}
                accessibilityValue={{text: this.state.password}}
                label={Localized.Labels.new_password}
                value={this.state.password}
                needValidation={true}
                onChangeText={(text: string) => this.setState({password: text})}
              />
              <PasswordInput
                accessible={true}
                accessibilityLabel={Localized.Labels.confirm_password}
                accessibilityValue={{text: this.state.confirmPassword}}
                label={Localized.Labels.confirm_password}
                value={this.state.confirmPassword}
                needValidation={true}
                onChangeText={(text: string, errorMessage: string[]) =>
                  this.setState({
                    confirmPassword: text,
                    errorMessage,
                  })
                }
              />
            </View>
          </ScrollView>
          {this.props.isConnected && (
            <RoundedButton
              buttonType={ButtonType.action}
              accessible={true}
              accessibilityLabel={Localized.Buttons.save_new_password}
              accessibilityRole="button"
              aria-label={Localized.Buttons.save_new_password}
              role="button"
              onPress={this.saveClick}
              text={Localized.Buttons.save}
            />
          )}
        </KeyboardAvoidingView>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingLeft: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
    marginLeft: -7.5,
    width: Platform.OS === 'web' ? '50%' : '100%',
  },
});
export default withForwardedNavigationParams()(
  withIsConnected(ChangePasswordScreen),
);
