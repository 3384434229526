import moment from 'moment';
import MoblicoTokenService from 'src/services/MoblicoTokenService';
import MoblicoApi from 'src/api/MoblicoApi';
import DealRepository from './aws/DealRepository';
import type {MoblicoPromotionType} from 'src/types/Promotions';
import Events, {PromotionActions} from 'src/logging/Events';
import Deal from 'src/models/Moblico/Deal';
import type {Deal as ServerDeal} from 'src/types/serverTypes/Moblico';

class DealService {
  async loadMoblicoDeals(email: string) {
    try {
      const token = await MoblicoTokenService.getToken(email);
      const response: Array<ServerDeal> = await MoblicoApi.getDeals(token);

      if (response) {
        this.reconcileDeals(response);
      }
    } catch (error) {
      // Only log the error if it's something other than a 404 from the request.
      if (
        error &&
        error.networkResponse &&
        error.networkResponse.status !== 404
      ) {
        Events.Error.trackEvent(
          'Exception',
          'DealService:loadMoblicoDeals',
          error.message ? error.message : error.toString(),
        );
      }
    }
  }

  reconcileDeals(remoteDeals: Array<ServerDeal>) {
    const deals = remoteDeals.map((deal: ServerDeal) => {
      return new Deal(deal);
    });
    DealRepository.addDeals(deals);
  }

  async acceptMoblicoDeal(email: string, id: number) {
    const token = await MoblicoTokenService.getToken(email);
    await MoblicoApi.acceptDeal(token, id.toString());
    const deal = DealRepository.getDeal(id);
    Events.Promotion.trackEvent(
      id.toString(),
      deal.name,
      PromotionActions.ClaimedDeal,
    );
    this.acceptDeal(id);
  }

  acceptDeal(dealId: number) {
    DealRepository.acceptDeal(dealId);
  }

  getDeals() {
    return DealRepository.getDeals();
  }

  matchPromosWithDeals(
    accountId: string,
    promotions: Array<MoblicoPromotionType>,
  ): Array<MoblicoPromotionType> {
    if (promotions) {
      return promotions.map((promotion) => ({
        ...promotion,
        deal: this.getDealFromPromotion(promotion),
      }));
    }

    return promotions;
  }

  removePromosWithRedeemedDeals(
    promotions: Array<MoblicoPromotionType>,
  ): Array<MoblicoPromotionType> {
    if (promotions) {
      return promotions.filter(
        (promotion) =>
          (promotion.deal && this.isDealActive(promotion.deal)) ||
          !promotion.deal,
      );
    }

    return promotions;
  }

  isDealActive(deal: Deal): boolean {
    if (deal) {
      const now = moment();
      return (
        now.isBefore(deal.endDate) &&
        (deal.numberOfUsesPerCode === 0 ||
          deal.redeemedCount < deal.numberOfUsesPerCode)
      );
    }

    return false;
  }

  getDealFromPromotion(promotion: MoblicoPromotionType) {
    if (
      promotion &&
      promotion.clickToUrl &&
      promotion.clickToUrl.includes('getDeals;')
    ) {
      const parts = promotion.clickToUrl.split(',');

      if (parts.length > 0) {
        const id = parts[parts.length - 1];
        const deal = DealRepository.getDeal(parseInt(id, 10));
        return deal;
      }
    }

    return null;
  }
}

export default new DealService();
