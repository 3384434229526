import {createAsyncThunk} from '@reduxjs/toolkit';
import {hideSpinner, showSpinner} from '../../slices/screenSlice';
import {AppDispatch, RootState} from '../../store';
import {GiftItem as GiftItemType} from 'src/types/Snack';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import {alertError, alertSuccess} from 'src/components/helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import HttpClient from 'src/api/HttpClient';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import Events from 'src/logging/Events';
import PlatformApi from 'src/api/PlatformApi';
import {SnackStatus} from '../../slices/snackSlice';
import Logger from 'src/logging/Logger';

export type AcceptSnackParams = {
  snackDetails: GiftItemType;
};
export const acceptSnack = createAsyncThunk<
  GiftItemType,
  AcceptSnackParams,
  {dispatch: AppDispatch; state: RootState}
>('snack/acceptSnack', async ({snackDetails}, {dispatch, getState}) => {
  dispatch(showSpinner());
  const accountId = getState().account.account.id;
  const accountBalanceId = getState().account.account.defaultBalance.id;
  try {
    const acceptSnackRes = await PlatformApi.acceptSnack(
      snackDetails.locationId,
      snackDetails.snackId,
      accountId,
      accountBalanceId,
      Util.getCurrentDate(),
    );
    Logger.Log.LogAPIEvent(
      'PlatformAPI',
      'AcceptSnack',
      JSON.stringify({
        locationId: snackDetails.locationId,
        snackId: snackDetails.snackId,
        accountId,
        accountBalanceId,
      }),
      JSON.stringify(acceptSnackRes),
    );

    alertSuccess(
      Localized.Success.formatString(
        Localized.Success.accept_amount_has_been_added_to_your_account,
        snackDetails.amount.toFixed(2).toString(),
      ),
      undefined,
      Localized.Success.snack_accepted,
    );

    //TODO will remove
    await ActionsFactory.getAccountActions().getBalance(
      AccountStore.getAccountId(),
    );

    return {
      ...snackDetails,
      status: SnackStatus.Accepted,
    };
  } catch (err) {
    const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());

    if (error?.code === PlatformApiErrors.SnackAccepted) {
      alertError(Localized.Errors.snack_already_accepted);
    } else {
      alertError(Localized.Errors.error);
      Events.Error.trackEvent(
        'Exception',
        'snack/acceptSnack',
        error.message ? error.message : JSON.stringify(error),
      );
    }
  } finally {
    dispatch(hideSpinner());
  }
});
