import {IGooglePay} from './interfaces/IGooglePay';
import Settings from 'src/Settings';
import Events from '../logging/Events';
import {
  allowedAuthMethods,
  allowedCardNetworks,
  merchantId,
  merchantName,
  apiVersion,
  apiVersionMinor,
} from 'src/constants/GPayConstants';

class GooglePay implements IGooglePay {
  #googlePayClient: google.payments.api.PaymentsClient;

  async canMakePaymentsGooglePay(
    processor: string,
    processorKey: string,
    sandbox: boolean,
  ) {
    try {
      if (Settings.processors[processor] === Settings.processors.stripe) {
        return true;
      } else {
        this.#googlePayClient = new google.payments.api.PaymentsClient({
          environment: sandbox ? 'TEST' : 'PRODUCTION',
        });

        const response = await this.#googlePayClient.isReadyToPay({
          apiVersion,
          apiVersionMinor,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedCardNetworks,
                allowedAuthMethods,
              },
            },
          ],
        });

        return response.result;
      }
    } catch (e) {
      return false;
    }
  }

  async presentGooglePay(
    amount: number,
    countryCode: string,
    currencyCode: string,
    processor: number,
    processorKey: string,
    secret?: string,
  ): Promise<any> {
    try {
      if (processor === Settings.processors.stripe) {
        const stripe = window.Stripe?.(processorKey);

        if (!stripe) {
          return Promise.reject();
        }

        const paymentRequest = stripe.paymentRequest({
          country: countryCode,
          currency: currencyCode.toLowerCase(),
          requestPayerName: true,
          requestPayerEmail: true,
          total: {
            label: 'Funding',
            amount: amount * 100, // The amount in the currency's subunit (e.g. cents, yen, etc.)
          },
        });

        paymentRequest.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const {paymentIntent, error: confirmError} =
            await stripe.confirmCardPayment(
              secret,
              {payment_method: ev.paymentMethod.id},
              {handleActions: false},
            );

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const {error} = await stripe.confirmCardPayment(secret);
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                return Promise.reject(error);
              } else {
                // The payment has succeeded.
                return Promise.resolve();
              }
            } else {
              // The payment has succeeded.
              return Promise.resolve();
            }
          }
        });

        return paymentRequest
          .canMakePayment()
          .then((result) => {
            if (result?.googlePay) {
              paymentRequest.show();
            } else {
              return Promise.reject();
            }
          })
          .catch((err) => Promise.reject(err));
      } else {
        const payload = {
          apiVersion,
          apiVersionMinor,
          merchantInfo: {
            merchantId,
            merchantName,
          },
          allowedPaymentMethods: [
            {
              type: 'CARD',
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: 'globalpayments',
                  gatewayMerchantId: processorKey,
                },
              },
              parameters: {
                allowedCardNetworks,
                allowedAuthMethods,
              },
            },
          ],
          transactionInfo: {
            currencyCode,
            countryCode,
            totalPrice: amount.toString(),
            totalPriceStatus: 'FINAL',
          },
        };

        const paymentData = await this.#googlePayClient.loadPaymentData(
          payload as unknown as google.payments.api.PaymentDataRequest,
        );

        return paymentData?.paymentMethodData?.tokenizationData?.token;
      }
    } catch (e) {
      Events.Error.trackEvent(
        'GooglePay',
        'GooglePay:presentGooglePay',
        e?.message ?? e?.toString(),
      );
      return Promise.reject();
    }
  }
}

export default new GooglePay();
