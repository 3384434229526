import React from 'react';
import {
  Platform,
  StyleSheet,
  View,
  FlatList,
  InteractionManager,
  RefreshControl,
  PermissionsAndroid,
  PixelRatio,
} from 'react-native';
import reactMixin from 'react-mixin';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import TimerMixin from 'react-timer-mixin';
import uuid from 'src/nativeModules/UUID';
import MainConsumerContext from '../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Header from '../elements/Header';
import MachineCell from '../elements/MachineCell';
import PaymentMethodDropdown from '../elements/PaymentMethodDropdown';
import RequirePermission from '../elements/RequirePermission';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import withIsConnected from '../hoc/withIsConnected';
import Styles from '../Styles';
import {IsConnectedProps} from 'src/types/Screen';
import {MachineType} from 'src/types/MachineType';
import ActionsFactory from 'src/actions/ActionsFactory';
import UIManager from './ui/UIManager';
import CurrencyInput from './CurrencyInput';
import Util from 'src/Util';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import MenuService from 'src/services/MenuService';
import MachineAction from 'src/actions/MachineActions';
import MachineStore from 'src/stores/MachineStore';
import AccountStore from 'src/stores/AccountStore';
import TransactionAction from 'src/actions/TransactionActions';
import AVText from '../elements/AVText';
import CartTypes from 'src/constants/cart/CartTypes';
import Settings from 'src/Settings';
import BluetoothManager, {
  BluetoothEvents,
  BluetoothStates,
} from 'src/services/bluetooth/BluetoothManager';
import {BluetoothStatesType} from 'src/services/bluetooth/BluetoothManager';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import TSFInsideDevice, {
  TSFInsideEvents,
} from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import BluetoothDevice from 'src/services/bluetooth/BluetoothDevice';
import BeaconDevice from 'src/services/bluetooth/BeaconDevice';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import VEVendSession from 'src/services/VEVendSession';
import TSFInsideVendSession from 'src/services/TSFInsideVendSession';
import VendingMachineConfig from 'src/models/VendingMachineConfig';
import {alertError, confirm} from '../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import SyncHelper from 'src/services/SyncService';
import TransactionActions from 'src/actions/TransactionActions';
import MenuActions from 'src/actions/MenuActions';
import {TimeSlotType} from 'src/types/Menu';
import {LocationType} from 'src/types/Location';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import Geolocation from '@react-native-community/geolocation';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import VendSession from 'src/services/VEVendSession';

const TIMEOUT_MILLISECONDS = 10000;
const MINIMUM_FUND = 1;
const LOCATION_TIMEOUT_MILLISECONDS = 30000;
const LOCATION_MAXIMUM_AGE_MILLISECONDS = 1000;

type MachinesProps = IsConnectedProps & {
  navigation: any;
  dispatch: AppDispatch;
  paymentCredentials: PaymentCredentials;
  defaultPaymentToken: string;
  payrollAvailable: boolean;
  beaconPopup: boolean;
};
type MachinesState = {
  refreshing: boolean;
  loaded: boolean;
  dataSource: Array<any>;
  showVendOptions: boolean;
  canVendMinProduct: boolean;
  showCCSelection: boolean;
  bluetoothOn: boolean;
  isLocationOn: boolean;
  flashing: boolean;
  fundValue: number;
  cardToken: string;
};

export type RouteInfo = {
  name: keyof typeof AppRoutes;
  availableTimes: Array<TimeSlotType>;
  error: boolean;
};

class MachinesScreen extends React.Component<MachinesProps, MachinesState> {
  interval = 0;
  willFocusSubscription: any;
  preventRefresh = false;
  _deviceId = '';
  selectedDevice: BluetoothDevice;
  clearTimeout: (timeout: number) => void;
  connectTimeout: any;
  _alreadyAutoAdvanced: boolean;
  machineTimeout: any;
  _findingMachines = false;
  setTimeout: (callback: () => void, timeout: number) => void;
  clearInterval: (interval: any) => void;
  flashInterval: any;
  setInterval: (callback: () => void, timeout: number) => void;
  authorizing = false;
  currentVendTransactionId: null | string = null;

  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  constructor(props: MachinesProps) {
    super(props);
    const machines = this.getMachines();
    this.state = {
      dataSource: machines,
      loaded: false,
      canVendMinProduct: false,
      showVendOptions: false,
      showCCSelection: false,
      refreshing: false,
      bluetoothOn: false,
      isLocationOn: false,
      flashing: false,
      fundValue: 0,
      cardToken: props.defaultPaymentToken,
    };
    this.authorizing = false;
    this._onBTStateUpdated = this._onBTStateUpdated.bind(this);
    this.findMachines = this.findMachines.bind(this);
    this.retryClicked = this.retryClicked.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOneTime = this.handleOneTime.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this._onConnectionError = this._onConnectionError.bind(this);
    this.onAuthorize = this.onAuthorize.bind(this);
    this.handleConnectTimeout = this.handleConnectTimeout.bind(this);
    this.startConnectionTimeout = this.startConnectionTimeout.bind(this);
    this._onMachineConnect = this._onMachineConnect.bind(this);
    this._onMachineConnecting = this._onMachineConnecting.bind(this);
    this._onChange = this._onChange.bind(this);
    this.onPlanogramClick = this.onPlanogramClick.bind(this);
    this.onMachineSelect = this.onMachineSelect.bind(this);
    this.popToMain = this.popToMain.bind(this);
    this.handleOKOneTime = this.handleOKOneTime.bind(this);
    this.handleCancelOneTime = this.handleCancelOneTime.bind(this);
    this.cardSelected = this.cardSelected.bind(this);
    this.renderCCSelection = this.renderCCSelection.bind(this);
    this.renderVendOptions = this.renderVendOptions.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderMachine = this.renderMachine.bind(this);
    this.getCurrentRoute = this.getCurrentRoute.bind(this);
    this.autoAdvance = this.autoAdvance.bind(this);
    this.handleAddFunds = this.handleAddFunds.bind(this);
    this.shouldAutoAdvance = this.shouldAutoAdvance.bind(this);
    this.handleLinkLocation = this.handleLinkLocation.bind(this);
    this.removeLocation = this.removeLocation.bind(this);
    this.handleMachineSelected = this.handleMachineSelected.bind(this);
    this.handleOrderAhead = this.handleOrderAhead.bind(this);
    this.onBluetoothDeviceFound = this.onBluetoothDeviceFound.bind(this);
    this.onHighestPriceReceived = this.onHighestPriceReceived.bind(this);
    this.getRouteFromPickupLocations =
      this.getRouteFromPickupLocations.bind(this);
    this.cancelTransaction = this.cancelTransaction.bind(this);
  }

  async componentDidMount() {
    this.getGeoLocation();
    const state = await BluetoothManager.getBluetoothState();
    this._onBTStateUpdated(state, true);

    BluetoothManager.addListener(
      BluetoothEvents.onDeviceFound,
      this.onBluetoothDeviceFound,
    );
    this.interval = 15;
    InteractionManager.runAfterInteractions(() => {
      MachineStore.addChangeListener(this._onChange);
      AccountStore.addChangeListener(this._onChange);
      MachineStore.addConnectionErrorListener(this._onConnectionError);
      BluetoothManager.addListener(
        BluetoothEvents.onBTStateUpdate,
        this._onBTStateUpdated,
      );
    });
    this.willFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        if (this.shouldAutoAdvance()) {
          this.autoAdvance();
        } else if (!this.preventRefresh) {
          this.retryClicked();
          this._alreadyAutoAdvanced = false;
        } else {
          this._alreadyAutoAdvanced = false;
        }

        this.preventRefresh = false;
      },
    );
  }

  componentWillUnmount() {
    MachineStore.removeChangeListener(this._onChange);
    AccountStore.removeChangeListener(this._onChange);
    MachineStore.removeConnectionErrorListener(this._onConnectionError);
    BluetoothManager.removeListener(
      BluetoothEvents.onBTStateUpdate,
      this._onBTStateUpdated,
    );

    if (this.willFocusSubscription) {
      this.willFocusSubscription();
    }

    BluetoothManager.removeListener(
      BluetoothEvents.onDeviceFound,
      this.onBluetoothDeviceFound,
    );
  }

  async onBluetoothDeviceFound(device: any) {
    if (device.type === BluetoothDeviceTypes.vendorsExchange) {
      MachineAction.deviceAddedVE(device as VendorsExchangeDevice);
    } else if (device.type === BluetoothDeviceTypes.beacon) {
      const beacon = device as BeaconDevice;
      MachineAction.marketScanned(
        beacon.beaconId,
        AccountStore.getAccountId(),
        Settings.buildType,
      );
      if (
        Settings.buildType === BuildTypeConstants.canteen &&
        !this.props.beaconPopup &&
        !this.props.navigation.isFocused()
      ) {
        NavActions.navigate(AppRoutes.DetectedBeacon);
      }
    } else if (device.type === BluetoothDeviceTypes.tsfInside) {
      const tsfInside = device as TSFInsideDevice;

      if (tsfInside.deviceName) {
        MachineAction.machineAdded(tsfInside);
      } else {
        MachineAction.deviceAdded(tsfInside);
      }
    }
  }

  getGeoLocation() {
    Geolocation.getCurrentPosition(
      (position) => {
        this.setState({isLocationOn: true});
        ActionsFactory.getAccountActions().fetchNearbyLocations(
          position.coords.latitude,
          position.coords.longitude,
        );
      },
      (error) => {
        this.setState({isLocationOn: false});
      },
      {
        enableHighAccuracy: false,
        timeout: LOCATION_TIMEOUT_MILLISECONDS,
        maximumAge: LOCATION_MAXIMUM_AGE_MILLISECONDS,
      },
    );
  }

  getMachines() {
    const machines = MachineStore.getShops();
    const linkedLocations = AccountStore.getLinkedLocations();
    const nearbyLocations = AccountStore.getNearbyLocations();
    const filteredLocations = nearbyLocations.filter(
      (nearbyLocation) =>
        !linkedLocations.some(
          (linkedLocation) => linkedLocation.id === nearbyLocation.id,
        ),
    );
    const locations = linkedLocations.concat(filteredLocations);
    return machines.concat(locations);
  }

  onPlanogramClick(machine: any) {
    this._deviceId = machine.deviceId;
    NavActions.push(AppRoutes.MainProduct, {
      deviceId: this._deviceId,
      machine,
      onConnect: () => this.onMachineSelect(machine),
    });
  }

  removeLocation(machine: MachineType) {
    if (!machine.isNearby) {
      confirm(
        Localized.Labels.are_you_sure_remove_location,
        () => {
          ActionsFactory.getAccountActions().removeLocationLink(
            AccountStore.getAccountId(),
            machine,
          );
        },
        undefined,
        Localized.Labels.remove_location,
        Localized.Labels.back,
        Localized.Labels.confirm,
      );
    }
  }

  async handleMachineSelected(machine) {
    if (!this.authorizing) {
      try {
        this.authorizing = true;
        this.context.actions.showSpinner(Localized.Labels.authorizing);
        this._deviceId = machine.deviceId;
        this.selectedDevice = machine;
        const authResult = await TransactionAction.authorize(machine.deviceId);
        this.onAuthorize(authResult);
      } catch (error) {
        if (VendSession?.MachineConfig?.TransactionId) {
          this.cancelTransaction(VendSession?.MachineConfig?.TransactionId);
        }
        Events.Error.trackEvent(
          'Exception',
          'Machines:handleMachineSelected',
          error.message ? error.message : error.toString(),
        );
      } finally {
        this.authorizing = false;
      }
    }
  }

  handleOrderAhead = async (machine: any, autoAdvanced = false) => {
    try {
      this.context.actions.showSpinner();
      const locationResponse =
        await ActionsFactory.getAccountActions().getLocation(machine.id);
      MenuService.setTimezone(locationResponse.timezone);
      const params = {
        location: {...machine, ...locationResponse},
        autoAdvanced,
      };
      if (locationResponse.onlineOrderConfig.disclaimer) {
        this.startCartSession(AppRoutes.Disclaimer, machine, {
          ...params,
          getRouteFromPickupLocations: this.getRouteFromPickupLocations,
        });
      } else {
        const routeInfo = await this.getRouteFromPickupLocations(
          locationResponse,
          autoAdvanced,
        );
        if (!routeInfo.error) {
          this.startCartSession(
            routeInfo.name,
            machine,
            {...params, availableTimes: routeInfo.availableTimes},
            'machines-pickup',
          );
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'Machines:HandleOrderAhead',
        error.message ? error.message : error.toString(),
        guid,
      );
      alertError(Localized.Errors.order_ahead_unavailable, guid);
    } finally {
      this.context.actions.hideSpinner();
    }
  };

  async getRouteFromPickupLocations(
    location: LocationType,
    autoAdvanced = false,
  ) {
    const pickupLocationsProduct = MenuService.getPickupLocations();
    const {
      onlineOrderConfig: {hasPickupLocations},
      pickupLocations,
    } = location;
    const hasPickupLocation = hasPickupLocations && pickupLocations?.length > 0;
    let routeName =
      pickupLocationsProduct || hasPickupLocation
        ? AppRoutes.PickupLocation
        : AppRoutes.PickupTime;

    let availableTimeSlots;
    let error = false;
    if (pickupLocations?.length <= 1) {
      let pickupLocationId;
      if (pickupLocations?.length === 1) {
        TransactionActions.pickupLocationUpdated(pickupLocations[0]);
        pickupLocationId = pickupLocations[0].pickupLocationId;
      }
      try {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          location,
          pickupLocationId,
        );
        if (availableTimes.length > 0) {
          availableTimeSlots = MenuService.getAvailableTimeSlotsFromList(
            availableTimes,
            location.onlineOrderConfig.kitchenSchedule,
          );
        } else if (!autoAdvanced) {
          error = true;
          alertError(Localized.Errors.order_ahead_unavailable);
        } else {
          error = true;
          alertError(Localized.Errors.no_pickup_times_available);
        }
      } catch (err) {
        error = true;
        const guid = await uuid.getRandomUUID();
        Events.Error.trackEvent(
          'Exception',
          'Machines:GetRouteFromPickupLocations',
          err.message ? err.message : error.toString(),
          guid,
        );
        alertError(Localized.Errors.order_ahead_unavailable, guid);
      } finally {
        this.context.actions.hideSpinner();
      }

      routeName = AppRoutes.PickupTime;
    }
    return {name: routeName, availableTimes: availableTimeSlots, error};
  }

  startCartSession(
    route: AppRoutes,
    machine: any,
    additionalParams: any = {},
    key?: string,
  ) {
    const params = this.context.actions.getScanScreenParams(machine);
    Events.Machines.trackEvent('startCartSession', {
      params,
      additionalParams,
      route,
    });
    NavActions.push(route, {...params, ...additionalParams}, key);
  }

  async onMachineSelect(machine: any, autoAdvanced = false) {
    Events.Machines.trackEvent('onMachineSelect', {
      localType: machine.localType,
    });
    this._alreadyAutoAdvanced = true;
    // Clear any previous cart
    TransactionAction.shoppingCartCleared();

    if (machine.localType === 'coffee') {
      this.startCartSession(AppRoutes.CoffeeMachine, machine);
    } else if (machine.localType !== 'market') {
      this.handleMachineSelected(machine);
    } else if (machine.orderAhead) {
      this.handleOrderAhead(machine, autoAdvanced);
    } else {
      try {
        this.context.actions.showSpinner();
        const locationResponse =
          await ActionsFactory.getAccountActions().getLocation(machine.id);
        const params = {
          location: {...machine, ...locationResponse},
          cartType: CartTypes.ScanAndPay,
        };
        this.startCartSession(AppRoutes.Scan, machine, params);
      } catch (error) {
        Events.Error.trackEvent(
          'Exception',
          'Machines:onMachineSelect',
          error.message ? error.message : error.toString(),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  onHighestPriceReceived(price: number) {
    TSFInsideVendSession.setMachineHighestPrice(price);
  }

  _onMachineConnect() {
    MachineAction.machineConnected();
    this.clearTimeout(this.connectTimeout);
    this.context.actions.hideSpinner();
    NavActions.push(AppRoutes.Vending, {
      returnRoute: AppRoutes.Machines,
    });
    this.removeDeviceListeners(false);
  }

  _onMachineConnecting() {
    this.clearTimeout(this.connectTimeout);
    this.connectTimeout = this.startConnectionTimeout(3000);
  }

  getCurrentRoute(): string {
    return NavActions.getLastRoute();
  }

  shouldAutoAdvance(): boolean {
    if (this.state.dataSource.length === 1) {
      const market = this.state.dataSource[0];
      return (
        !this._alreadyAutoAdvanced &&
        market &&
        !market.incompatibleCurrency &&
        !market.ccNotConfigured &&
        !market.incompatibleOrg &&
        !market.hiatusMode
      );
    }

    return false;
  }

  autoAdvance() {
    this.onMachineSelect(this.state.dataSource[0], true);
  }

  _onChange() {
    const machines = this.getMachines();
    const loaded = machines.length > 0;

    if (this._findingMachines && loaded) {
      this.context.actions.hideSpinner();
      this.clearTimeout(this.machineTimeout);
      this._findingMachines = false;
    }

    this.setState({
      dataSource: machines,
      loaded,
      refreshing: this.state.refreshing && !loaded,
    });
  }

  handleDisconnect() {
    if (this.selectedDevice) {
      this.removeDeviceListeners();
      this.selectedDevice.disconnect();
    }
  }

  removeDeviceListeners(removeSyncEvents = true) {
    if (this.selectedDevice) {
      if (removeSyncEvents) {
        SyncHelper.removeEvents(this.selectedDevice);
      }

      this.selectedDevice.removeListener(
        TSFInsideEvents.onConnected,
        this._onMachineConnect,
      );
      this.selectedDevice.removeListener(
        TSFInsideEvents.onConnecting,
        this._onMachineConnecting,
      );
      this.selectedDevice.removeListener(
        TSFInsideEvents.onHighestPriceReceived,
        this.onHighestPriceReceived,
      );
    }
  }

  addDeviceListeners() {
    if (this.selectedDevice) {
      SyncHelper.setDevice(this.selectedDevice);
      this.selectedDevice.addListener(
        TSFInsideEvents.onConnected,
        this._onMachineConnect,
      );
      this.selectedDevice.addListener(
        TSFInsideEvents.onConnecting,
        this._onMachineConnecting,
      );
      this.selectedDevice.addListener(
        TSFInsideEvents.onHighestPriceReceived,
        this.onHighestPriceReceived,
      );
    }
  }

  async handleConnectTimeout(showError = false) {
    if (
      this.currentVendTransactionId ||
      VendSession?.MachineConfig?.TransactionId
    ) {
      this.cancelTransaction(
        this.currentVendTransactionId ??
          VendSession?.MachineConfig?.TransactionId,
      );
    }
    this.clearTimeout(this.connectTimeout);
    const guid = await uuid.getRandomUUID();
    Events.Error.trackEvent(
      'Exception',
      'Machines:HandleConnectTimeout',
      '',
      guid,
    );
    this.handleDisconnect();
    this.context.actions.hideSpinner();

    if (showError) {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }

    // Only refresh if we're on the machines list
    const route = this.getCurrentRoute();

    if (route === AppRoutes.MainConsumer) {
      this.findMachines();
    }
  }

  startConnectionTimeout(timeout: number) {
    return this.setTimeout(() => {
      this.handleConnectTimeout(true);
    }, timeout);
  }

  async onAuthorize(authResult: any) {
    const validAuthResult =
      authResult &&
      authResult.AuthResult &&
      !authResult.AuthResult.ErrorMessage;

    if (validAuthResult && authResult.AuthResult.TransactionId) {
      this.currentVendTransactionId =
        authResult?.AuthResult?.TransactionId ??
        VendSession?.MachineConfig?.TransactionId;
      this.context.actions.showSpinner('', true, this.handleConnectTimeout);

      if (this.selectedDevice.type === BluetoothDeviceTypes.vendorsExchange) {
        const vendorsExchangeDevice = this
          .selectedDevice as VendorsExchangeDevice;
        await this.selectedDevice.connect();
        this.context.actions.hideSpinner();

        if (vendorsExchangeDevice.currency === AccountStore.getCurrency()) {
          const machineConfig = new VendingMachineConfig(
            authResult.AuthResult,
            authResult.Settings,
          );
          VEVendSession.startSession(vendorsExchangeDevice, machineConfig);
          NavActions.push(AppRoutes.VendorsExchangeVending);
        } else {
          alertError(Localized.Labels.incompatible_currency);
          this.handleDisconnect();
        }
      } else {
        this.connectTimeout = this.startConnectionTimeout(
          TIMEOUT_MILLISECONDS * 3,
        );
        const machineConfig = new VendingMachineConfig(
          authResult.AuthResult,
          authResult.Settings,
        );
        this.addDeviceListeners();
        TSFInsideVendSession.startSession(this.selectedDevice, machineConfig);
      }
    } else if (
      validAuthResult &&
      authResult.AuthResult.MinPricedProductPurchasable
    ) {
      this.context.actions.hideSpinner();
      this.setState({
        canVendMinProduct: true,
        showVendOptions: true,
      });
      this.preventRefresh = true;
      this.popToMain();
    } else if (
      validAuthResult &&
      !authResult.AuthResult.MinPricedProductPurchaseable
    ) {
      this.context.actions.hideSpinner();
      this.setState({
        canVendMinProduct: false,
        showVendOptions: true,
      });
      this.preventRefresh = true;
      this.popToMain();
    } else {
      this.context.actions.hideSpinner();
      if (
        authResult?.AuthResult?.TransactionId ||
        VendSession?.MachineConfig?.TransactionId ||
        this.currentVendTransactionId
      ) {
        this.cancelTransaction(
          authResult?.AuthResult?.TransactionId ??
            VendSession?.MachineConfig?.TransactionId ??
            this.currentVendTransactionId,
        );
      }

      if (!authResult.AuthResult || !authResult.AuthResult.ErrorMessage) {
        alertError(Localized.Errors.unable_to_vend);
      } else {
        alertError(authResult.AuthResult.ErrorMessage);
      }
    }

    this.authorizing = false;
  }

  async _onConnectionError() {
    if (
      VendSession?.MachineConfig?.TransactionId ||
      this.currentVendTransactionId
    ) {
      this.cancelTransaction(
        VendSession?.MachineConfig?.TransactionId ??
          this.currentVendTransactionId,
      );
    }
    this.clearTimeout(this.connectTimeout);
    const guid = await uuid.getRandomUUID();
    this.context.actions.hideSpinner();
    const error = MachineStore.getConnectionError();

    if (error === 'notavailable') {
      alertError(
        Localized.Errors.notification_sent_to_operator,
        guid,
        undefined,
        Localized.Errors.unable_to_connect_with_vending_machine,
      );
    } else if (error === 'invalidkey') {
      alertError(Localized.Errors.invalid_key, guid);
    } else {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }

    Events.Error.trackEvent(
      'Exception',
      'Machines:OnConnectionError',
      error,
      guid,
    );
    this.handleDisconnect();
    // Only refresh if we're on the machines list
    const route = this.getCurrentRoute();

    if (route === AppRoutes.MainConsumer) {
      this.findMachines();
    }
  }

  async cancelTransaction(transId: string) {
    TransactionAction.cancelTransaction(transId);
    this.currentVendTransactionId = null;
  }

  async handleContinue() {
    this.setState({
      showVendOptions: false,
    });
    const authResult = await TransactionAction.authorize(this._deviceId, true);
    this.onAuthorize(authResult);
  }

  handleOneTime() {
    this.setState({
      showVendOptions: false,
      showCCSelection: true,
      fundValue: 0,
    });
  }

  handleCancel() {
    this.setState({
      showVendOptions: false,
    });
  }

  handleAddFunds() {
    this.setState({
      showVendOptions: false,
    });
    this.context.actions.navigateToFunding(this.props.isConnected, {
      transDate: Util.getCurrentDate(),
    });
  }

  retryClicked() {
    if (!AccountStore.isDemo()) {
      this.setState({
        refreshing: true,
      });
      this.findMachines();
      this.getGeoLocation();
    }
  }

  async findMachines() {
    MachineAction.clearMachines();
    MachineAction.clearDevices();
    MachineAction.clearMarkets();

    try {
      if (this.props.isConnected) {
        await AccountStore.waitForData();

        ActionsFactory.getAccountActions().fetchLinkedLocations(
          AccountStore.getAccountId(),
        );

        if (
          this.state.bluetoothOn ||
          (!AccountStore.getBluetoothChecked() && Platform.OS !== 'web')
        ) {
          ActionsFactory.getAccountActions().setBluetoothChecked(
            !this.state.bluetoothOn,
          );

          if (!this.state.bluetoothOn) {
            alertError(Localized.Errors.bluetooth_off);
          } else {
            this._findingMachines = true;
            this.clearTimeout(this.machineTimeout);
            Events.BluetoothScan.trackEvent();
            BluetoothManager.startScan();
            this.machineTimeout = this.setTimeout(() => {
              this.clearInterval(this.flashInterval);
              this.flashInterval = null;
              this.setState({
                refreshing: false,
                flashing: false,
              });

              if (!this.state.loaded) {
                this.interval = 15;
                this.setTimeout(() => {
                  this.retryClicked();
                }, this.interval * 1000);
                this.interval = this.interval * 2;
              }

              this.context.actions.hideSpinner();
            }, TIMEOUT_MILLISECONDS);
          }
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'Machines:findMachines',
        error.message ? error.message : error.toString(),
        guid,
        {
          accountId: AccountStore.getAccountId(),
          isConnected: this.props.isConnected,
          bluetoothOn: this.state.bluetoothOn,
          loaded: this.state.loaded,
          interval: this.interval,
        },
      );
    }
  }

  _onBTStateUpdated(state: BluetoothStatesType, startup = false) {
    const bluetoothOn = state === BluetoothStates?.PoweredOn;
    const findMachines = bluetoothOn !== this.state.bluetoothOn || startup;

    if (this.state.bluetoothOn !== bluetoothOn) {
      this.setState({
        bluetoothOn,
      });
    }

    if (findMachines) {
      this.findMachines();
    }
  }

  popToMain() {
    NavActions.popToRoute(AppRoutes.Machines);
  }

  handleLinkLocation() {
    this._alreadyAutoAdvanced = true;
    NavActions.push(AppRoutes.LocationCode);
  }

  async handleOKOneTime() {
    if (this.state.fundValue >= MINIMUM_FUND) {
      this.context.actions.showSpinner(Localized.Labels.authorizing);
      this.setState({
        showCCSelection: false,
      });
      const response = await ActionsFactory.getAccountActions().addFunds(
        this.state.cardToken,
        this.state.fundValue,
        Util.getCurrentDate(),
      );

      if (response !== true) {
        alertError(Localized.Errors.failed_to_add_funds);
      }

      await ActionsFactory.getAccountActions().getBalance(
        AccountStore.getAccountId(),
        true,
      );
      const authResult = await TransactionAction.authorize(this._deviceId);
      this.onAuthorize(authResult);
    } else {
      alertError(
        `${Localized.Errors.minimum_amount_is} ${Util.formatCurrency(
          this.props,
          MINIMUM_FUND,
          AccountStore.getCurrency(),
        )}`,
      );
    }
  }

  handleCancelOneTime() {
    this.setState({
      showCCSelection: false,
    });
  }

  cardSelected(val: string) {
    this.setState({
      cardToken: val,
    });
  }

  renderCCSelection() {
    const {applePayAvailable, googlePayAvailable} =
      this.props.paymentCredentials;
    return this.renderHeader(
      true,
      <KeyboardAvoidingView behavior="height" insideTab>
        <View style={styles.containerVend}>
          <>
            <AVText style={styles.instructionsVend}>
              {Localized.Labels.formatString(
                Localized.Labels.select_a_card,
                Util.formatCurrency(
                  this.props,
                  MINIMUM_FUND,
                  AccountStore.getCurrency(),
                ),
              )}
            </AVText>
          </>
          <View style={styles.bottomContainer}>
            <PaymentMethodDropdown
              anchorPosition="bottom"
              onSelect={this.cardSelected}
              value={this.state.cardToken}
              cards={this.context.state.creditCards}
              applePayAvailable={applePayAvailable}
              googlePayAvailable={googlePayAvailable}
              payrollAvailable={this.props.payrollAvailable}
            />
            <CurrencyInput
              currency={AccountStore.getCurrency()}
              value={this.state.fundValue}
              onUpdate={(value) => {
                this.setState({
                  fundValue: +value,
                });
              }}
              label={Localized.Labels.amount}
              accessibilityLabel="Amount"
            />
            <RoundedButton
              buttonType={ButtonType.outline}
              containerStyle={styles.cancelBtn}
              onPress={this.handleCancelOneTime}
              accessibilityLabel="Cancel"
              text={Localized.Buttons.cancel}
            />
          </View>
        </View>
        <RoundedButton
          buttonType={ButtonType.action}
          onPress={this.handleOKOneTime}
          accessibilityLabel="OK"
          text={Localized.Labels.ok}
        />
      </KeyboardAvoidingView>,
    );
  }

  renderVendOptions() {
    return this.renderHeader(
      false,
      <View style={styles.containerVend}>
        {this.state.canVendMinProduct ? (
          <>
            <AVText style={styles.instructionsVend}>
              {Localized.Labels.purchase_items_but_not_all}
            </AVText>
            <AVText style={styles.instructionsVend}>
              {Localized.Labels.select_what_you_would_like_to_do}
            </AVText>
            <View style={styles.bottomContainer}>
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleAddFunds}
                accessibilityLabel="Add Funds"
                text={Localized.Buttons.add_funds}
                containerStyle={styles.button}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleOneTime}
                accessibilityLabel="One Time Purchase"
                text={Localized.Buttons.one_time_purchase}
                containerStyle={styles.button}
              />
            </View>
          </>
        ) : (
          <>
            <AVText style={styles.instructionsVend}>
              {Localized.Errors.cannot_make_purchase}
            </AVText>
            <AVText style={styles.instructionsVend}>
              {Localized.Labels.select_what_you_would_like_to_do}
            </AVText>
            <View style={styles.bottomContainer}>
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleAddFunds}
                accessibilityLabel="Add Funds"
                text={Localized.Buttons.add_funds}
                containerStyle={styles.button}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleOneTime}
                accessibilityLabel="One Time Purchase"
                text={Localized.Buttons.one_time_purchase}
                containerStyle={styles.button}
              />
            </View>
          </>
        )}
        <RoundedButton
          buttonType={ButtonType.outline}
          onPress={this.handleCancel}
          accessibilityLabel="Cancel"
          text={Localized.Buttons.cancel}
          containerStyle={styles.button}
        />
        {this.state.canVendMinProduct && (
          <RoundedButton
            buttonType={ButtonType.action}
            onPress={this.handleContinue}
            accessibilityLabel="Continue"
            text={Localized.Buttons.continue}
          />
        )}
      </View>,
    );
  }

  renderHeader(showBalance = false, children?: React.ReactNode) {
    let rightView = null;

    if (showBalance) {
      rightView = UIManager.getBalanceContainer(false, Localized);
    }

    return (
      <Header
        accessible={true}
        accessibilityLabel={UIManager.getShopTitle()}
        aria-label={UIManager.getShopTitle()}
        accessibilityRole="heading"
        role="heading"
        title={UIManager.getShopTitle()}
        rightView={rightView}
      >
        {children}
      </Header>
    );
  }

  renderContent() {
    const emptyListReason = this.state.isLocationOn
      ? Platform.OS === 'web'
        ? ''
        : Localized.Errors.no_machines_found
      : Localized.Errors.location_service_off;
    const view = (
      <FlatList
        style={styles.backgroundImage}
        data={this.state.dataSource}
        renderItem={this.renderMachine}
        keyExtractor={(item) =>
          item.orderAhead + item.localType + item.deviceId
        }
        ListEmptyComponent={
          <View style={styles.content}>
            {!this.state.refreshing && (
              <AVText
                accessible={true}
                accessibilityLabel={emptyListReason}
                aria-label={emptyListReason}
                accessibilityRole="text"
                role="presentation"
              >
                {emptyListReason}
              </AVText>
            )}
          </View>
        }
        ListFooterComponent={
          PixelRatio.getFontScale() > Styles.FontSizeMultiplier.maxfm0 && (
            <View style={{height: Styles.Heights.headerHeight}} />
          )
        }
        refreshControl={
          <RefreshControl
            accessible={true}
            accessibilityLabel={Localized.Labels.searching_machines}
            accessibilityState={{busy: this.state.refreshing}}
            aria-label={Localized.Labels.searching_machines}
            aria-busy={this.state.refreshing}
            refreshing={this.state.refreshing}
            onRefresh={this.retryClicked}
            title={Localized.Labels.searching_machines}
          />
        }
      />
    );
    return this.renderHeader(
      false,
      <>
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.ACCESS_FINE_LOCATION}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_CONNECT}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_ADVERTISE}
        />
        <View style={styles.container}>{view}</View>
        <RoundedButton
          accessible={true}
          accessibilityLabel="Link Location"
          accessibilityRole="button"
          aria-label="Link Location"
          role="button"
          buttonType={ButtonType.action}
          onPress={this.handleLinkLocation}
          text={Localized.Labels.link_location}
        />
      </>,
    );
  }

  renderMachine(item: any): any {
    const machine = item.item;
    return (
      <MachineCell
        machine={machine}
        key={machine.deviceId}
        onPlanogramClick={this.onPlanogramClick}
        onSelect={this.onMachineSelect}
        strings={Localized}
        removeLocation={this.removeLocation}
      />
    );
  }

  renderNotConnected() {
    return this.renderHeader(
      false,
      <>
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.ACCESS_FINE_LOCATION}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_CONNECT}
        />
        <RequirePermission
          permission={PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_ADVERTISE}
        />
      </>,
    );
  }

  render() {
    if (!this.props.isConnected) {
      return this.renderNotConnected();
    } else if (this.state.showVendOptions) {
      return this.renderVendOptions();
    } else if (this.state.showCCSelection) {
      return this.renderCCSelection();
    }

    return this.renderContent();
  }
}

reactMixin(MachinesScreen.prototype, TimerMixin);
const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
  },
  bottomContainer: {
    marginTop: Styles.Spacing.m4,
  },
  button: {
    marginTop: Styles.Spacing.m2,
  },
  cancelBtn: {
    marginTop: Styles.Spacing.m2,
  },
  container: {
    backgroundColor: Styles.white,
    flex: 1,
    flexDirection: 'column',
  },
  containerVend: {
    flex: 1,
    margin: Styles.Spacing.m3,
  },
  content: {
    alignItems: 'center',
    padding: Styles.Spacing.m3,
  },
  fontSize10: {
    fontSize: Styles.Fonts.f1,
  },
  height60: {
    height: Styles.Heights.h5,
  },
  instructionsVend: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
  },
});
const ConnectedMachinesScreen = connect(
  (state: RootState) => ({
    paymentCredentials: state.account.paymentCredentials,
    defaultPaymentToken: state.account.defaultPaymentToken,
    payrollAvailable: state.account.account.isPayrollAvailable,
    beaconPopup: state.account.beaconPopup,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(MachinesScreen);
export default withForwardedNavigationParams()(
  withGlobalize(withIsConnected(ConnectedMachinesScreen)),
);
