import {createAsyncThunk} from '@reduxjs/toolkit';
import uuid from '../../../nativeModules/UUID';
import Events from '../../../logging/Events';
import {alertError} from '../../../components/helpers/AlertHelper';
import NavActions from '../../../actions/NavActions';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import Api from 'src/api/Api';

export const fetchEnvironments = createAsyncThunk(
  'environment/fetchEnvironments',
  async (_, {dispatch}) => {
    dispatch(showSpinner());

    try {
      const response = await Api.discoverUrls();

      if (
        response.hasOwnProperty('urls') &&
        response.urls.hasOwnProperty('TEST')
      ) {
        delete response.urls.TEST;
      }

      if (response) {
        return response.urls;
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      Events.Error.trackEvent(
        'Exception',
        'EnvironmentScreen:ComponentDidMount',
        error.message ? error.message : JSON.stringify(error),
        guid,
      );
      alertError('Failed to load environments.', guid);
      NavActions.pop();
    } finally {
      dispatch(hideSpinner());
    }

    return {error: true};
  },
);
